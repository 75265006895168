import { Box, LinearProgress, styled, Typography } from "@material-ui/core";
import React, { CSSProperties, useEffect, useRef, useState } from "react";

import Button from "./Button";
import { handleFileSize } from "./handle-file-size";
import useOfflineBlob from "./hooks/useOfflineBlob";

import { UploadedFile } from "../../projectnotes/src/ProjectnotesController";

const audioButton = require("../../projectnotes/assets/audioButton.png");
const AudioPauseButton = require("../../projectnotes/assets/AudioPauseButton.png");
const AudioPlayButton = require("../../projectnotes/assets/AudioPlayButton.png");

type IAudioPlayerProps = { 
    isGridViewItem: boolean;
    file: UploadedFile;
    style?: CSSProperties;
    onComplete: () => void;
}
const AudioPlayer = ({
    isGridViewItem = false,
    file,
    onComplete,
}: IAudioPlayerProps) => {
    const currentUrl = useOfflineBlob(file);

    const audioRef = useRef<HTMLAudioElement>(null);

    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (completed) {
            onComplete();
        }
    }, [completed]);

    const formatTime = (timeInSeconds: number) => {
        if (isNaN(timeInSeconds) || timeInSeconds === 0) {
          return "0:00";
        }
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };
    const handleProgress = () => {
        if (audioRef && audioRef?.current) {
            setCurrentTime(audioRef.current.currentTime);
            setDuration(audioRef.current.duration);
            setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100);
            if (audioRef.current.currentTime === audioRef.current.duration) {
                setIsPlaying(false);
                setCompleted(true);
            }
        }
    };
    const handleLoadedMetadata = () => {
        if (audioRef && audioRef?.current) {
            setDuration(isNaN(audioRef?.current.duration) ? 0 : audioRef?.current.duration)
        }
    };
    const handleTogglePlayPause = () => {
        if (audioRef && audioRef?.current) {
            if (!isPlaying) {
                if (audioRef?.current?.ended) {
                    audioRef.current.currentTime = 0;
                }
                audioRef?.current?.play();
            } else {
                audioRef?.current?.pause();
            }
            setIsPlaying(!isPlaying);
        }
    }

    if (!isGridViewItem) {
        return (
            <ListViewContainer>
                <RowStack style={{ alignItems: 'center', gap: '12px' }}>
                    <RowStack style={{ borderRadius: '14px', backgroundColor: '#EDF1F6', padding: "14px 12px", justifyContent: 'center', alignItems: 'center' }}>
                        <img
                            src={audioButton}
                            style={{ width: '18px', height: '18px' }}
                            alt="Audio"
                        />
                    </RowStack>
                    <ColStack style={{ gap: "2px" }}>
                        <NormalTypo>{file.filename}</NormalTypo>
                        {file.filename && <NormalTypo style={{ fontSize: '10px', color: "#475569" }}>{file.size} KB</NormalTypo>}
                    </ColStack>
                </RowStack>
                <RowStack style={{ alignItems: 'center', gap: '22px' }}>
                    <audio
                        controls={false}
                        ref={audioRef} 
                        src={currentUrl}
                        preload="metadata"
                        onTimeUpdate={handleProgress}
                        onLoadedMetadata={handleLoadedMetadata}
                    />
                    <ColStack width="184.18px" style={{ gap: "6px" }}>
                        <RowStack style={{ justifyContent: 'space-between' }}>
                            <NormalTypo style={{ color: '#475569' }}>{formatTime(currentTime)}</NormalTypo>
                            <NormalTypo style={{ color: '#475569' }}>{formatTime(duration)}</NormalTypo>
                        </RowStack>
                        <AudioTimer 
                            variant="determinate"
                            value={progress}
                            color="secondary"
                        />
                    </ColStack>
                    <Button style={{ width: "38.82px", height: "38.82px", minWidth: 0, padding: "12px 13px", borderRadius: "30px" }}>
                        <img
                            alt={!isPlaying ? "Play" : "Pause"}
                            data-testid = "AudioPlayPauseButton-2"
                            onClick={handleTogglePlayPause}
                            src={isPlaying ? AudioPauseButton  : AudioPlayButton}   
                            style={{ width: "14.5px", height: "14.5px" }}
                        />
                    </Button>
                </RowStack>
            </ListViewContainer>
        )
    }

    return (
        <ColStack 
            style={{ 
                width: "100%",
                height: "100%",
                padding: '12px',
                justifyContent: "center",
                alignItems: "center",
                gap: "10px"
            }}
        >
            <audio
                controls={false}
                ref={audioRef} 
                src={currentUrl}
                preload="metadata"
                onTimeUpdate={handleProgress}
                onLoadedMetadata={handleLoadedMetadata}
            />
            <Box width="100%">
                <RowStack style={{ justifyContent: 'space-between' }}>
                    <NormalTypo style={{ color: '#475569' }}>{formatTime(currentTime)}</NormalTypo>
                    <NormalTypo style={{ color: '#475569' }}>{formatTime(duration)}</NormalTypo>
                </RowStack>
                <AudioTimer 
                    variant="determinate"
                    value={progress}
                    color="secondary"
                />
            </Box>
            <RowStack style={{ width: "100%", height: "518.17px", borderRadius: '33.58px', backgroundColor: '#EDF1F6', justifyContent: "center", alignItems: "center" }}>
                <img
                    src={audioButton}
                    style={{
                        margin: "auto",
                        width: '43.18px',
                        height: '43.18px',
                        color: "rgba(26, 70, 156, 1)",
                    }}
                />
            </RowStack>
            <RowStack style={{ width: "100%", justifyContent: 'space-between', alignItems: "center" }}>
                <ColStack style={{ gap: "6px" }}>
                    <NormalTypo>{file.filename}</NormalTypo>
                    {file.size && <NormalTypo style={{ fontSize: '10px' }}>{handleFileSize(file.size)} KB</NormalTypo>}
                </ColStack>
                <Button style={{ width: "30px", height: "26px", minWidth: 0, borderRadius: "16px" }}>
                    <img
                        alt={!isPlaying ? "Play" : "Pause"}
                        data-testid = "AudioPlayPauseButton-2"
                        onClick={handleTogglePlayPause}
                        src={isPlaying ? AudioPauseButton  : AudioPlayButton}   
                        style={{ width: "9px", height: "9px" }}
                    />
                </Button>
            </RowStack>
        </ColStack>
    )
}

export default AudioPlayer;

const RowStack = styled(Box)({
    display: "flex"
})
const ColStack = styled(RowStack)({
    flexDirection: "column"
})
const ListViewContainer = styled(RowStack)(({ theme }) => ({
    borderRadius: "18px",
    padding: '8px',
    backgroundColor: theme.palette.primary.dark,
    justifyContent: "space-between",
    alignItems: "center",
}))
const AudioTimer = styled(LinearProgress)(({ theme }) => ({
    height: "3.63px",
    borderRadius: "4.85px",
    backgroundColor: "#E2E8F0",
    "& .MuiLinearProgress-barColorSecondary": {
        backgroundColor: '#1A469C'
    },
}))
const NormalTypo = styled(Typography)({
    fontFamily: "Rubik",
    fontSize: '12px',
    lineHeight: "12px",
    color: '#0F172A'
})