import { Box, InputProps, Input as MuiInput, styled } from "@material-ui/core";
import React, { CSSProperties, forwardRef, ReactNode } from "react";

import { urlValidate } from "../../../framework/src/Utilities";

type TextInputType = | 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url';

interface TextInputProps extends InputProps {
    id?: string;
    inputSize?: 'sm' | 'md' | 'lg' | string;
    type?: TextInputType | string;
    layoutStyle?: CSSProperties;
    label?: string;
    labelStyle?: CSSProperties;
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
    placeholder?: string;
    helperText?: ReactNode;
    errorText?: string;
}

const TextInput = forwardRef<InputProps, TextInputProps>((props, ref) => {
    const { inputSize = 'md', id, layoutStyle, label, labelStyle, required, helperText, errorText, iconLeft, iconRight, ...inputProps } = props;

    const isLinkWithProtocol = (link: string) => {
    return !link.includes("http") ? `https://${link}` : link
    }

    return (
        <Container style={layoutStyle}>
            {label && (
                <Label 
                    htmlFor={id} 
                    role="alert" 
                    style={labelStyle}
                >
                    {label} {required && <span style={{ fontSize: '8px' }}>(Required <span style={{ color: "#DC2626" }}>*</span>)</span>}
                </Label>)}
            <Input 
                id={id}
                ref={ref}
                startAdornment={iconLeft}
                endAdornment={iconRight}
                style={{ height: inputSize === 'lg' ? '56' : inputSize === 'sm' ? '32' : '44', border: !!errorText ? '1px solid #DC2626' : '' }}
                {...inputProps}
            />
            {inputProps.type === "url" && inputProps.value && inputProps.value !== "" && urlValidate(inputProps.value as string)
                && (
                    <Link 
                      href={isLinkWithProtocol(inputProps.value as string)} 
                      target="_blank" 
                      rel="noreferrer"
                    >{inputProps.value as string}</Link>
                )}
            {errorText && <HintText>{errorText}</HintText>}
        </Container>
    )
})

export default TextInput;

const Container = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: '100%',
    maxWidth: '100%',
})
const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Rubik',
    paddingBottom: '4px',
    color: theme.palette.text.primary,
    lineHeight: '22px'
}))
const Input = styled(MuiInput)(({ theme }) => ({
    width: '100%',
    maxWidth: '100%',
    padding: '10px 12px',
    appearance: 'none',
    fontFamily: 'Rubik',
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.info.dark}`,
    gap: '8px',
    borderRadius: '8px',
    lineHeight: '22px',
    background: theme.palette.background.default,
    '&:active, &:focus, &:focus-within': { border: '2px solid #1A469C' },
    '&::before, &::after, &:hover:not(.Mui-disabled)::before': { borderBottom: 'transparent' },
    '&:placeholder': { color: '#475569' },
    '& > .MuiInputBase-input': { 
        padding: 0, 
        appearance: 'none', 
        "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
        }, 
    }
}))
const Link = styled('a')(({ theme }) => ({
    lineHeight: "22px",
    color: theme.palette.info.contrastText,
    textDecorationLine: "none",
    textDecorationColor: theme.palette.info.contrastText,
    "&:hover": { 
        color: theme.palette.info.contrastText,
        textDecorationLine: "underline",
        textDecorationColor: theme.palette.info.contrastText,
    }
}))
const HintText = styled('p')({
    display: 'inline-block',
    marginTop: '2px',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#DC2626'
})