import React from "react";
// Customizable Area Start
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  TextField,
  Typography,
  styled,
  Modal,
  Divider
} from "@material-ui/core";
import Button from '../../utilities/src/Button';
import { ThemeProvider } from "@material-ui/core/styles";
import CertificationTrackingController, {
  Props,
} from "./CertificationTrackingController.web";
import { ToastContainer } from "react-toastify";
import { Pagination } from "@material-ui/lab";
import {
  EditHeading,
  EditDescription,
  MainContainer,
} from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import { FilterContainer, FilterSelect, GridTitle, GridValue, WorkerGrid } from "../../user-profile-basic/src/UserProfileBasicBlock.web";
import { lightTheme, darkTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { Select } from "antd";
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { banner, certificate, forwardArrow, order } from "./assets";
import { DotSection } from "../../notifications/src/Notifications.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Close from "@material-ui/icons/Close";
const StatusOptions = [
  { label: 'Assigned', value: 'assigned' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Completed', value: 'completed' }
]

const sortOptions = [
  { value: "asc", label: "Ascending" },
  { value: "desc", label: "Descending" },
];
// Customizable Area End

export default class CertificationTracking extends CertificationTrackingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  LeftFilterOptions = () => {
    return <div style={{ display: 'flex' }}>
      <EditDescription style={{ fontSize: "16px", lineHeight: "24px", marginRight: "5px" }}>
        Filter by:
      </EditDescription>
      {this.state.currentRole === 'educator' && <FilterSelect
        data-test-id="Company"
        className={this.state.selectedCompany ? "selectFilter" : ""}
        value={this.state.selectedCompany || undefined}
        onChange={this.changeCompany}
        placeholder="Company"
        dropdownMatchSelectWidth={false}
      >
        {this.state.allCompanyName?.map((item) => (
          <Select.Option value={item.company_name}>
            {item.company_name}
          </Select.Option>
        ))}
      </FilterSelect>}
      <FilterSelect
        data-test-id="Group"
        className={this.state.selectedGroup ? "selectFilter" : ""}
        value={this.state.selectedGroup || undefined}
        onChange={this.changeGroup}
        placeholder="Group"
        dropdownMatchSelectWidth={false}
      >
        {this.state.allGroupName?.map((item) => (
          <Select.Option value={item.group_name}>
            {item.group_name}
          </Select.Option>
        ))}
      </FilterSelect>
      <FilterSelect
        data-test-id="Course"
        className={this.state.selectedCourse ? "selectFilter" : ""}
        value={this.state.selectedCourse || undefined}
        onChange={this.changeCourse}
        placeholder="Course"
        dropdownMatchSelectWidth={false}
      >
        {Array.isArray(this.state.allCourseName) && this.state.allCourseName?.map((item) => (
          <Select.Option value={item.attributes.course_name}>
            {item.attributes.course_name}
          </Select.Option>
        ))}
      </FilterSelect>
      <FilterSelect
        data-test-id="Status"
        className={this.state.selectedStatus ? "selectFilter" : ""}
        value={this.state.selectedStatus || undefined}
        onChange={this.changeStatus}
        placeholder="Status"
        dropdownMatchSelectWidth={false}
      >
        {StatusOptions.map((item) => (
          <Select.Option value={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </FilterSelect>
      {this.state.selectedRadio.length === 0 && <ExpirationLabel
        data-test-id="enableExpiry"
        className={this.state.enableExpDate ? "selectedOption" : ""}
        onClick={() => this.setState({ enableExpDate: !this.state.enableExpDate, currentPage: 1 }, () => this.getAssignedCourses())}
      >
        Exp. Date {this.state.enableExpDate && 'x'}
      </ExpirationLabel>}
      <ExpirationLabel
        data-test-id="RedLabel"
        className={this.state.enableRedLabel ? "selectedOption" : ""}
        onClick={() => this.setState({ enableRedLabel: !this.state.enableRedLabel, currentPage: 1 }, () => this.getAssignedCourses())}
      >
        Red Label {this.state.enableRedLabel && 'x'}
      </ExpirationLabel>
    </div>
  }

  RightFilterOptions = () => {
    const open = Boolean(this.state.anchorSort);
    return <div style={{ display: 'flex', alignItems: 'center' }}>
      {this.state.selectedRadio.length > 0 && <RemindText>Send Reminder</RemindText>}
      {(this.state.selectedCompany || this.state.selectedCourse || this.state.selectedGroup || this.state.selectedStatus || this.state.enableExpDate || this.state.enableRedLabel || (this.state.selectedRadio.length > 0)) && <ResetText data-test-id="resetValues" variant="text" onClick={this.resetFilter}>Reset</ResetText>}
      {this.state.enableSearchBar ?
        <SearchBox
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ fill: "#1A469C", width: "24px", height: '24px' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <CloseIcon data-test-id="closeSearch" style={{ fill: "#1A469C", cursor: 'pointer' }} onClick={() => this.setState({ enableSearchBar: false, searchValue: "", currentPage: 1, totalPage: 1 }, () => this.getAssignedCourses())} />
              </InputAdornment>
            )
          }}
          data-test-id="searchParam"
          placeholder="Search"
          onChange={this.changeSearchValue}
          style={{ width: '200px' }}
          onKeyPress={this.searchList}
        /> :
        <SearchIcon
          data-test-id="enableSearch"
          style={{ height: '32px', width: '32px', fill: "#1A469C" }}
          onClick={() => this.setState({ enableSearchBar: true, currentPage: 1 })}
        />
      }
      {(this.state.selectedStatus || this.state.enableExpDate) && <>
        <img
          src={order}
          alt="sort"
          style={{ cursor: "pointer" }}
          data-test-id="openAnchorSort"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event: React.MouseEvent<HTMLImageElement>) => {
            this.setState({ anchorSort: event.currentTarget })
          }}
        />
        <Menu
          data-test-id="closeSort"
          id="basic-menu"
          anchorEl={this.state.anchorSort}
          open={open}
          onClose={() => this.setState({ anchorSort: null })}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          style={{ top: "50px" }}
        >
          <MenuItem data-test-id="removeImage" disabled>
            SORT BY
          </MenuItem>
          {sortOptions.map((item) => (
            <MenuItem data-test-id="removeImage">
              <span
                data-test-id="sortEmployee"
                style={{
                  color: "#1A469C",
                  fontFamily: "Rubik",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "22px",
                }}
                onClick={() => this.sortList(item.value)}
              >
                {item.label}
              </span>
            </MenuItem>
          ))}
        </Menu> </>}
    </div>
  }

  checkExpiration = (value: string) => {
    if (value.includes("month")) {
      return <CompletedLabel>{value}</CompletedLabel>
    } else if (value.includes("Weeks")) {
      return <InProgressLabel>{value}</InProgressLabel>
    } else {
      return <AssignedLabel>{value}</AssignedLabel>
    }
  }

  getManagerRoleDetails = () => {
    return (
      <HeadContainer style={{ paddingLeft: this.state.sideMenu ? 273 : 130, paddingRight: 48, transition: 'all 300ms linear' }}>
        <SubSection>
          <div>
            <EditHeading>Educational Progress</EditHeading>
            <EditDescription>
              Here you can find all student progress, certificate and expiration dates.
            </EditDescription>
          </div>
        </SubSection>

        <SubSection style={{ flexDirection: 'column' }}>
          <MainContainer style={{ flexDirection: 'column', maxWidth: '100%', maxHeight: '100%', padding: 0 }}>
            <FilterContainer>
              {this.LeftFilterOptions()}
              {this.RightFilterOptions()}
            </FilterContainer>
            {this.state.isLoading ? (
              <CircularProgress color={"inherit"} size={40} style={{ alignSelf: 'center' }} />
            ) : <>
              {this.state.assignedCourseList?.map((element) => (
                <div style={{ display: 'flex' }}>
                  <Radio
                    data-test-id="RadioSelect"
                    checked={this.state.selectedRadio.includes(String(element?.attributes.id))}
                    onChange={this.handleRadioChange}
                    value={element?.attributes.id}
                  />
                  <div style={{ width: '100%', margin: '10px' }}>
                    <AboutExpireText>{element.attributes.red_label}</AboutExpireText>
                    <WorkerGrid container spacing={1}>
                      <Grid item xs={"auto"} style={{ marginRight: "15px", width: '28px' }}>
                        <GridTitle>ID</GridTitle>
                        <GridValue>{element?.attributes.id}</GridValue>
                      </Grid>
                      <Grid item xs={2}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Avatar
                            src={element?.attributes.profile.data.attributes?.image?.toString()}
                            style={{
                              width: "34px",
                              height: "34px",
                              marginRight: "10px",
                              marginTop: "5px",
                              borderRadius: "50%",
                            }}
                          />
                          <div>
                            <GridTitle>Worker Name</GridTitle>
                            <GridValue>
                              {element?.attributes.profile.data.attributes.preferred_name}
                            </GridValue>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={2}>
                        <GridTitle>Group Name</GridTitle>
                        <GridValue>{element.attributes?.group}</GridValue>
                      </Grid>
                      <Grid item xs={2}>
                        <GridTitle>Course Name</GridTitle>
                        <GridValue>{element?.attributes?.course?.data?.attributes?.course_name}</GridValue>
                      </Grid>
                      <Grid item xs={2}>
                        <GridTitle>Expiration Date</GridTitle>
                        {this.checkExpiration(element.attributes?.expiration_date)}
                      </Grid>
                      <Grid item xs={2} style={{ width: '120px' }}>
                        <GridTitle>Status</GridTitle>
                        {element.attributes?.status === 'completed' && <CompletedLabel>COMPLETED</CompletedLabel>}
                        {element.attributes?.status === 'in_progress' && <InProgressLabel>IN PROGRESS</InProgressLabel>}
                        {element.attributes?.status === 'assigned' && <AssignedLabel>ASSIGNED</AssignedLabel>}
                      </Grid>
                      <Grid item xs={"auto"} style={{ width: '50px' }}>
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                          <CircularProgress variant="determinate" value={70} />
                          <Box
                            sx={{
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              position: 'absolute',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <ProgressText
                              variant="caption"
                            >{`70%`}</ProgressText>
                          </Box>
                        </Box>
                      </Grid>
                      {!this.state.selectedRadio.includes(String(element?.attributes.id)) && <Grid item xs={"auto"}>
                        <DotSection>
                          <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={(event) => this.handleOpenNavMenu(event, String(element.attributes.id))}
                            color="inherit"
                            data-test-id="openOption"
                          >
                            <MoreVertOutlinedIcon />
                          </IconButton>
                          <Menu
                            id="menu-appbar"
                            anchorEl={this.state.anchorDotOption}
                            anchorOrigin={{
                              horizontal: 'right',
                              vertical: 'bottom',
                            }}
                            data-test-id="anchorDotOption"
                            keepMounted
                            transformOrigin={{
                              horizontal: 'right',
                              vertical: 'top',
                            }}
                            open={Boolean(this.state.anchorDotOption)}
                            onClose={() => this.setState({ anchorDotOption: null })}
                            elevation={0}
                            style={{ top: 30 }}
                          >
                            <MenuItem data-test-id='renewExpiration' onClick={() => this.renewExpiration(String(element.attributes.id))}>
                              <CalendarTodayIcon style={{ marginRight: 10, height: '16px', width: '16px' }} />
                              <Typography style={{ fontSize: 12, color: '#1A469C' }}>Renew Expiration Date</Typography>
                            </MenuItem>

                            <MenuItem data-test-id='sendNotification' onClick={() => this.sendNotification(String(element.attributes.id))}>
                              <NotificationsActiveIcon style={{ marginRight: 10, height: '16px', width: '16px' }} />
                              <Typography style={{ fontSize: 12, color: '#1A469C' }}>Send Reminder</Typography>
                            </MenuItem>
                          </Menu>
                        </DotSection>
                      </Grid>}
                    </WorkerGrid>
                  </div>
                </div>
              ))}
            </>}
          </MainContainer>
          <Pagination
            style={{ display: 'flex', alignSelf: "center" }}
            data-test-id="changePage"
            count={this.state.totalPage}
            page={this.state.currentPage}
            onChange={this.changePage}
          />
        </SubSection>
      </HeadContainer>
    )
  }

  openPreviewModal = (course_name:string, certificate_url:string) => (
    <Modal
      open={this.state.openPreview}
      onClose={this.handleClosePreview}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer>
        <RowStack style={{ alignItems: "center", justifyContent:"space-between", gap: "22px" }}>
          <Typography style={{ fontSize: 25, fontWeight: 500 , fontFamily: "Rubik", textWrap: "nowrap" }}>Certification Preview</Typography>
          <RowStack style={{ flex: 1, alignItems: "center", justifyContent:"space-between" }}>
            <Divider style={{ flex: 1, maxWidth: "129px" }} />
            <Typography 
              style={{ 
                flex: 2, 
                whiteSpace: "nowrap",
                fontSize: "17px",
                lineHeight: "18px", 
                color: "#334155",
                textAlign: "center" 
              }}
            >{course_name ? course_name : "Course Name"}</Typography>
            <Divider style={{ flex: 1, maxWidth: "129px" }} />
          </RowStack>
          <IconButton
            onClick={this.handleClosePreview}
            aria-label="close"
            style={{ padding: 0, color: "red" }}
          >
            <Close />
          </IconButton>
        </RowStack>
        <RowStack style={{ gap: 14, alignItems: 'center' }}>
          <img style={{width:"605px", height:"418px"}} src={certificate}></img>
        </RowStack>
        <RowStack style={{ gap: 14, alignItems: 'center' }}>
          <Button
            variant="outline"
            style={{ width: "183px", borderRadius: "30px" }}
            onClick={this.handleClosePreview}
          >Cancel</Button>
          <Button
            style={{ flex: 1, borderRadius: "30px" }}
            onClick={()=>this.downloadCertificate(certificate_url)}
          >
          Download
          </Button>
        </RowStack>  
      </ModalContainer>
    </Modal>
  )

  getStudentRoleDetails = () => (
    <HeadContainer style={{ paddingLeft: this.state.sideMenu ? 273 : 130, paddingRight: 48, transition: 'all 300ms linear' }}>
      <SubSection>
        <div>
          <EditHeading>Certificates</EditHeading>
          <EditDescription>
            here where you can track all your progress and taken certificates as well
          </EditDescription>
        </div>
      </SubSection>

      <SubSection style={{ flexDirection: 'column', padding: 0, }}>
        <MainContainer style={{ flexDirection: 'column', maxHeight: "100%", maxWidth: "100%", }}>
          <FilterContainer>
            <EditDescription style={{ fontSize: "24px", fontWeight: 500, lineHeight: "42px", marginRight: "5px" }}>
              Courses Certificates
            </EditDescription>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: "10px" }}>
              <SearchBox
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
              />
              <OverAllProgress data-test-id="overallProgress" onClick={() => this.goToPerformanceTracker()}>Overall Progress</OverAllProgress>
            </div>
          </FilterContainer>
          {this.state.isLoading ? (
            <CircularProgress color={"inherit"} size={40} style={{ alignSelf: 'center' }} />
          ) : <>
            {this.state.completedCourseList?.map((element) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
              <WorkerGrid
                container
                style={{
                  marginTop: "10px",
                  marginBottom: "5px",
                  padding: 0,
                  maxHeight: "115px",
                  width: "calc(100% - 150px)",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Grid item style={{display: "flex"}}>
                  <BannerImage
                    src={
                        element.attributes.course.data.attributes.image ||
                      banner
                    }
                  />
                  <div
                    style={{
                      padding: "3px 10px",
                    }}
                  >
                    <GridTitle style={{ fontSize: "10px" }}>
                      Course Name
                    </GridTitle>
                    <GridValue
                      style={{ fontSize: "12px", fontWeight: 400 }}
                    >
                      {element.attributes.course.data.attributes.course_name}
                    </GridValue>
                    <GridTitle style={{ fontSize: "10px" }}>
                      Instructor Name
                    </GridTitle>
                    <GridValue
                      style={{ fontSize: "12px", fontWeight: 400 }}
                    >
                      {element.attributes.course.data.attributes.instructor}
                    </GridValue>
                    <GridTitle style={{ fontSize: "10px" }}>
                      End Date
                    </GridTitle>
                    <GridValue
                      style={{ fontSize: "12px", fontWeight: 400 }}
                    >
                      {this.getExpirationDate(
                        element.attributes.course.data.attributes.end_date		
                      )}
                    </GridValue>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{ maxWidth: "max-content", padding: "10px" }}
                >
                  <div>
                    <img onClick={()=>this.openPreview(element.attributes.course.data.attributes.course_name, element.attributes.certificate_image)} src={forwardArrow} />
                  </div>
                </Grid>
              </WorkerGrid>
              <CertificateImage src={certificate} />
            </Box>
            ))}
          </>}
        </MainContainer>
        <Pagination
          style={{ display: 'flex', alignSelf: "center" }}
          data-test-id="changePage"
          count={this.state.totalPage}
          page={this.state.currentPage}
          onChange={this.changePage}
        />
      </SubSection>
    </HeadContainer>
  )

  renderCertificationData = () => {
    return this.state.currentRole === "worker"
      ? this.getStudentRoleDetails()
      : this.getManagerRoleDetails();
  };
  // Customizable Area End

  render() {
    const { isLoading } = this.state;

    return (
      // Customizable Area Start
      <ThemeProvider theme={this.state.blackTheme ? darkTheme : lightTheme}>
        <NavigationMenu navigation={this.props.navigation} id={""} open={this.state.sideMenu} theme={this.state.blackTheme} />
        <HamburgerMenu
          navigation={this.props.navigation}
          handleMenu={this.handleSideMenu}
          open={this.state.sideMenu}
          handleTheme={this.handleBlackTheme}
          themeDark={this.state.blackTheme}
        />
        {this.state.currentRole && this.renderCertificationData()}
        {this.openPreviewModal(this.state.course_name, this.state.certificate_url)}
        {!isLoading &&
          <ToastContainer position="top-right" />}
      </ThemeProvider>
      // Customizable Area End
    ); /*  */
  } /*  */
}

// Customizable Area Start
const HeadContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%'
}))

const SubSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  padding: '15px',
  width: '100%',
  margin: '20px 0px',
  "& .MuiPaginationItem-page.Mui-selected": {
    background: theme.palette.action.selected,
    color: '#FFF',
    minWidth: '18px',
    height: '18px'
  },
  "& .MuiPaginationItem-root": {
    fontFamily: "Rubik",
    background: theme.palette.action.active,
    fontWeight: 500,
    lineHeight: "18px",
    fontSize: "10px",
    minWidth: '18px',
    height: '18px'
  },
  "& .MuiPagination-ul": {
    background: theme.palette.background.default,
    padding: '5px',
    borderRadius: '40px'
  },
  "& .MuiSvgIcon-root": {
    fill: theme.palette.secondary.main,
    width: '18px'
  },
  "& .MuiCircularProgress-root": {
    transform: 'rotate(120deg) !important'
  }
}))

const ProgressText = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: '8px',
  fontWeight: 400,
  color: theme.palette.secondary.main
}))

const RowStack = styled(Box)({
  display: "flex"
})
const ColStack = styled(RowStack)({
  flexDirection: "column"
})

const ModalContainer = styled(ColStack)({
  width: "700px", 
  height:"603px",
  border: "1px solid #FFF",
  borderRadius: "8px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
  padding: "36px", 
  gap: "24px",
  backgroundColor: "#F9F9F9",
  position: "absolute", 
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
})

const ExpirationLabel = styled(Typography)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: theme.palette.info.contrastText,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
  borderRadius: '26px',
  padding: '10px 12px',
  marginLeft: '5px',
  cursor: 'pointer'
}))

export const InProgressLabel = styled(Typography)(({ theme }) => ({
  background: theme.palette.error.light,
  color: theme.palette.warning.dark,
  padding: '4px 8px',
  height: 'auto',
  borderRadius: '40px',
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: 500
}))

export const CompletedLabel = styled(Typography)(({ theme }) => ({
  background: theme.palette.error.dark,
  color: theme.palette.warning.light,
  padding: '4px 8px',
  height: 'auto',
  textAlign: 'center',
  borderRadius: '40px',
  fontWeight: 500,
  fontSize: '12px',
}))

export const AssignedLabel = styled(Typography)(({ theme }) => ({
  background: theme.palette.error.main,
  color: theme.palette.primary.contrastText,
  padding: '4px 8px',
  textAlign: 'center',
  height: 'auto',
  borderRadius: '40px',
  fontSize: '12px',
  fontWeight: 500
}))

const SearchBox = styled(TextField)(({ theme }) => ({
  minWidth: "250px",
  height: "44px",
  padding: "8px 16px",
  borderRadius: "98px",
  border: `1px solid  ${theme.palette.info.dark}`,
  backgroundColor: theme.palette.background.default,
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
}));

const OverAllProgress = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  color: theme.palette.info.contrastText,
  marginLeft: "5px",
  cursor: "pointer"
}))

const BannerImage = styled('img')({
  height: "115px",
  width: "228px",
  borderRadius: "18px"
})

const CertificateImage = styled('img')({
  width: '100%',
  height: "100%",
  maxHeight: "97px",
  maxWidth: "137px",
  marginTop: '8px'
})

const ResetText = styled(Button)(({ theme }) => ({
  fontSize: "16",
  fontWeight: 500,
  textTransform: 'capitalize',
  color: theme.palette.primary.contrastText
}))

const RemindText = styled(Button)(({ theme }) => ({
  fontSize: "14",
  fontWeight: 500,
  textTransform: 'capitalize',
  color: theme.palette.info.contrastText
}))

const AboutExpireText = styled(Typography)(({ theme }) => ({
  fontSize: '8px',
  fontWeight: 500,
  lineHeight: '12px',
  color: theme.palette.primary.contrastText,
  marginBottom: '-12px',
  marginLeft: "3%"
}))
// Customizable Area End
