import React, { ReactNode } from "react";
import useOutsideClick from "./hooks/useOutsideAlerter";

const OutsideAlerter = ({ children, onOutsideClick }: { children: ReactNode; onOutsideClick: () => void }) => {
    const ref = useOutsideClick(onOutsideClick);
    
    return (
        <div ref={ref}>
            {children}
        </div>
    );
}

export default OutsideAlerter