import React from "react";
// Customizable Area Start
import { Paper, Dialog, DialogContent, IconButton, Checkbox, Button, Typography, FormControlLabel, Radio, Box, styled } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ScoringController, { Props } from "./ScoringController";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import {imgProductCard, ReDoButton} from "./assets";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

interface FormValues {
  title: string;
  answer: string;
}

export default class TestScoring extends ScoringController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderDialog () {
    const { ModalOpen, selectedImage } = this.state;
    return(
      <Dialog
      open={ModalOpen}
      onClose={this.closeModal}
      PaperProps={{
        style: {
          maxWidth: "700px",
          maxHeight: "548px",
          borderRadius: "12px",
          overflow: "hidden",
          width: '100%',
          height: '100%',
        },
      }}
    >
      <DialogContent style={{ position: "relative", padding: "2rem" }}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" className="previewStyle">
            Image Preview
          </Typography>
          <IconButton
            onClick={this.closeModal}
            style={{ position: "absolute", top: 25, right: 18 }}
          >
            <CloseIcon style={{ color: 'rgba(220, 38, 38, 1)', height: '21px', width: '21px' }} />
          </IconButton>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '1.5rem' }}>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Preview"
              style={{ width: "605px", height: "418px", borderRadius: '6px' }}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
    )
  }

  renderMediaFiles = (mediaFiles: any[]) => {
    if (!mediaFiles) return null;
  
    return (
      <Box style={{ display: 'flex' }}>
        {mediaFiles.map((media: any, idx: number) => {
          const isVideo = media.url.match(/\.(mp4|webm|ogg)$/i);
          return isVideo ? (
            <video
              key={idx}
              src={String(media.url)}
              controls
              className="videostyle"
            />
          ) : (
            <img
              key={idx}
              src={String(media.url)}
              data-test-id="imagee"
              onClick={() => this.openModal(media.url)}
              className="videostyle"
            />
          );
        })}
      </Box>
    );
  };

  renderCorrectContent() {
    const { showIncorrectAnswers, testPercentage, correct_questions_data } = this.state;
    return (
      <Box className="messageContainer" style={{ flexDirection: 'column'}}>
        
          <Box className="scoreHeaderStyle">
            <Typography className="hearderStyle-1" variant="h6">
              Below are the questions that were answered incorrectly. The correct answer is shown in green.
            </Typography>
            <Typography className="hearderStyle-2" variant="h6">
              Your Score <span style={{ fontWeight: 600 }}>{testPercentage}%</span>
            </Typography>
            <IconButton className="hearderStyle-3" onClick={this.handleReDoQuix}>
              <img src={ReDoButton} style={{marginRight: '6px'}} /><span>Re-Do Quiz</span> 
            </IconButton>
          </Box>
       
        <ScrollableContainer p={4} style={{ height: "430px"}}>
          {correct_questions_data.map((q:any, index:number) => (
            <Box key={q.id}>
              <Typography className="questionStyle" variant="h6">
                {index + 1}. {q.question_id?.data?.attributes?.content}
              </Typography>
              {(q.question_id?.data?.attributes?.media_files_urls)  && (
                <Box style={{ display: 'flex' }}>
                  {this.renderMediaFiles(q.question_id?.data?.attributes?.media_files_urls)}
                </Box>
              )}

              <Box style = {{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
                {( Array.isArray(q.question_id?.data?.attributes?.choices?.data) ? q.question_id.data.attributes.choices?.data : [] ).map((opt: any, idx: number) => {
                  return (
                    <FormControlLabel
                      key={`${idx}`}
                      control={
                        (q.question_id?.data?.attributes?.question_type) === "single_choice" ? (
                          <Radio
                            onChange={() => this.handleOptionChange(q.id, opt.attributes.id, "single_choice")}
                            disabled
                            className={this.getCheckboxClass(showIncorrectAnswers)}
                            checked={
                                (Array.isArray(q.correct_choice_ids) && q.correct_choice_ids.includes(opt.attributes.id))
                            }
                        />
                        ) : (
                          <Checkbox
                            onChange={() => this.handleOptionChange(q.id, opt.attributes.id, "multiple_choice")}
                            disabled={showIncorrectAnswers}
                            className={this.getCheckboxClass(showIncorrectAnswers)}
                            checked={
                              (Array.isArray(q.correct_choice_ids) &&
                                q.correct_choice_ids.includes(opt.attributes.id))
                            }                                                                                
                          />
                        )
                      }
                      label={
                        <Typography
                          className="optionsStyle"
                          dangerouslySetInnerHTML={{ __html: String(opt.attributes.content) }}
                        />
                      }
                    />
                  );
                })}

              </Box>
              <Box className="lineStyle"></Box>
            </Box>
          ))}
        </ScrollableContainer>
        {this.renderDialog()}
      </Box>
    );
  }

  renderContent() {
    const { showIncorrectAnswers, questions_data } = this.state;

    return (
      <Box className="messageContainer" style={{ flexDirection: 'column'}}>

        <ScrollableContainer p={4} style={{ height: "430px"}}>
          {questions_data.map((q:any, index:number) => (
            <Box key={q.id}>
              <Typography className="questionStyle" variant="h6">
                {index + 1}. {q.attributes.content}
              </Typography>

              {q.attributes.media_files_urls && (
                <Box style={{ display: 'flex' }}>
                  {this.renderMediaFiles(q.attributes.media_files_urls)}
                </Box>
              )}

              <Box style = {{display: 'flex', flexWrap: 'wrap', flexDirection: 'column'}}>
              {(Array.isArray(q.attributes.final_test_choices?.data) ? q.attributes.final_test_choices?.data : Array.isArray(q.attributes.choices?.data) ? q.attributes.choices?.data : [] ).map((opt: any, idx: number) => (
                   <FormControlLabel
                    key={`${idx}`}
                    control={
                      q.attributes.question_type === "single_choice" ? (
                        <Radio
                          onChange={() => this.handleOptionChange(q.id, opt.attributes.id, "single_choice")}
                          disabled={showIncorrectAnswers}
                          className={this.getCheckboxClass(showIncorrectAnswers)}
                          checked={this.state.selectedAnswers[q.id] === opt.attributes.id}
                        />
                      ) : (
                        <Checkbox
                          onChange={() => this.handleOptionChange(q.id,opt.attributes.id, "multiple_choice")}
                          disabled={showIncorrectAnswers}
                          className={this.getCheckboxClass(showIncorrectAnswers)}
                          checked={Array.isArray(this.state.selectedAnswers[q.id]) &&
                            this.state.selectedAnswers[q.id]?.includes(opt.attributes.id)}                        />
                      )
                    }
                    label={
                      <Typography
                        className="optionsStyle"
                        dangerouslySetInnerHTML={{ __html: String(opt.attributes.content) }}
                      />
                    
                    }
                  />
                ))}
              </Box>
              <Box className="lineStyle"></Box>
            </Box>
          ))}
        </ScrollableContainer>
          {
            questions_data.length > 0 && 
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography className="numStyle" variant="h6">
                Num. of Ques.: {questions_data.length} 
              </Typography>
              <Button
                disabled={
                  Object.keys(this.state.selectedAnswers).length !== this.state.questions_data.length
                }
                onClick={this.handleSubmit}
                className="quizButtonStyle"
              >
                Submit Answers
              </Button>
              </Box>
          }

         {this.renderDialog()}
      </Box>
    );
  }

  renderScore() {
    const { questions_data, testScore, testPercentage} = this.state;
    return (
      <Box className="scoreMessageContainer" style={{ flexDirection: 'column', flexWrap: 'wrap' }}>
        <Typography variant="body1" className="successStyle" style={{ paddingTop: '4rem' }}>Congratulations!</Typography>
        <Typography variant="body1" className="messageStyle" style={{ wordWrap: 'break-word', marginTop: '1rem', fontSize: '20px' }}>
          You answered <span style={{ fontWeight: 600 }}> {testScore} out of {questions_data?.length} </span>questions correctly.
        </Typography>
        <Typography variant="body1" className="messageStyle" style={{ wordWrap: 'break-word', paddingTop: '3rem', marginBlock: '1rem' }}>Your score is <span style={{ fontWeight: 600 }}>{testPercentage}%</span></Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={this.state.testPercentage === 100 ? () => {} : this.handleRightAnswer}
          className="buttonStyle"
        >
        See Right Answers
          <ArrowForwardIosIcon style={{ height: '13px', width: '13px', marginLeft: '1rem' }} />
        </Button>
      </Box>
    );
  }

  renderScoreFinalTest() {
    const { questions_data, testScore, testPercentage } = this.state;
    return (
      <Box className="scoreMessageContainer" style={{ flexDirection: 'column', flexWrap: 'wrap' }}>
        {this.state.passStatus !== "Failed" ? (
          <>
            <Typography variant="body1" className="successStyle" style={{ paddingTop: '4rem' }}>Congratulations!</Typography>
            <Typography variant="body1" className="messageStyle" style={{ wordWrap: 'break-word', marginTop: '1rem', fontSize: '20px' }}>
              You've successfully completed all the quizzes and final test for this course! Your overall score is [{testPercentage}]%.
            </Typography>

            <Typography variant="body1" className="messageStyle" style={{ wordWrap: 'break-word', paddingTop: '3rem', marginBlock: '1rem' }}>
              Great work on your progress so far! Keep up the hard work as you move forward in your learning journey.
            </Typography>
            <IconButton className="hearderStyle-3" onClick={this.retakeFinalAssessment}>
              <img src={ReDoButton} style={{ marginRight: '6px' }} /><span>Re-Take Final Test</span>
            </IconButton>
          </>
        ) :
          (
            <>
              <Typography variant="body1" className="successStyle" style={{ paddingTop: '4rem' }}>Unfortunately, you did not pass this time.</Typography>
              <Typography variant="body1" className="messageStyle" style={{ wordWrap: 'break-word', marginTop: '1rem', fontSize: '20px' }}>
                You scored {testScore} out of {questions_data.length} questions correctly, <br/>
                giving you a score of {testPercentage}%.
              </Typography>

              <Typography variant="body1" className="messageStyle" style={{ wordWrap: 'break-word', paddingTop: '3rem', marginBlock: '1rem' }}>
                Don’t be discouraged; take this as an opportunity to review the course
                materials. Please click on the Table of Contents and navigate through
                the chapters and lessons
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={this.retakeFinalAssessment}
                className="buttonStyle"
              >
                Request to Retake Final Test
              </Button>
            </>
          )
        }
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
        <ThemeProvider theme={theme}>
          <MainSection>
            <Box>
              <Paper className="paperStyle" >
                <div >
                {!this.state.isPassword  && this.props.testType === "final_test" && !this.state.showFinalAssessment &&
                <div style={{padding:"70px 50px"}}>
                <div style={{color:"#0F172A", fontWeight:500, fontSize:"24px", fontFamily:"Rubik"}}>
                Success!
                </div>
                <div  style={{color:"#475569", fontWeight:400, fontSize:"18px", fontFamily:"Rubik"}}>
                You've finished the course! Now, you can request a final assessment to officially complete your training. Click the "Request Final Assessment" button to proceed.
                </div>
                {
                    this.state.courseError && this.props.testType === "final_test" &&
                    <div style={{color:"#DC2626", fontWeight:400, fontSize:"12px", fontFamily:"Rubik", textAlign:"center", marginTop:"12px"}}>
                      {this.state.courseError}
                    </div>
                }
                <SaveContinueButton style={{marginTop:"12px"}} onClick={this.requestFinalAssessment}>
                  Request Final Assessment
                </SaveContinueButton>
                </div>
                }
                {
                  this.state.isPassword && !this.state.isVerified && this.props.testType === "final_test" &&
                  <div style={{display:"flex", flexDirection:"column", alignItems:"center",padding:"70px 50px"}}>
                    <div style={{color:"#0F172A", fontWeight:500, fontSize:"22px", fontFamily:"Rubik"}}>Enter Password to Access Final Test</div>
                    <div style={{color:"#475569", fontWeight:400, fontSize:"16px", fontFamily:"Rubik", textAlign:"center"}}>
                      This final test is password protected. Please enter the password provided by your supervisor to begin.</div>
                    <div style={{ display: "flex", gap: "10px", marginTop: "12px" }}>
                    {this.state.password.map((val, index) => (
                      <input
                        key={index}
                        ref={this.inputRefs[index]}
                        type="password"
                        value={val}
                        onChange={(e) => this.handleChangePasswordInput(index, e)}
                        onKeyDown={(e) => this.handleKeyDown(index, e)}
                        style={{
                          width: "40px",
                          height: "40px",
                          textAlign: "center",
                          fontSize: "18px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                        }}
                      />
                    ))}
                    </div>
                    {
                      this.state.isVerifiedText && this.props.testType === "final_test" &&
                      <div style={{color:"#DC2626", fontWeight:400, fontSize:"12px", fontFamily:"Rubik", textAlign:"center", marginTop:"12px"}}>
                        The password you entered for the final test is incorrect. Please try again or contact your supervisor for assistance.
                      </div>
                    }
                    <SaveContinueButton style={{marginTop:"12px"}} onClick={this.handlePasswordSubmit}>
                      Submit Password
                    </SaveContinueButton>
                  </div>
                }
                {
                  this.state.isVerified && !this.state.showFinalAssessment && this.props.testType === "final_test" &&
                  <div style={{padding:"70px 50px"}}>
                  <div style={{color:"#0F172A", fontWeight:500, fontSize:"24px", fontFamily:"Rubik"}}>Success!</div>
                  <div  style={{color:"#475569", fontWeight:400, fontSize:"18px", fontFamily:"Rubik"}}>
                  You've successfully unlocked the final test.                 </div>
                  <SaveContinueButton style={{marginTop:"12px"}} onClick={this.startFinalTest}>
                  Start Final Test
                  </SaveContinueButton>
                  </div>
                }
                </div>
                {this.state.reDoQuiz && this.state.showFinalAssessment  && this.renderContent()}
                
                { !this.state.reDoQuiz && this.state.showIncorrectAnswers && this.props.testType==="quiz" && this.renderCorrectContent()} 
                {
                 this.props.testType === "final_test" ?
                 (
                  <>
                    {!this.state.reDoQuiz && !this.state.showIncorrectAnswers && this.state.isScoreOpen && this.state.showFinalAssessment &&  this.renderScoreFinalTest()}
                  </>
                 )
                 :
                 (
                  <>
                    {!this.state.reDoQuiz && !this.state.showIncorrectAnswers && this.state.isScoreOpen  && this.renderScore()}
                  </>
                 )
                }
                {!this.state.reDoQuiz && !this.state.showIncorrectAnswers && !this.state.isScoreOpen && this.state.questions_data?.length >= 0 &&  !this.state.isPassword && this.renderContent()}

              </Paper>

            </Box>
          </MainSection>
        </ThemeProvider>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const SaveContinueButton = styled(Button)(({ theme }) => ({
  width: "474px",
height: "44px",
padding: "10px 16px 10px 16px",
gap: "8px",
borderRadius: "30px",
opacity: "0px",
backgroundColor: "#1A469C",
textTransform:"none",
color: "#F8FAFC",
fontFamily: "Rubik",
fontSize: "14px",
fontWeight: 700,
"&:hover": {
  backgroundColor: 'rgba(26, 70, 156, 1)', 
  boxShadow: 'none', 
}
}))
const ScrollableContainer = styled(Box)(({ theme }) => ({
  gap: "8px",
  opacity: "0px",
  overflowY:"auto",
  "&::-webkit-scrollbar": {
    width: "13px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "30px 30px 90px 30px",
    backgroundColor: "transparent",
    border: "1px solid #CBD5E1",
  },
  "&::-webkit-scrollbar-thumb": {
    width: "13px",
    height: "43px",
    borderRadius: "30px 30px 90px 30px",
    backgroundColor: "#EDF1F6",
    border: "1px solid #94A3B8",
  },
}))



const MainSection = styled(Box)({
  "& .scoreHeaderStyle": {
    height: '65px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '32px',
    paddingRight: '32px',
    paddingTop: '2rem',
    zIndex: 9
  },
  "& .textButton": {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: '12px',
    color: 'rgba(26, 70, 156, 1)',
  },
  "& .hearderStyle-1": {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: '10px',
    color: 'rgba(51, 65, 85, 1)',
    width: '266px',
    backgroundColor: 'rgba(249, 249, 249, 1)',
    borderLeft: '4px rgba(26, 70, 156, 1) solid',
    borderRadius: '4px',
    paddingLeft: '1rem'
  },
  "& .hearderStyle-2": {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: '10.47px',
    color: 'rgba(15, 23, 42, 1)',
  },
  "& .hearderStyle-3": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: '12.25px',
    color: 'rgba(26, 70, 156, 1)',

    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  "& .headerStyle": {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: '12.25px',
    color: 'rgba(15, 23, 42, 1)',
    lineHeight: '26px'
  },
  "& .paperStyle": {
    width: '100%',
    borderRadius: '18px',
    backgroundColor: 'rgba(249, 249, 249, 1)',
    padding: '2rem',
    boxShadow: 'none',
    height: '753px'
  },
  "& .downloadCourseText": {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: '9px',
  },
  "& .downloadCourse": {
    display: 'flex',
    color: 'rgba(26, 70, 156, 1)',
    gap: '4px'
  },
  "& .checkboxStyle": {
    color: "#b0bec5",
    padding: 0,
    marginLeft: '6px',
    marginTop: '2px',
    marginBottom: '2px',
  },
  "& .checkboxStyle.Mui-checked": {
    color: "rgba(5, 150, 105, 1)",
  },
  "& .unCheckboxStyle": {
    color: "#b0bec5",
    padding: 0,
    marginLeft: '6px',
    marginTop: '2px',
    marginBottom: '2px',
  },
  "& .unCheckboxStyle.Mui-checked": {
    color: "rgba(26, 70, 156, 1)",
  },
  "& .questionStyle": {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: '14px',
    color: 'rgba(15, 23, 42, 1)',
    lineHeight: '24px'
  },
  "& .optionsStyle": {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: '12px',
    color: 'rgba(51, 65, 85, 1)',
    lineHeight: '18px',
    paddingLeft: '6px'
  },
  "& .videoStyle": {
    width: "100%",
    height: "101px",
    maxWidth: "261px",
    borderRadius: '6.5px',
    margin: '8px 8px 8px 0',
  },
  "& .lineStyle": {
    border: '0.5px rgba(215, 215, 215, 0.5) solid',
    marginTop: '8px',
    marginBottom: '1rem',
  },
  "& .numStyle": {
    fontFamily: "Rubik",
    fontWeight: '400',
    fontSize: '16px',
    color: 'rgba(15, 23, 42, 1)',
    lineHeight: '24px'
  },
  "& .quizButtonStyle": {
    fontFamily: "Rubik",
    fontWeight: '500',
    fontSize: '12px',
    color: '#fff',
    backgroundColor: 'rgba(26, 70, 156, 1)',
    width: '100%',
    height: '30px',
    borderRadius: '30px',
    maxWidth: '153px',
    textTransform: 'none',
    "&:disabled": {
    backgroundColor: "rgba(26, 70, 156, 0.5)", 
    color: "#4a4747",
    cursor: "not-allowed",
    },
  },
  "& .buttonStyle": {
    fontFamily: "Rubik",
    fontWeight: '700',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: 'rgba(26, 70, 156, 1)',
    width: '100%',
    marginTop: '8px',
    borderRadius: '30px',
    textTransform: 'none',
  },
  "& .previewStyle": {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: '22.63px',
    color: 'rgba(15, 23, 42, 1)',
  },
  "& .messageContainer": {
    height: '641px',
    display: 'flex',
    paddingRight: '25px',
    paddingLeft: '25px',
    backgroundColor: 'white',
    borderRadius: '18px'
  },
  "& .scoreMessageContainer": {
    backgroundImage: `url(${imgProductCard})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 50px top 50px',
    height: '641px',
    display: 'flex',
    paddingRight: '25px',
    paddingLeft: '25px',
    backgroundColor: 'white',
    borderRadius: '18px'
  },

  "& .successStyle": {
    fontFamily: "Rubik",
    fontWeight: '500',
    fontSize: '24px',
    color: '#0F172A',
    textTransform: 'none',
  },
  "& .messageStyle": {
    fontFamily: "Rubik",
    fontWeight: '400',
    fontSize: '18px',
    color: '#475569',
  },
})

// Customizable Area End










