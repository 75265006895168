import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment, { Moment } from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  menuStatus: boolean;
  darkThemeStatus: boolean;
  anchorElNav: null | HTMLElement;
  currentPage: number;
  totalPage: number;
  unread: boolean;
  replyModal: boolean;
  commentMessage: string;
  selectedId: string;
  open:boolean;
  snackbarMessage: string,
  snackBarOpen: boolean;
  isPageDropdownOpen: boolean;
  selectedPageSize: number;
  isLoading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      menuStatus: false,
      darkThemeStatus: false,
      anchorElNav: null,
      currentPage: 1,
      totalPage: 1,
      unread: false,
      replyModal: false,
      commentMessage: "",
      selectedId: "",
      open:false,
      snackbarMessage:"",
      snackBarOpen:false,
      isPageDropdownOpen: false,
      selectedPageSize: 10,
      isLoading: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    let theme = await getStorageData('darkMode');
    this.setState({ darkThemeStatus: JSON.parse(theme) })
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getNotifications();
    } else if (
      this.getDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      apiResponse &&
        this.setState({
          data: apiResponse.data,
          totalPage: apiResponse.meta.total_pages,
          isLoading: false
        });
    } else if (
      this.markAsReadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ anchorElNav: null })
      this.getNotifications();
    } else if (
      this.deleteCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.message) {
      this.setState({ snackBarOpen: true });
      }
      this.setState({ selectedData: null, anchorElNav: null });
      this.getNotifications();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  async getNotifications() {
    this.setState({isLoading: true})
    const authToken = await getStorageData('authToken');
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}?page=${this.state.currentPage}&perPage=${this.state.selectedPageSize}&unread=${this.state.unread}`
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: authToken,
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  async markAsRead(id: number) {
    const authToken = await getStorageData('authToken');
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${this.state.selectedId}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: authToken
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  async deleteNotifications(id: number) {
    const authToken = await getStorageData('authToken');
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${this.state.selectedId}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: authToken
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }

  timeSince(date: string) {
    const momentDate = moment(date);
    const now = moment();

    if (momentDate.isSame(now, 'day')) {
      return `Today at ${momentDate.format('h:mm A')}`;
    } else if (momentDate.isSame(now.subtract(1, 'days'), 'day')) {
      return `Yesterday at ${momentDate.format('h:mm A')}`;
    } else {
      return momentDate.format('MM/DD/YYYY h:mm A');
    }
  }

  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  toggleSideMenu = () => {
    this.setState({ menuStatus: !this.state.menuStatus })
    setStorageData('open', JSON.stringify(!this.state.menuStatus));
  }

  toggleDarkTheme = () => {
    this.setState({ darkThemeStatus: !this.state.darkThemeStatus })
    setStorageData('darkMode', `${!this.state.darkThemeStatus}`)
  }

  handleCloseNavMenu = () => {
    this.setState({ anchorElNav: null })
  }

  handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>, key: string) => {
    this.setState({ anchorElNav: event.currentTarget, selectedId: key });
  };

  goToNotificationSettings = () => {
    const message1: Message = new Message(
      getName(MessageEnum.NavigationNotificationSettingsMessage)
    );
    message1.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message1);
  };

  changePageNumber = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value }, () => this.getNotifications())
  }

  showUnreadNotification = (unreadStatus: boolean) => {
    this.setState({ unread: unreadStatus }, () => this.getNotifications())
  }

  openReplyModal = () => {
    this.setState({ replyModal: true })
  }

  closeReplyModal = () => {
    this.setState({ replyModal: false })
  }

  changeCommentMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ commentMessage: event.target.value })
  }

  handleClose = () => {
    this.setState({snackbarMessage:"", snackBarOpen:false})
  }

  togglePageDropdown = () => {
    this.setState((prevState) => ({
      isPageDropdownOpen: !prevState.isPageDropdownOpen,
    }));
  };

  handlePageSizeChange = (size: number) => {
    this.setState({ selectedPageSize: size }, () => {
      this.getNotifications();
    });
  };
  // Customizable Area End
}
