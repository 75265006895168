// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const caretRight = require("../assets/caret_right.png");
export const deleteIcon = require("../assets/deleteIcon.svg");
export const editButton = require("../assets/edit_button.svg");
export const expand = require("../assets/expand.svg");
export const reduce = require("../assets/reduce.svg");
export const assignButton = require("../assets/assign.svg");
export const calender = require("../assets/calender.svg")
export const coursePic=require("../assets/dammy.png")
// Customizable Area End