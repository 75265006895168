import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
  } from "../../../framework/src/Messages/MessageEnum";
  import { runEngine } from "../../../framework/src/RunEngine";
  export const configJSON = require("./config");
  import {  setStorageData } from "../../../framework/src/Utilities";
interface Course {
    id: number;
    reviewLabel: string;
    attributes: {
      course_name: string;
      status: string;
      image:string
    };
  }
  interface Feedback{
    id:string;
    attributes:{
      message:string;
      feedback_by:{
        attributes:{
          first_name:string;
          image:string | null;
        }
      }
    }
  }
export interface IProps {
    CourseData:Course[]
  }
  interface IState {
    activeTab: number;
    showFeedbackPage: boolean;
    courseData:Course[];
    showCourseData:Course[];
    showFeedBack:Feedback;
    step:number;
    darkTheme:boolean
  }
  interface Props{

  }
export default class CourseFeedbackController extends BlockComponent<
Props,
IState,
Course
> {
    getCourseApiCallId:string ="";
    getFeedBackApiCallId:string="";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
    
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.NavigationPayLoadMessage)
        ];
    
        this.state = {
            activeTab: 0,
            showFeedbackPage: false,
            showCourseData:[],
            courseData:[],
            showFeedBack:{
              id:"",
              attributes:{
                  message:"",
                  feedback_by:{
                    attributes:{
                      first_name:"",
                      image:null,
                    }
              
                  }
              }
            },
            step:-1,
            darkTheme:false,
        };
    
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      }
      async componentDidMount() {
       this.getCourse()
      }
      async componentWillUnmount() {
        setStorageData('step', '0')
      }
      async componentDidUpdate(prevProps: IProps, prevState: IState) {
        // Run function only when `value` changes
        if (prevState.activeTab !== this.state.activeTab) {
          this.showCourse()
        }
      }
      async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
    
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          runEngine.debugLog("API Message Recived", message);
          if (responseJson && responseJson.errors) {
            return ;
          } else if (responseJson) {
            switch (apiRequestCallId) {
               case this.getCourseApiCallId :
                this.setState({courseData:responseJson.data.data})
                 this.showCourse()
               break;
               case this.getFeedBackApiCallId:
                      this.setState({showFeedBack:responseJson.data})
                break;
            }         
          } else if (errorReponse) {
            this.parseApiErrorResponse(errorReponse);
            this.parseApiCatchErrorResponse(errorReponse);
          }
        }
      }
      showCourse=()=>{
        if(this.state.activeTab==0){
          const filter=this.state.courseData.filter((course:Course)=>course.attributes.status==="published")
          this.setState({showCourseData:filter})
        }else if(this.state.activeTab==1){
          const filter=this.state.courseData.filter((course:Course)=>course.attributes.status==="revision_rejected")
          this.setState({showCourseData:filter})
        }
       
      }
      handleChangeTab = (event: any, newValue: number) => {
        this.setState({ activeTab: newValue });
      };
    
      handleArrowClick = (id:number) => {
        this.getFeedBack(id)
        this.setState({ showFeedbackPage: true });
      };
      getCourse = async()=>{
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCourseApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getCourse
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
      getFeedBack=(id:number)=>{
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFeedBackApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
         `bx_block_profile/feedbacks/${id}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      goToCurriculum=()=> {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), 'PostCreation');
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
}