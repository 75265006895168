import { Box, Grid, styled } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { CSSProperties, useRef, useState, ChangeEvent, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import Button from "./Button";
import IconButton from "./IconButton";

type IPreviewDisplayProps = {
    urls: string[],
    fileType?: "video" | "image" | string;
    onRemovePreviewMedia: (index: number) => void
}

const PreviewDisplay = (props: IPreviewDisplayProps) => (
    <Box style={{ padding: props.urls.length > 0 ? "15px" : 0, marginTop: props.urls.length > 0 ? 0 : "8px" }}>
        <Grid container spacing={1}>
            {props.urls.map((url, index) => (
                <Grid item xs={4} justifyContent="center" alignItems="stretch">
                    <ImagePreview src={url} alt={`Image ${index}`} style={{ height: "204px" }} />
                    <RemoveButton style={{ height: "fit-content", left: "80%" }} onClick={() => props.onRemovePreviewMedia(index)}>
                        <Close />
                    </RemoveButton>
                </Grid>
            ))}
        </Grid>
    </Box>
)

interface UploadedFile {
    id: number;
    filename: string;
    size?: number;
    content_type?: string;
    url: string;
}
type IMultipleFileUploadWithPreviewProps = {
    id?: string;
    required?: boolean;
    label?: string;
    labelStyle?: CSSProperties;
    triggerStyle?: CSSProperties;
    supportText?: string;
    fileType?: "video" | "image" | string;
    uploadingFileIcon?: string;
    value?: (UploadedFile | File | string)[];
    onChange?: (files?: (File | UploadedFile | string)[]) => void;
}

const MultipleFileUploadWithPreview = (props: IMultipleFileUploadWithPreviewProps) => {
    const { 
        id, 
        required, 
        label, 
        labelStyle, 
        triggerStyle,
        supportText, 
        fileType = "image/*",
        uploadingFileIcon, 
        value,
        onChange,
    } = props;
    const fileRef = useRef<HTMLInputElement>(null);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        handleFilesToPreview(acceptedFiles);
        setMediaList([...mediaList, ...acceptedFiles]);
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const [previewUrls, setPreviewUrls] = useState<string[]>([]);
    console.log("Initial value", value);
    console.log("Preview url", previewUrls);
    const [mediaList, setMediaList] = useState<(File | UploadedFile)[]>([]);

    useEffect(() => {
        if (value && value?.length) {
            const extractedPreviewUrls = value.map(each => {
                if (typeof each === "string") {
                    return each;
                }
                if (each instanceof File) {
                    return URL.createObjectURL(each);
                }
                return each.url;
            });
            setPreviewUrls(extractedPreviewUrls);
        }
    }, [value])
    useEffect(() => {
        if (onChange) {
            onChange(mediaList);
        }
    }, [mediaList])

    const handleFilesToPreview = (files: File[]) => {
        console.log("uploaded files", files);
        setPreviewUrls(prev => [...prev, ...files.map(file => {
            return URL.createObjectURL(file)
        })]);
        setMediaList(prev => [...prev, ...files]);
    }
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            handleFilesToPreview(Array.from(event.target.files));
        }
    }
    const handleFileUploadClick = () => {
        if (fileRef && fileRef.current) {
            fileRef.current.click();
        }
    };
    const removePreviewMedia = (index: number) => {
        setPreviewUrls(prev => [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)]);
        setMediaList(prev => [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)]);

        if (fileRef && fileRef.current) {
            fileRef.current.value = "";
        }
    }

    return (
        <Container>
            {label && (
                <Label 
                    htmlFor={id} 
                    role="alert" 
                    style={labelStyle}
                >
                    {label} {required && <span style={{ fontSize: '8px' }}>(Required <span style={{ color: "#DC2626" }}>*</span>)</span>}
                </Label>)}
            <PreviewDisplay urls={previewUrls} onRemovePreviewMedia={(index) => removePreviewMedia(index)} />
            <label htmlFor="uploadedImage" className="profile-upload-btn">
                <input
                    id="uploadedImage"
                    type="file"
                    accept={fileType}
                    ref={fileRef}
                    multiple={true}
                    style={{ display: "none" }}
                    onChange={handleChange}
                    {...getInputProps}
                />
                <FileUploadZone {...getRootProps()} style={triggerStyle}>
                    <img src={uploadingFileIcon} />
                    <RowStack>
                        <SupportText>{isDragActive ? "Is dragging file" : supportText}</SupportText>
                        <Button variant="ghost" size="sm" style={{ fontSize: "12px", lineHeight: "18px" }} onClick={handleFileUploadClick}>Browse</Button>
                    </RowStack>
                </FileUploadZone>
            </label>
        </Container>
    )
}

export default MultipleFileUploadWithPreview;

const Container = styled(Box)({
    width: '100%',
    maxWidth: '100%'
})
const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Rubik',
    paddingBottom: '4px',
    color: theme.palette.text.primary,
    lineHeight: '22px'
}))
const ImagePreview = styled('img')({
    width: "100%", 
    height: "100%", 
    borderRadius: "15px",
})
const RemoveButton = styled(IconButton)(({ theme }) => ({
    position: "relative",
    bottom: "85%",
    left: "40%",
    backgroundColor: "#D7D7D780", 
    padding: "3px", 
    borderRadius: 0,
    "& svg": { 
        height: "15px",
        width: "15px",
        fill: theme.palette.secondary.main 
    }
}))
const FileUploadZone = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "22px",
    height: "239px",
    border: `1px dashed ${theme.palette.secondary.contrastText}`,
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer"
}))
const RowStack = styled(Box)({
    display: "flex",
    alignItems: "center"
})
const SupportText = styled('span')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '10px',
    lineHeight: '12px',
}))