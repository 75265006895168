import { Box, CircularProgress, Menu, MenuItem, styled } from "@material-ui/core";
import  axios from 'axios';
import React, { CSSProperties, useRef, useState } from "react";
import ReactQuill, { Quill, ReactQuillProps } from "react-quill";
import 'react-quill/dist/quill.snow.css';

Quill.register('modules/counter', function (quill: any, options: { container: string }){
    const container = document.querySelector(options.container);
    
    quill.on('text-change', function() {
        if (container) {
            container.innerHTML = quill.root.innerHTML.length + '/1000';
            if (quill.root.innerHTML.length > 1000) {
                container.className = container.classList.value + " exceeded";
            } else {
                container.classList.remove('exceeded');
                container.className = container.classList.value;
            }
        }
    });
});
  
const modules = {
    toolbar: [
        ['bold', 'underline'],
        ['link'],
        [{ header: 1 }],
        [{ align: [] }],
        [{ list: 'bullet' }],
        ['clean'],
    ],
    counter: {
        container: "#counter"
    }
}

const initialState = {
    mouseX: null,
    mouseY: null,
};

interface RichTextEditorProps extends ReactQuillProps {
    id?: string;
    name?: string;
    required?: boolean;
    label?: string;
    labelStyle?: CSSProperties;
    bgColor?: string;
    errorText?: string;
}

const RichTextEditor = (props: RichTextEditorProps) => {
    const { id, required, label, labelStyle, bgColor, errorText, value, onChange, ...richTextEditorProps } = props;

    const quillRef = useRef<ReactQuill>(null);

    const [suggestionBoxPosition, setSuggestionBoxPosition] = useState<{
        mouseX: null | number;
        mouseY: null | number;
    }>(initialState);
    const [currentRange, setCurrentRange] = useState<any>();
    const [suggestionList, setSuggestionList] = useState<string[]>([]);
    console.log("Suggestions", suggestionList);

    const checkSpelling = async (content: string) => {
        try {
            const response = await axios.get('https://api.textgears.com/spelling', {
                params: {
                    key: 'yJ85cVqAAMVkya7O',
                    text: content
                }
            });
    
            const suggestions = response.data.response.errors[0].better;

            setSuggestionList(suggestions);
        } catch (error) {
            setSuggestionList([]);
        }
    }
    const handleSelectionChange = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (quillRef && quillRef.current) {
            const quill = quillRef.current.getEditor();
            const range = quill.getSelection();
            if (range && range.length > 0) {
                const selectedText = quill.getText(range.index, range.length);
                checkSpelling(selectedText);
    
                const bounds = quill.getBounds(range.index, range.length);
                setSuggestionBoxPosition({
                    mouseY: bounds.top + bounds.height + event.clientY,
                    mouseX: bounds.left + event.clientX - range.length
                });
                setCurrentRange(range);
            }
        }
    }
    const handleSuggestionClick = (suggestion: string) => {
        if (quillRef && quillRef?.current) {
            const quill = quillRef.current?.getEditor();
            if (currentRange) {
                quill.deleteText(currentRange.index, currentRange.length);
                quill.insertText(currentRange.index, suggestion);
                quill.setSelection(currentRange.index + suggestion.length);
            }
    
            setSuggestionBoxPosition(initialState);
        }
    }
    
    return (
        <Container>
            {label && (
                <Label 
                    htmlFor={id} 
                    role="alert" 
                    style={labelStyle}
                >
                    {label} {required && <span style={{ fontSize: '8px' }}>(Required <span style={{ color: "#DC2626" }}>*</span>)</span>}
                </Label>)}
            <Box id="quill-container" onContextMenu={handleSelectionChange} style={{ backgroundColor: "#FFF" }}>
                <ReactQuill
                    ref={quillRef}
                    modules={modules}
                    bounds="#quill-container"
                    style={{ backgroundColor: bgColor, border: !!errorText ? '1px solid #DC2626' : '' }}
                    value={value}
                    onChange={onChange}
                    {...richTextEditorProps}
                />
                <CustomMenu
                    keepMounted
                    open={suggestionBoxPosition.mouseY !== null}
                    onClose={() => setSuggestionBoxPosition(initialState)}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        suggestionBoxPosition.mouseY !== null && suggestionBoxPosition.mouseX !== null
                            ? { top: suggestionBoxPosition.mouseY, left: suggestionBoxPosition.mouseX }
                            : undefined
                    }
                >
                    {suggestionList.length > 0 && suggestionList.map((suggestion, index) => (
                        <CustomMenuItem key={index} onClick={() => handleSuggestionClick(suggestion)}>{suggestion}</CustomMenuItem>
                    ))}
                </CustomMenu>
            </Box>
            <CharacterCount id="counter">0/1000</CharacterCount>
            {errorText && <HintText>{errorText}</HintText>}
        </Container>
    )
}


export default RichTextEditor

const Container = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '100%',
    "& .ql-container": {
        borderRadius: '8px',
        height: "174px",
        border: 'none'
    },
    '& .quill': {
        position: 'relative',
        borderRadius: '8px',
        paddingBottom: 55,
        border: `1px solid ${theme.palette.info.dark}`,
    },
    "& .ql-toolbar": {
        borderRadius: '8px',
        border: `none`,
        position: "absolute",
        bottom: "10px",
        left: 10,
        width: "fit-content",
        transform: "translateY",
        zIndex: 1000,
        '& .ql-formats': {
        marginRight: 0
        },
        '& .ql-formats button, & .ql-formats .ql-picker': {
        border: '1px solid #94A3B8',
        marginRight: 2
        },
    },
    "& .ql-editor": {
        background: theme.palette.background.default,
        color: theme.palette.secondary.main,
        borderRadius: 8,
        fontSize: '14px',
        fontFamily: 'Rubik'
    },
    "& .ql-editor.ql-blank::before": {
        color: theme.palette.info.light,
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        fontSize: '14px'
    },
}))
const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Rubik',
    paddingBottom: '4px',
    color: theme.palette.text.primary,
    lineHeight: '22px'
}))
const HintText = styled('p')({
    display: 'inline-block',
    marginTop: '2px',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#DC2626'
})
const CharacterCount = styled(Box)({
    marginBottom: "2px",
    backgroundColor: "transparent",
    fontFamily: "Rubik",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#334155",
    textAlign: "right",
    "&.exceeded": { color: "#DC2626" }
})
const CustomMenu = styled(Menu)({
    width: "100%",
    "& .MuiMenu-paper": {
        minWidth: "128px",
        minHeight: "82px",
        backgroundColor: "#FFF",
        border: "1px solid #E2E8F0",
        borderRadius: "8px",
        boxShadow: "0 2px 8px 0 #00000014",
        padding: "4px",
        display: "flex",
        alignItems: "center"
    }
})
const CustomMenuItem = styled(MenuItem)({
    fontFamily: "Rubik",
    color: "#334155",
    fontSize: 14,
    lineHeight: "22px",
    padding: "8px 16px",
    borderRadius: 8,
    "&:hover": {
        backgroundColor: "#E8EDF5",
    },
    "&.Mui-selected": {
        backgroundColor: "#FFF !important",
        "&:hover": {
          backgroundColor: "#E8EDF5",
        }
    }
})