import { useState, useEffect } from "react";

import { db } from "../fetch-files";

interface UploadedFile {
    id: number;
    filename: string;
    size?: number;
    content_type?: string;
    url: string;
}
const useOfflineBlob = (file: UploadedFile) => {
    const [currentUrl, setCurrentUrl] = useState(file.url);

    const getFileUrl = async (key: number) => {
        const fileFromIndexedDB = await db.files.get(key);
        return fileFromIndexedDB?.data ? window.URL.createObjectURL(fileFromIndexedDB?.data) : "";
    };
    const getUrl = async (file: UploadedFile) => {
        if (navigator.onLine) {
            return file.url;
        } else {
            const localUrl = await getFileUrl(file.id);
            return localUrl;
        }
    }

    useEffect(() => {
        if (file) {
           getUrl(file).then(url => {
            setCurrentUrl(url);
           });
        }
    }, [file, file.id, file.url, file.content_type, file.filename, file?.size]);

    return currentUrl;
};

export default useOfflineBlob;