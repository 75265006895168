import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import { Dimensions } from "react-native";
import { getStorageData } from "../../../framework/src/Utilities";

type ScoreOptionValue = number | string | boolean;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  assigned_worker_id:number|null;
  scoreValue?: {
    score_options: {
      [key: string]: ScoreOptionValue;
    }[];
  };
  setError?: {
    score_title0?: string;
    score_title1?: string;
    score_title2?: string;
    score_title3?: string;
    score_title4?: string;
  };
  isEdit?: boolean;
  scoringQuestionFn: Function;
  quiz_data:any;
  onComplete: () => void;
  testType:string;
  quiz_id:string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  myID: number;
  opponentID: number;
  score: {
    first_user_score: number;
    second_user_score: number;
  };
  token: string;
  score_options?: {
    [key: string]: ScoreOptionValue;
  }[];
  option?: {
    [key: string]: ScoreOptionValue;
  }[];
  erorrs: {
    [key: string]: ScoreOptionValue;
  }[];
  firstUserName: string;
  secondUserName: string;
  isModalOpen: boolean;
  isPortrait: boolean;
  questions: Question[];
  questions_data: QuestionData[];
  correct_questions_data: CorrectQuestionData[];
  testScore: number | null;
  ModalOpen: boolean;
  selectedImage: string | null;
  isScoreOpen: boolean;
  showIncorrectAnswers: boolean;
  testPercentage: number|null;
  reDoQuiz: boolean;
  password: string[],
  isPassword:boolean;
  passStatus:string;
  isVerified:boolean;
  showFinalAssessment:boolean;
  isVerifiedText:string;
  courseError:string;
  selectedAnswers:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface DimensionType {
  screen: Screen;
  window: Screen;
}

export interface Screen {
  fontScale: number;
  height: number;
  scale: number;
  width: number;
}

interface Option {
  content: string;
  isCorrect: boolean;
};

interface OptionQuestion {
  id: string;
  content: boolean;
};

type QuestionType = "single" | "multi";

interface Question {
  id: number;
  type: QuestionType;
  attributes:{
    content:string;
    media_files_urls: MediaUrls[];
    final_test_choices:any;
    question_type:string;
  };
  image?: string[];
  options: Option[];
  selectedOption: string | string[];
};

interface MediaUrls{
  id:string;
  url:string;
}

interface QuestionData {
  id: number;
  type: QuestionType;
  attributes:{
    content:string;
    media_files_urls: MediaUrls[];
    final_test_choices?:any;
    choices?:any;
    question_type:string;
  };
  image?: string[];
  options: OptionQuestion[];
  selectedOption: string | string[];
};

interface CorrectQuestionData{
  id:number;
  question_id:{
    data:{
      attributes:{
        content:string,
        media_files_urls:MediaUrls[];
        choices:any;
        question_type:string;
      }
    }
  }
}

const dummyQuestions: Question[] = [
  {
    id: 1,
    type: "multi",
    attributes:{
      content:"What is the most common method for extracting oil from deep underground reservoirs?",
      media_files_urls:[],
      final_test_choices:[],
      question_type:"single_choice"
    },
    image: [imgPasswordInVisible, imgPasswordVisible],
    options: [
      { content: "Hydraulic Fracturing (Fracking)", isCorrect: true },
      { content: "Horizontal Drilling", isCorrect: false },
      { content: "Primary Recovery", isCorrect: true },
      { content: "Steam Injection", isCorrect: false },
    ],
    selectedOption: [],
  },
  {
    id: 2,
    type: "single",
    attributes:{
      content:"What is the most common method for extracting oil from deep underground reservoirs?",
      media_files_urls:[],
      final_test_choices:[],
      question_type:"multiple_choice"
    },
    options: [
      { content: "Propane", isCorrect: false },
      { content: "Methane", isCorrect: true },
    ],
    selectedOption: "",
  },
];
// Customizable Area End

export default class ScoringController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  deleteOptionCallId = "";
  getReasonId: string = "";
  requestFinalAssessApiCallId: string = "";
  verifyOtpAssessApiCallId: string = "";
  listFinaltestApiCallId: string = "";
  startTestCallId: string = "";
  submitFinalTestApiCallId: string = "";
  submitQuizTestApiCallId: string = "";
  retakeFinalAssessmentCallId: string = "";
  generateCertificateApiCallId: string = "";
  public inputRefs: React.RefObject<HTMLInputElement>[];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    
    let tempScoreOptionChecked = this.props.isEdit
      ? this.props?.scoreValue?.score_options?.map(item => {
        return {
          ...item,
          checked: typeof item === "number" ? item?.["answer"] > 0 : false
        };
      })
      : this.props?.scoreValue?.score_options?.map(item => {
        return {
          ...item,
          answer: item.answer ? item.answer : "0",
          checked: false
        };
      });

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      myID: 0,
      opponentID: 0,
      score: {
        first_user_score: 0,
        second_user_score: 0
      },
      token: "",
      score_options: tempScoreOptionChecked ? tempScoreOptionChecked : [],
      erorrs: [
        { value: "", title: "" },
        { value: "", title: "" }
      ],
      firstUserName: "",
      secondUserName: "",
      isModalOpen: false,
      isPortrait: this.isPortrait(),
      questions: dummyQuestions,
      questions_data:[],
      correct_questions_data:[],
      testScore: null,
      ModalOpen: false,
      selectedImage: null,
      isScoreOpen: false,
      showIncorrectAnswers: false,
      testPercentage: null,
      reDoQuiz: false,
      password: ["", "", "", ""],
      isPassword:false,
      passStatus:"",
      showFinalAssessment:false,
      isVerified:false,
      isVerifiedText:"",
      courseError:"",
      selectedAnswers:{},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.inputRefs = Array(4)
    .fill(null)
    .map(() => React.createRef<HTMLInputElement>());
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.error && !responseJson.errors) {
        if (apiRequestCallId == this.getReasonId) {
          this.setState({
            score: {
              first_user_score:
                responseJson.first_user_score?.data.attributes.score,
              second_user_score:
                responseJson.second_user_score?.data.attributes.score
            },
            firstUserName:
              responseJson.first_user_score?.data.attributes.user_name,
            secondUserName:
              responseJson.second_user_score?.data.attributes.user_name
          });
        } else if (apiRequestCallId == this.deleteOptionCallId) {
          toast.success("Option Deleted Successfully");
        }
        else if (apiRequestCallId == this.requestFinalAssessApiCallId) {
          this.setState({isPassword:true,isScoreOpen:false, isVerified:false, password:responseJson.assessments[0].assessment.otp.split(""), selectedAnswers:{}, courseError:""})

        }
        else if (apiRequestCallId == this.retakeFinalAssessmentCallId) {
          this.setState({isPassword:true,isScoreOpen:false, isVerified:false, showFinalAssessment:false, password:responseJson.otp.split(""), selectedAnswers:{}})

        }
        else if (apiRequestCallId == this.submitFinalTestApiCallId) {
          this.createCertificate()
          this.setState({isScoreOpen:true,passStatus:responseJson.result.status, testScore:responseJson.result.correct_answers,testPercentage: responseJson.result.percentage, selectedAnswers:{}})
        } 
        else if (apiRequestCallId == this.submitQuizTestApiCallId) {
          this.props.onComplete()
          this.setState({correct_questions_data:responseJson.details.incorrect, showFinalAssessment:false, isScoreOpen:true, testScore:responseJson.correct_answers,testPercentage: responseJson.score})
        }    
           
        else if (apiRequestCallId == this.verifyOtpAssessApiCallId) {
          this.setState({isVerified:true, showFinalAssessment:false})
          this.setState({isVerifiedText:"", password:["","","",""]})
        }
        else if (apiRequestCallId == this.listFinaltestApiCallId) {
          this.setState({isPassword:false, questions_data:responseJson.data.attributes?.final_test_questions?.data, showFinalAssessment:true, showIncorrectAnswers:false})
        }
        else if (apiRequestCallId == this.startTestCallId) {
          this.setState({showFinalAssessment:true})
          this.listFinalTestQuestionData()
        }      
        
      } else {
        if(apiRequestCallId == this.verifyOtpAssessApiCallId) {
          this.setState({isVerifiedText:responseJson.error})
          this.setState({isVerified:false})
        }
        else if (apiRequestCallId == this.startTestCallId) {
          this.setState({showFinalAssessment:false })
        }  
        else if (apiRequestCallId == this.requestFinalAssessApiCallId) {
          this.setState({courseError:responseJson.error})
        }
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let token = await getStorageData("authToken");
    if(this.props.testType === "quiz"){
      this.setState({questions_data:this.props.quiz_data.data?.attributes?.questions?.data})
      this.setState({showFinalAssessment:true})
    }
    if (token == null) {
      this.props.navigation.navigate("Home");
    } else {
      let loggedInUserId = await getStorageData("userId");
      this.setState(
        { token, myID: loggedInUserId, opponentID: configJSON.opponentID },
        () => {
          this.getScoreData(configJSON.gameID);
        }
      );

      Dimensions.addEventListener("change", this.getDimentions);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.reDoQuiz !== this.state.reDoQuiz && this.state.reDoQuiz) {
      console.log('Re-rendering due to reDoQuiz state update');
      this.setState({ reDoQuiz: false,});
    }
    if ( this.props.quiz_id && prevProps.quiz_id !== this.props.quiz_id) {
      this.setState({questions_data:this.props.quiz_data.data?.attributes?.questions?.data, isScoreOpen: false, showFinalAssessment:true, showIncorrectAnswers:false, selectedAnswers:{}})
    }
  }
  
  getScoreData = async (gameId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReasonId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserGameScoreApiEndpoint}?game[game_type_id]=${gameId}&account_id=${this.state.opponentID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDimentions = () => {
    this.setState({ isPortrait: this.isPortrait() });
  };

  isPortrait = () => {
    const dimension = Dimensions.get("screen");
    return dimension.height >= dimension.width;
  };

  changeChecked = (index: number, checked: boolean) => {
    let tempArr =
      this.state.score_options && this.state.score_options.length
        ? this.state.score_options
        : [];
    if (tempArr.length > 0) {
      tempArr[index]["checked"] = checked;
    } else {
      tempArr = [];
    }

    this.setState({ ...this.state, score_options: tempArr });
  };

  deleteOptions = (deletionId: number, scoringQuestionFn: Function) => {
    let tempArr =
      this.state.score_options && this.state.score_options.length
        ? this.state.score_options
        : [];
    if (tempArr.length > 0) {
      const scoringIndex = tempArr.findIndex(item => item.id === deletionId);
      if (scoringIndex !== -1) {
        tempArr[scoringIndex]["is_deleted"] = true;
      }
      this.setState({ ...this.state, score_options: tempArr });
      scoringQuestionFn(tempArr);
    } else {
      tempArr = [];
      this.setState({ ...this.state, score_options: tempArr });
    }
  };

  handleEdit = (index: number, scoringQuestionFn: Function) => {
    if (!this.props.isEdit) {
      let tempArr =
        this.state.score_options && this.state.score_options.length
          ? this.state.score_options
          : [];
      let error =
        this.state.erorrs && this.state.erorrs.length ? this.state.erorrs : [];
      if (tempArr.length > 2) {
        tempArr.splice(index, 1);
        error.splice(index, 1);

        this.setState({ ...this.state, score_options: tempArr, erorrs: error });
        this.props.scoringQuestionFn(tempArr);
      }
    } else {
      this.deleteOptions(
        this.props.scoreValue?.score_options[index].id as number,
        scoringQuestionFn
      );
    }
  };

  addOption = (scoringQuestionFn: Function) => {
    const initailScoringData =
      this.state.score_options && this.state.score_options.length
        ? this.state.score_options
        : [];
    const scoreData = { title: "", answer: "0", checked: false };
    const erorrs =
      this.state.erorrs && this.state.erorrs.length ? this.state.erorrs : [];
    erorrs.push({ title: "", answer: "" });
    initailScoringData.push(scoreData);
    this.setState({
      score_options: initailScoringData,
      erorrs
    });
    scoringQuestionFn(initailScoringData);
  };

  handleChange = (
    event: number | string,
    index: number,
    name: string,
    scoringQuestionFn: Function
  ) => {
    const option =
      this.state.score_options && this.state.score_options.length
        ? this.state.score_options
        : [{ value: "", title: "" }];
    option[index][name] = event;
    if (name === "answer" && 100 < Number(event)) {
      const error =
        this.state.erorrs && this.state.erorrs.length
          ? this.state.erorrs
          : [{ value: "", title: "" }];
      error[index]["value"] = "Max number is 100";
      this.setState({
        erorrs: error
      });
      return false;
    }

    this.setState({
      score_options: option
    });
    scoringQuestionFn(option);
  };

  determineWinner() {
    const { firstUserName, secondUserName, score } = this.state;

    if (score.first_user_score > score.second_user_score) {
      const winnerName =
        firstUserName.length > 15
          ? `${firstUserName.slice(0, 15)}...`
          : firstUserName;
      const loserName =
        secondUserName.length > 15
          ? `${secondUserName.slice(0, 15)}...`
          : secondUserName;
      return `${winnerName} has won by ${loserName}`;
    } else if (score.first_user_score < score.second_user_score) {
      const winnerName =
        secondUserName.length > 15
          ? `${secondUserName.slice(0, 15)}...`
          : secondUserName;
      const loserName =
        firstUserName.length > 15
          ? `${firstUserName.slice(0, 15)}...`
          : firstUserName;
      return `${winnerName} has won by ${loserName}`;
    } else {
      return "Game Tied";
    }
  }


  handleOptionChange = (
    questionId: number,
    choiceId: number,
    questionType: "single_choice" | "multiple_choice"
  ) => {
    this.setState((prevState) => {
      if (questionType === "single_choice") {
        return {
          selectedAnswers: {
            ...prevState.selectedAnswers,
            [questionId]: choiceId,
          },
        };
      } else {
        const currentSelection = prevState.selectedAnswers[questionId] || [];
        const updatedSelection = currentSelection.includes(choiceId)
          ? currentSelection.filter((id:number) => id !== choiceId) 
          : [...currentSelection, choiceId]; 
  
        return {
          selectedAnswers: {
            ...prevState.selectedAnswers,
            [questionId]: updatedSelection,
          },
        };
      }
    });
  };
  
  getFinalTestAnswers = () => {
    return {
      student_course_assignment_id: this.props.assigned_worker_id,
      answers: Object.entries(this.state.selectedAnswers).map(
        ([questionId, choiceIds]) => ({
          final_test_question_id: Number(questionId),
          final_test_choice_id: Array.isArray(choiceIds) ? choiceIds : choiceIds, 
        })
      ),
    };
  };

  getQuizAnswers = () => {
    return {
      quiz_id: Number(this.props.quiz_data.data?.id),
      answers: Object.entries(this.state.selectedAnswers).map(
        ([questionId, choiceIds]) => ({
          question_id: Number(questionId),
          choice_id: Array.isArray(choiceIds) ? [choiceIds]: choiceIds
        })
      ),
    };
  };
  
  handleSubmit = async () => {

    const token = await getStorageData("authToken"); 

    const data = this.getFinalTestAnswers()
    const data2 = this.getQuizAnswers()

    if(this.props.testType === "quiz"){
      this.submitQuizTestApiCallId = await this.apiCall({
        method2: configJSON.exampleAPiMethod,
        contentType2: configJSON.validationApiContentType,
        endPoint2: configJSON.submitQuiz,
        body2: JSON.stringify(data2),
        headers2: { 
          token 
        },
      });
    }else{
      this.submitFinalTestApiCallId = await this.apiCall({
        method2: configJSON.exampleAPiMethod,
        contentType2: configJSON.validationApiContentType,
        endPoint2: `${configJSON.submitTest}?student_course_assignment_id=${this.props.assigned_worker_id}`,
        body2: JSON.stringify(data),
        headers2: { 
          token 
        },
      });
    }

  };

  handleRightAnswer = async () => {
    this.setState({showIncorrectAnswers:true, isScoreOpen:false})

  }
  openModal = (image: any) => {
    this.setState({ ModalOpen: true, selectedImage: image });
  };

  closeModal = () => {
    this.setState({ ModalOpen: false, selectedImage: null });
  };

  getIncorrectQuestions() {
    return this.state.questions.filter((q) => {
      if (q.type === "single") {
        return !q.options.some((opt) => opt.isCorrect && opt.content === q.selectedOption);
      } else if (q.type === "multi") {
        const correctOptions = q.options
          .filter((opt) => opt.isCorrect)
          .map((opt) => String(opt.content));
        const selectedOptions = Array.isArray(q.selectedOption)
          ? q.selectedOption.map((opt) => String(opt))
          : [];
        const hasExtraSelections = selectedOptions.some((opt) => !correctOptions.includes(opt));
        const isMissingCorrect = correctOptions.some((opt) => !selectedOptions.includes(opt));

        return hasExtraSelections || isMissingCorrect;
      }
      return false;
    });
  }

  handleReDoQuix = () => {
    this.setState({reDoQuiz: true, showIncorrectAnswers: false, testScore: null, testPercentage: null, isScoreOpen: false, questions_data: this.state.questions_data, selectedAnswers:{}})
  }

  getCheckboxClass = (showIncorrectAnswers: any) => {
    return showIncorrectAnswers ? "checkboxStyle" : "unCheckboxStyle";
  }
  
  isCheckboxChecked = (showIncorrectAnswers: any, opt: any, q: any) => {
    return showIncorrectAnswers ? opt.isCorrect : q.selectedOption === opt.content;
  }
  
  isOptionChecked = (showIncorrectAnswers: any, opt: any, q: any) => {
    return showIncorrectAnswers 
      ? opt.isCorrect 
      : Array.isArray(q.selectedOption) && q.selectedOption.includes(opt.content);
  }


  apiCall = async (data: { [key: string]: any }) => {
    const { method2, endPoint2, body2, type2, contentType2 } = data;
    let apiBody = body2;
    if (type2 === '') {
      apiBody = JSON.stringify(body2);
    }
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": contentType2,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint2
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.baseURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method2
    );
    body2 &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  requestFinalAssessment = async () => {
    const token = await getStorageData("authToken"); 
   
    let data = new FormData();
    data.append(
      "student_course_assignment_id",
      this.props.assigned_worker_id ? String(this.props.assigned_worker_id) : ""
    );
    
    this.requestFinalAssessApiCallId = await this.apiCall({
      method2: configJSON.exampleAPiMethod,
      endPoint2: configJSON.requestFinalAssessmentEndpoint,
      body2: data,
      headers2: { 
        token 
      },
    });
    
  }

  startFinalTest = async () =>{
    const token = await getStorageData("authToken"); 

    this.startTestCallId = await this.apiCall({
      method2: configJSON.validationApiMethodType,
      endPoint2: `${configJSON.startTestEndpoint}?student_course_assignment_id=${this.props.assigned_worker_id}`,
      headers2: { 
        token 
      },
    });
  }

  listFinalTestQuestionData =  async() =>{
    const token = await getStorageData("authToken"); 

    this.listFinaltestApiCallId = await this.apiCall({
      method2: configJSON.validationApiMethodType,
      endPoint2: `${configJSON.listFinalTestEndPoint}?student_course_assignment_id=${this.props.assigned_worker_id}`,
      headers2: { 
        token 
      },
    });
  }


  retakeFinalAssessment = async () =>{
    const token = await getStorageData("authToken"); 

    this.retakeFinalAssessmentCallId = await this.apiCall({
      method2: configJSON.exampleAPiMethod,
      endPoint2: `${configJSON.retakeFinalTest}?student_course_assignment_id=${this.props.assigned_worker_id}`,
      headers2: { 
        token 
      },
    });
  }

  handleChangePasswordInput = (index:number, e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 1) return;

    let newPassword = [...this.state.password];
    newPassword[index] = value;
    this.setState({ password: newPassword });

    if (value && index < 3) {
      this.inputRefs[index + 1].current?.focus();
    }
  };

  handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && !this.state.password[index] && index > 0) {
      this.inputRefs[index - 1]?.current?.focus();
    }
  };

  handlePasswordSubmit = async () => {
    const { password } = this.state;
    const enteredPassword = password.join("");
    const token = await getStorageData("authToken"); 

    this.verifyOtpAssessApiCallId = await this.apiCall({
      method2: configJSON.validationApiMethodType,
      endPoint2: `${configJSON.verifyOtp}?otp=${enteredPassword}&student_course_assignment_id=${this.props.assigned_worker_id}`,
      headers2: { 
        token 
      },
    });
  };

  createCertificate = async () =>{
    const token = await getStorageData("authToken"); 
    const course_id = await getStorageData("courseId"); 
    const profile_id = await getStorageData("user_role_id"); 

   
    let data = new FormData();
    data.append( "employee_certificate[student_course_assignment_id]", this.props.assigned_worker_id ? String(this.props.assigned_worker_id) : "" );
    data.append( "employee_certificate[course_id]", course_id );
    data.append( "employee_certificate[profile_id]", profile_id);
    
    this.generateCertificateApiCallId = await this.apiCall({
      method2: configJSON.exampleAPiMethod,
      endPoint2: configJSON.createCertificate,
      body2: data,
      headers2: { 
        token 
      },
    });

  }
  
  // Customizable Area End
}
