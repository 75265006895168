import { Close } from "@material-ui/icons";
import React, { CSSProperties } from "react";

import Card from "./Card";
import { handleFileSize } from "./handle-file-size";
import IconButton from "./IconButton";

const videoIcon = require("./assets/video-icon.svg");
const pdfIcon = require("./assets/pdf-icon.svg");
const audioIcon = require("./assets/audio-icon.svg");
const zipIcon = require("./assets/zip-icon.svg");
const docIcon = require("./assets/doc-icon.svg");

interface UploadedFile {
    id: number;
    filename: string;
    size?: number;
    content_type?: string;
    url: string;
}

const FilePreviewCard = ({ file, style, onRemoveFileButtonClick }: { file: UploadedFile; style?: CSSProperties; onRemoveFileButtonClick: () => void }) => {
    const getFileTypeIcon = (fileType: string) => {
        if (fileType.includes("video")) {
            return videoIcon;
        }
        if (fileType.includes("audio")) {
            return audioIcon;
        }
        if (fileType.includes("zip")) {
            return zipIcon;
        }
        if (fileType.includes("doc")) {
            return docIcon;
        }
        return pdfIcon;
    }

    return (
        <Card 
            thumbnailSrc={getFileTypeIcon(file.content_type || "")}
            title={file.filename} 
            subtitle={handleFileSize(file.size || 0)} 
            actionButton={<IconButton onClick={onRemoveFileButtonClick}><Close /></IconButton>} 
            style={{ marginTop: "10px", width: "70%", ...style }}
        />
    )
}

export default FilePreviewCard;