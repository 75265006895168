import React from "react";

// Customizable Area Start
import {
  Container,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  TextField,
  IconButton,
  Grid,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { styled } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RootRef from "@material-ui/core/RootRef";
import InboxIcon from "@material-ui/icons/Inbox";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import DragDropInterfaceController, {
  ICardProps,
  Props,
  configJSON,
} from "./DragDropInterfaceController";
import { audioBrowseIcon, audioIcon, imageBrowseIcon, pdfDragIcon, pdfIcon, presentationBrowseIcon, videoBrowseIcon, videoIcon, zipIcon } from "./assets";
import { handleFileSize } from "../../../../packages/blocks/utilities/src/handle-file-size";

export default class DragDropInterface extends DragDropInterfaceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCustomizeIcon = (icon?:string) => {
   return (
    <ColStack style={webStyle.imageTextBox}>
    <img src={icon || pdfDragIcon} />
    <label htmlFor="fileFromLocal">
        <input
            id="fileFromLocal"
            type="file"
            accept={this.props.fileType}
            ref={this.fileRef}
            multiple={this.props.multiple}
            style={{ display: "none" }}
            data-test-id="drag-drop-input"
            onChange={this.onhandleChange}
        />
            <SupportText>Drag and Drop Files or <BrowseText>Browse</BrowseText></SupportText> 
    </label>
    </ColStack>
   )
  }

  renderDifferentIcons = () => {
    if(this.props.fileType){
      if (this.props.fileType.includes("video")) {
        return videoBrowseIcon;
    }
    if (this.props.fileType.includes("audio")) {
        return audioBrowseIcon;
    }
    if (this.props.fileType.includes("zip")) {
        return pdfDragIcon;
    }
    if (this.props.fileType.includes("presentation")) {
      return presentationBrowseIcon;
    }
    if (this.props.fileType.includes("image")) {
      return imageBrowseIcon;
    }
    return pdfDragIcon;
    }
  }

  renderPdfInput = () => {
    return (
<FileUploadWithoutPreviewZone data-test-id="drag-input" onClick={this.handleFileUploadClick}>
  <Grid container style={{...webStyle.fullHeight,gap:"10px"}}>
{(this.state.fileName !== "" && !this.props.disableBrowsing) || (this.props.multiple && this.state.fileList.length > 0) ?  (
  <>
  {this.state.fileList.map((file,index)=>(
    <>
  <Grid xs={this.props.multiple ? 5 : 12} item style={webStyle.fullHeight}>
                        <Card 
                            thumbnailSrc={this.getFileTypeIcon(this.props.fileType as string)} 
                            title={file.name} 
                            subtitle={handleFileSize(file.size)} 
                            actionButton={<IconButton style={{color:"#1A469C"}} onClick={(event) => this.removePreviewMedia(event,index)}><CloseIcon /></IconButton>} 
                         />
                    </Grid>
  {index % 2 === 0 && this.props.multiple && <Grid xs={1} item style={{...webStyle.fullHeight,...webStyle.flexCenter}}>
  <Box style={{...webStyle.fullHeight,borderLeft:"2px solid #D7D7D780"}}></Box>
</Grid>}
</>
  ))}
  { this.props.multiple &&
  <Grid xs={5} item style={webStyle.fullHeight}>
                {this.renderCustomizeIcon(this.renderDifferentIcons())}
                </Grid>
  }
  </>
                    ) : (
                      <>
  <Grid xs={12} item style={webStyle.fullHeight}>
  {this.renderCustomizeIcon(this.renderDifferentIcons())}
  </Grid>
                    </>
                    )}
                    </Grid>
                </FileUploadWithoutPreviewZone>
    )
  }

   getFileTypeIcon = (fileType: string) => {
    if (fileType.includes("video")) {
        return videoIcon;
    }
    if (fileType.includes("audio")) {
        return audioIcon;
    }
    if (fileType.includes("zip")) {
        return zipIcon;
    }
    return pdfIcon;
}

 removePreviewMedia = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>,index?: number) => {
  event.stopPropagation()
  if (!this.props.multiple) {
      if (!this.props.withoutPreview) {
          this.setState({previewUrl:""});
      } else {
        this.setState({fileName:"",fileSize:0});
      }
      if (this.fileRef && this.fileRef.current) {
          this.fileRef.current.value = "";
      }
      this.setState({fileList:[]});
  } else {
      if (typeof index === "number") {
      this.setState({previewUrls:[...this.state.previewUrls.slice(0, index), ...this.state.previewUrls.slice(index + 1, this.state.previewUrls.length)],
        fileList:this.state.fileList ? [...this.state.fileList?.slice(0, index), ...this.state.fileList?.slice(index +1, this.state.fileList.length)] : []});
      }
  }
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
       {this.renderPdfInput()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  DDForm: {
    display: "flex",
    flexlow: "row wrap",
    alignItems: "center",
  },
  imageTextBox:{
    justifyContent:"space-around",
    alignItems:"center",
    height:"100%"
  },
  fullHeight:{
    height:"100%"
  },
  flexCenter:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  }
};

const BrowseText = styled('span')(({ theme }) => ({
  color: "#1A469C",
  fontSize: '12px',
  lineHeight: '12px',
  fontWeight:700
}))

const SupportText = styled('span')(({ theme }) => ({
  color: "#334155",
  fontSize: '10px',
  lineHeight: '12px',
  fontWeight:400
}))

const BrowseButton = styled(Button)({
  paddingLeft: "60px", 
  paddingRight: "60px", 
  borderRadius: "30px"
})

const RowStack = styled(Box)({
    display: "flex",
    alignItems: "center"
})

const FileUploadWithoutPreviewZone = styled(Box)(({ theme }) => ({
    height: '90px',
    overflowY:"auto",
    width: "100%",
    borderRadius: '8px',
    border: `1px solid ${theme.palette.info.dark}`,
    padding: '10px',
    backgroundColor: "#E8EDF5"
}))

export const Card = (props: ICardProps) => {
  const { thumbnailSrc = "", title, subtitle = "", children, actionButton, style } = props;

  return (
      <CustomizeContainer style={style}>
          <RowStack style={{ justifyContent: "space-between" }}>
              <RowStack style={{ gap: "12px" }}>
                  <ThumbnailImage src={thumbnailSrc} alt="Thumbnail Image" />
                  <ColStack style={{ gap: "2px" }}>
                      <CardTypo>{title}</CardTypo>
                      {subtitle && <SubtitleTypo>{subtitle}</SubtitleTypo>}
                  </ColStack>
              </RowStack>
              <RowStack style={{ gap: "22px" }}>
                  {children}
                  {actionButton}
              </RowStack>
          </RowStack>
      </CustomizeContainer>
  )
}

const ColStack = styled(Box)({
  display: "flex",
  flexDirection: "column"
})
const CustomizeContainer = styled(Box)({
  width: '100%',
  border: "0.5px solid #E2E8F0",
  borderRadius: "18px",
  padding: "8px 16px",
  backgroundColor: "#FFF"
})
const ThumbnailImage = styled('img')({
  width: '42px',
  height: '42px',
  objectFit: 'cover',
  objectPosition: 'center'
})
const CardTypo = styled(Typography)({
  fontFamily: "Rubik",
  fontSize: "12px",
  lineHeight: "18px",
  color: "#0F172A"
})
const SubtitleTypo = styled(CardTypo)({
  color: "#475569"
})
// Customizable Area End
