export function FormDataBuilder(object: Object, form?: FormData, namespace?: string): FormData {
   const formData = form || new FormData();
   Object.keys(object).forEach((property) => {
    const formKey = namespace ? `${namespace}[${property}]` : property;
    if (!object[property as keyof Object]) return;
    if (object[property as keyof Object] instanceof Date) {
        formData.append(formKey, object[property as keyof Object].toString());
    } else if (typeof object[property as keyof Object] === 'object' && !(object[property as keyof Object] instanceof File)) {
        if (Array.isArray(object[property as keyof Object]) && object[property as keyof Object].length > 0 
            // @ts-ignore
            && (object[property as keyof Object][0] instanceof File || object[property as keyof Object][0]?.filename)) {
            // @ts-ignore
            object[property as keyof Object].map(each => {
                if (each instanceof File) {
                    // @ts-ignore
                    formData.append(`${formKey}[]`, each);
                } else {
                    FormDataBuilder(each, formData, `${formKey}[]`);
                }
            })
        } else {
            FormDataBuilder(object[property as keyof Object], formData, formKey);
        }
    } else {
        // @ts-ignore
        formData.append(formKey, object[property as keyof Object]);
    }
   });
   return formData;
}

export default FormDataBuilder;