import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import { IStudentDetail } from './types/types';
import { Moment } from 'moment';
import { getStorageData ,setStorageData} from "../../../framework/src/Utilities";
export type FieldValue = string | number | boolean | Date | null | Moment;
export type TeamMember = {
  id: string;
  type: string;
  attributes: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    preferred_name: string;
    role_id: string;
    employee_number: number;
    account_id: number;
    image: string;
    courses_count: number;
    number_of_certificates: number;
    overall_progress: number;
    group_names: string;
    company_name: string;
    company_id: number[];
  }
}
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loginToken: string;
  accountId: string;
  studentDetails: IStudentDetail[] | null;
  selectedCompany: string;
  selectedGroup: string;
  selectedCourse: string;
  selectedStatus: string;
  sortType: string;
  isLoading:boolean;
  enableExpDate: boolean;
  enableRedLabel: boolean;
  searchValue: string;
  currentPage: number;
  currentRole: string;
  allCompanyName: { [key: string]: FieldValue }[];
  allGroupName: { [key: string]: FieldValue }[];
  allCourseName: { attributes: { [key: string]: FieldValue } }[];
  selectedRadio: string[];
  anchorSort: null | HTMLElement;
  enableSearchBar: boolean;
  totalPage: number;
  darkTheme:boolean;
  sideMenu: boolean;
  orderBy: "courses_count" | "no_of_certificates" | "overall_progress" | "";
  open:boolean;
  teamMembers: TeamMember[];
  isPageDropdownOpen: boolean;
  selectedPageSize: number;
  orderByType: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PtStudentInfoScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getScoresForAccountApiCallId: string = '';
  getTeamMembersApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loginToken: '',
      accountId: '',
      studentDetails: null,
      selectedCompany: "",
      selectedCourse: "",
      selectedGroup: "",
      selectedStatus: "",
      sortType: "",
      isLoading:false,
      enableExpDate: false,
      enableRedLabel: false,
      searchValue: "",
      currentPage: 1,
      currentRole: "",
      allCompanyName: [],
      allGroupName: [],
      allCourseName: [],
      selectedRadio: [],
      anchorSort: null,
      enableSearchBar: false,
      totalPage: 7,
      darkTheme:false,
      sideMenu: false,
      orderBy: "",
      open:false,
      teamMembers: [],
      isPageDropdownOpen: false,
      selectedPageSize: 10,
      orderByType: "asc"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getToken();
    this.getTeamMembers();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    let menuState: any = localStorage.getItem('open');
    if (menuState != null) {
      this.setState({ open: JSON.parse(menuState) })
    }
    this.checkTheme();
    window.addEventListener('storage', this.handleStorageChange)
  }

  handleStorageChange(event :any) {
    if (event.key === 'darkMode') {
      this.checkTheme();
    }
  }
  
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage),
    );
    this.send(message);
  };

  getScoresForAccount = (account_id: number) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getScoresForAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getScoresForAccountApiEndpoint.replace(
        ':account_id',
        account_id,
      ),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSignedUpCoursesMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage),
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const loginToken: string = message.getData(
        getName(MessageEnum.SessionResponseToken),
      );
      runEngine.debugLog('TOKEN', loginToken);
      this.setState({ loginToken });
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const studentData = message.getData(
        getName(MessageEnum.SessionResponseData),
      );
      const accountId  = studentData?.accountId;
      if (accountId)
        this.setState({ accountId }, () => this.getScoresForAccount(accountId));
    }
    // GET scores for account
    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getScoresForAccountApiCallId != null &&
      this.getScoresForAccountApiCallId === apiRequestCallId
    ) {
      this.setState({
        studentDetails: responseJson?.data,
      });
    }

    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTeamMembersApiCallId != null &&
      this.getTeamMembersApiCallId === apiRequestCallId
    ) {
      this.setState({
        teamMembers: responseJson.data,
        totalPage: responseJson.meta.total_pages,
        currentPage: Number(responseJson.meta.current_page)
      })
    }
  }

  handleMenu=()=>{
    this.setState({open:!this.state.open});
    localStorage.setItem('open',JSON.stringify(!this.state.open));
  }
  
  handleTheme=()=>{
    this.setState({darkTheme:!this.state.darkTheme})
    setStorageData('darkMode', `${!this.state.darkTheme}`)
  }

  checkTheme = async () => {
    const mode = await getStorageData("darkMode");
    if (mode === 'true') {
      this.setState({ darkTheme: true })
    }
    else {
      this.setState({ darkTheme: false })
    }
  }

  goBack=()=>{this.props.navigation.goBack()}

  handleOrderBy = (orderBy: "courses_count" | "no_of_certificates" | "overall_progress" | "") => {
    this.setState({ orderBy: this.state.orderBy !== orderBy ? orderBy : "", currentPage: 1 }, () => this.getTeamMembers())
  }

  resetFilters = () => {
    this.setState({ orderBy: "", currentPage: 1, searchValue: "" }, () => this.getTeamMembers())
  }

  handleOrderByType = (orderByType: string) => {
    this.setState({ orderByType }, () => this.getTeamMembers())
  }

  togglePageDropdown = () => {
    this.setState((prevState) => ({
      isPageDropdownOpen: !prevState.isPageDropdownOpen,
    }));
  };

  handlePageSizeChange = (size: number) => {
    this.setState({ selectedPageSize: size }, () => {
      this.getTeamMembers();
    });
  };

  goToPerformaceTracker = (teamMemberId: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'PerformanceTracker',
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { data: "PerformanceTracker", teamMemberId });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    setStorageData('activePath', "PerformanceTracker")
    setStorageData("currentRoute", "PerformanceTracker");

    this.send(message);
  }

  getTeamMembers = () => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getTeamMembersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTeamMembersApiEndpoint}?search_query=${this.state.searchValue}&per_page=${this.state.selectedPageSize}&page=${this.state.currentPage}&sort_order=${this.state.orderByType}${this.state.orderBy ? `&sort=${this.state.orderBy}` : ""}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSignedUpCoursesMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
