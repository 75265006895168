import React from "react";

import FilePreviewCard from './Card';
import { handleFileSize } from "./handle-file-size";
import useOfflineBlob from "./hooks/useOfflineBlob";

import { UploadedFile } from "../../projectnotes/src/ProjectnotesController";

const pptFileIcon = require("./assets/ppt-file-icon.svg")
const pdfFileIcon = require("./assets/pdf-icon.svg")

const DocViewer = ({ file }: { file: UploadedFile }) => {
    const currentUrl = useOfflineBlob(file);

    const getIcon = () => {
        switch (file.content_type) {
          case "application/pdf":
            return pdfFileIcon;
          case "application/vnd.ms-powerpoint":
            return pptFileIcon;
          default:
            return "";
        }
    }

    if (file.content_type && file.content_type?.includes("image")) {
        return (<img src={currentUrl} alt={file.filename} style={{ height: "204px", width: "100%", objectFit: "cover" }} />)
    }

    return (
       <>
        <FilePreviewCard 
            thumbnailSrc={getIcon()}
            title={file.filename} 
            subtitle={handleFileSize(file.size ?? 0)} 
            style={{ borderRadius: "18px", border: "0.52px solid #FFF", backgroundColor: "#F9F9F9" }}
        />
        <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${currentUrl}`}
            width="100%"
            height="100%"
            frameBorder="0"
        ></iframe>
    </>
    )
}

export default DocViewer;