import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import { ICourse } from './types/types';
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loginToken: string;
  availableCourses: ICourse[];
  createCourseModalVisible: boolean;
  courseNameInput: string;
  courseDescriptionInput: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PtCoursesScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createUpdateCourseApiCallId: string = '';
  deleteCourseApiCallId: string = '';
  getAvailableCoursesApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loginToken: '',
      availableCourses: [],
      createCourseModalVisible: false,
      courseNameInput: '',
      courseDescriptionInput: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage),
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage),
    );

    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const loginToken = message.getData(
        getName(MessageEnum.SessionResponseToken),
      );
      runEngine.debugLog('TOKEN', loginToken);
      this.setState({ loginToken }, () => {
        this.getAvailableCourses();
      });
    }
    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAvailableCoursesApiCallId != null &&
      this.getAvailableCoursesApiCallId === apiRequestCallId
    ) {
      if (responseJson?.data) {
        this.setState({
          availableCourses: responseJson.data,
        });
      }
    }
    //create course
    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createUpdateCourseApiCallId != null &&
      this.createUpdateCourseApiCallId === apiRequestCallId
    ) {
      this.hideCreateCourseModal();
      this.getAvailableCourses();
    }
    //delete course
    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteCourseApiCallId != null &&
      this.deleteCourseApiCallId === apiRequestCallId
    ) {
      this.showAlert('Course deleted', '');
      this.getAvailableCourses();
    }
  }

  navigateToViewTestsForCourse = (course: ICourse) => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'PtTestsForCourseScreen',
    );

    requestMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      courseId: course.attributes.id,
    });
    requestMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage,
    );

    this.send(requestMessage);
    // runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToViewStudentsOnCourse = (course: ICourse) => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'PtStudentsOnCourseScreen',
    );

    requestMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      courseId: course.attributes.id,
    });
    requestMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage,
    );

    this.send(requestMessage);
  };

  getAvailableCourses = () => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getAvailableCoursesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAvailableCoursesApiEndpoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //POST create course
  createCourse = async (courseName: string, courseDescription: string) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const body = {
      performance_tracker_course: {
        name: courseName,
        description: courseDescription,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.createUpdateCourseApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createUpdateCourseApiEndpoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createUpdateMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //DELETE course
  deleteCourse = (course_id: number) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.deleteCourseApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteCourseApiEndpoint.replace(':id', course_id),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showCreateCourseModal = () => {
    this.setState({ createCourseModalVisible: true });
  };

  hideCreateCourseModal = () => {
    sessionStorage.setItem("step","0");
    this.setState({ createCourseModalVisible: false });
  };

  setCourseNameInput = (courseNameInput: string) => {
    this.setState({ courseNameInput });
  };

  setCourseDescriptionInput = (courseDescriptionInput: string) => {
    this.setState({ courseDescriptionInput });
  };
  // Customizable Area End
}
