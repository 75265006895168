import Dexie, { EntityTable } from 'dexie';

interface BlobData {
  id: number;
  data: Blob;
}

const db = new Dexie('FilesDatabase') as Dexie & {
  files: EntityTable<
    BlobData,
    'id' // primary key "id" (for the typings only)
  >;
};

// Schema declaration:
db.version(1).stores({
  files: '++id, data' // primary key "id" (for the runtime!)
});

export type { BlobData };
export { db };

export const downloadFileFromUrl = async (id: number, url: string) => {
    const response = await fetch(url);
  
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
  
    const blob = await response.blob();

    // Name of the store that will hold your data.
    // const storeName = url;

    try {
      // Add the new friend!
      await db.files.add({
        id,
        data: blob,
      });
    } catch (error) {
        console.log(`Failed to add ${url}: ${error}`);
    }
    // return new File([blob], "downloadedFile", { type: blob.type });
};