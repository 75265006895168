import { Box, Dialog, Slide, styled } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Close } from "@material-ui/icons";
import React, { CSSProperties, forwardRef, ReactElement, Ref, useEffect, useState } from "react";
import JSZip from 'jszip';

import { handleFileSize } from "./handle-file-size";
import Card from "./Card";
import Button from "./Button";
import IconButton from "./IconButton";
import { db } from "./fetch-files";

import { UploadedFile } from "../../projectnotes/src/ProjectnotesController";

const zipFileIcon = require("../../projectnotes/assets/zip-file-ic.svg");
const expandIcon = require("../../projectnotes/assets/expand.png");

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

type IScormFilePreviewProps = { 
    file: UploadedFile;
    style?: CSSProperties;
    onComplete?: () => void;
}

const ScormFilePreview = ({
    file,
    style,
    onComplete,
}: IScormFilePreviewProps) => {
    const [htmlContent, setHtmlContent] = useState<string | null>(null);
    const [currentFile, setCurrentFile] = useState<File | null>(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (currentFile) {
            const zip = new JSZip();
            zip.loadAsync(currentFile).then((zip) => {
                zip.file(`${file.filename.split('.')[0] || ""}/index.html`)?.async('string').then(function success(content) {
                    // use the content
                    setHtmlContent(content);
                }, function error(e) {
                    // handle the error
                    console.log('File error', e);
                });
            });
        }
    }, [currentFile]);

    const downloadFileFromUrl = async (): Promise<File> => {
        const response = await fetch(file.url);
      
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      
        const blob = await response.blob();
        return new File([blob], file.filename, { type: blob.type });
    };
    const handleViewTraining = async () => {
        if (navigator.onLine) {
            downloadFileFromUrl().then(res => {
                setCurrentFile(res);
                handleClickOpen();
            });
        } else {
            const fileFromIndexedDB = await db.files.get(file.id);
            setCurrentFile(new File([fileFromIndexedDB?.data as BlobPart], file.filename, {
                type: fileFromIndexedDB?.data.type,
            }));
            handleClickOpen();
        }
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
      const handleClose = () => {
        setOpen(false);
        onComplete && onComplete();
    };

    return (
        <ColStack style={{ flex: 1, ...style }}>
            <Box style={{ padding: "0 20px 19px" }}>
                <Header>
                    <Card 
                        thumbnailSrc={zipFileIcon}
                        title={file.filename} 
                        subtitle={handleFileSize(file.size || 0)} 
                        style={{ borderRadius: "18px", border: "0.52px solid #FFF", backgroundColor: "#F9F9F9" }}
                    />
                    {!htmlContent && <Button
                        onClick={handleViewTraining}
                        style={{
                            borderRadius: "30px",
                            padding: "5px 16px", 
                            fontSize: "12px",
                            lineHeight: "18px"
                        }}
                    >View training</Button>}
                </Header>
            </Box>
            {htmlContent && !open && <Box style={{ flex: 1, position: "relative" }}>
                <iframe srcDoc={htmlContent} title="HTML Preview" style={{ width: '100%', height: '100%' }} />
                <CloseButton onClick={handleClickOpen}>
                    <img src={expandIcon} width={24} height={24} />
                </CloseButton>
            </Box>}
           
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} style={{ position: "relative" }}>
                {htmlContent && (
                    <iframe srcDoc={htmlContent} title="HTML Preview" style={{ width: '100%', height: '100%' }} />
                )}
                <CloseButton onClick={handleClose}>
                    <Close />
                </CloseButton>
            </Dialog>
        </ColStack>
    )
}

export default ScormFilePreview;

const RowStack = styled(Box)({
    display: "flex"
})
const ColStack = styled(RowStack)({
    flexDirection: "column"
})
const Header = styled(RowStack)({
    borderRadius: "18px", 
    border: "0.52px solid #FFF", 
    backgroundColor: "#F9F9F9",
    alignItems: "center", 
})
const CloseButton = styled(IconButton)(({ theme }) => ({
    width: "48px",
    height: "48px",
    position: "absolute",
    top: "7%",
    right: "2%",
    backgroundColor: "#D7D7D780", 
    padding: "6px", 
    borderRadius: "50%",
}))