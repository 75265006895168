export const videoIcon = require("../assets/video-icon.svg");
export const pdfIcon = require("../assets/pdf-icon.svg");
export const audioIcon = require("../assets/audio-icon.svg");
export const zipIcon = require("../assets/zip-icon.svg");

export const presentationBrowseIcon = require("../assets/Vector(1).png");
export const videoBrowseIcon = require("../assets/Icon(1).png");
export const audioBrowseIcon = require("../assets/Icon.png");
export const imageBrowseIcon = require("../assets/Vector(2).png");
export const pdfDragIcon = require("../assets/Vector.png"); 
