import { Box, styled, Typography } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

import React, { CSSProperties, useEffect, useState } from "react";
//@ts-ignore
import { pdfjs, Document, Page } from "react-pdf";

import Card from "./Card";
import { handleFileSize } from "./handle-file-size";
import IconButton from "./IconButton";
import Button from "./Button";
import useOfflineBlob from "./hooks/useOfflineBlob";

import { UploadedFile } from "../../projectnotes/src/ProjectnotesController";

const pdfIcon = require("../../projectnotes/assets/pdf-ic.svg");

console.log("Pdfjs version", pdfjs.version);
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type IPdfViewerProps = { 
    file: UploadedFile;
    style?: CSSProperties;
    onComplete?: () => void;
}

const PdfViewer = ({
    file,
    style,
    onComplete,
}: IPdfViewerProps) => {
    const currentUrl = useOfflineBlob(file);

    const [totalPages, setTotalPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        if (pageNumber === totalPages && onComplete) {
            onComplete();
        }
    }, [pageNumber, totalPages])

    const onDocumentLoadSuccess = async (pdf: { numPages: number }) => {
        setTotalPages(pdf.numPages);
    };
    const goToPrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(prevState => prevState - 1);
        }
    }
    const goToNextPage = () => {
        if (pageNumber < totalPages) {
            setPageNumber(prevState => prevState + 1);
        }
    }

    return (
        <Container style={style}>
            <Document
                file={currentUrl}
                className="pdf-document"
                onLoadSuccess={(pdf: { numPages: number; }) => onDocumentLoadSuccess(pdf)}
                onLoadError={(e: any) => console.log('[Error]', e)}
            >
                <Page pageNumber={pageNumber} scale={0.8} />
            </Document>
            <RowStack style={{ justifyContent: "space-between", alignItems: "flex-end", gap: "14px" }}>
                <Card 
                    thumbnailSrc={pdfIcon}
                    title={file.filename} 
                    subtitle={handleFileSize(file.size || 0)} 
                    style={{ borderRadius: "18px", border: "0.52px solid #FFF", backgroundColor: "#F9F9F9" }}
                />
                <RowStack style={{ alignItems: "center", gap: "8px" }}>
                    <IconButton disabled={pageNumber === 1} style={{ width: "20px", height: "20px" }}>
                        <ArrowBackIos onClick={goToPrevPage} style={{ height: '13px', width: '13px' }} />
                    </IconButton>
                    <Box style={{
                        backgroundColor: '#1A469C',
                        padding: "4px 3px",
                        borderRadius: '5.61px'
                    }}>
                        <Typography 
                            style={{
                                fontFamily: "Rubik",
                                fontSize: "7px",
                                lineHeight: "13px",
                                fontWeight: 700,
                                color: '#F8FAFC'
                            }}
                        >{pageNumber}</Typography>
                    </Box>
                    <Button 
                        variant="ghost" 
                        disabled={pageNumber === totalPages}
                        iconRight={<ArrowForwardIos style={{ margin: 0, width: "12px", height: "12px" }} />}
                        style={{ 
                            width: "79px", 
                            height: "24px",
                            padding: 0,
                            fontSize: "12px",
                            lineHeight: "22px",
                        }}
                        onClick={goToNextPage}
                    >Next Slide</Button>
                </RowStack>
            </RowStack>
        </Container>
    )
}

export default PdfViewer;

const RowStack = styled(Box)({
    display: "flex"
})
const ColStack = styled(RowStack)({
    flexDirection: "column"
})
const Container = styled(ColStack)(({ theme }) => ({
    width: "100%",
    height: "641px",
    borderRadius: "18px",
    border: "1px solid #D7D7D780",
    backgroundColor: "#FFF",
    padding: "12px 16px",
    gap: "12px",
    "& .pdf-document": {
        width: "100%",
        paddingRight: "14px",
        overflowX: "hidden",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '13px'
        },
        '&::-webkit-scrollbar-track': {
            border: '0.94px solid #CBD5E1'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '28.34px 28.34px 85.01px',
            backgroundColor: '#EDF1F6',
            border: '0.94px solid #94A3B8'
        },
        "& canvas": { margin: "auto" }
    }
}))