import { Box, styled } from "@material-ui/core";
import Switch, { SwitchProps as MuiSwitchProps } from "@material-ui/core/Switch";
import React, { CSSProperties, forwardRef, ReactNode, useEffect, useState } from "react";

type Props = {
    onChange?: (value: boolean) => void;
    name?: string;
    values?: string;
    onBgColor?: string;
    offBgColor?: string;
    onIcon?: ReactNode;
    offIcon?: ReactNode;
    style?: CSSProperties;
};
type SwitchProps = Omit<MuiSwitchProps, "onChange"> & Props;

const CustomSwitch = forwardRef<HTMLButtonElement, SwitchProps>(({
  checked = false,
  disabled,
  name,
  onChange,
  ...rest
}, ref) => {
    const [enabled, setEnabled] = useState(checked);

    useEffect(() => {
        if (typeof checked !== undefined && checked !== enabled) {
            setEnabled(!!checked);
        }
    }, [checked])

    const setEnableHandler = (value: boolean) => {
        if (onChange) {
            onChange(value);
        }
        setEnabled(value)
    }

    return (
        <Container>
          <Switch 
            ref={ref} 
            disabled={disabled}
            checked={enabled}
            name={name}
            onChange={event => setEnableHandler(event.target.checked)}
            aria-readonly={disabled}
            {...rest}
          />
        </Container>
    )
})

export default CustomSwitch;

const Container = styled(Box)({
  display: "flex",
  gap: "8px",
  "& p": {
    marginLeft: '5px',
    marginTop: '2px',
    fontWeight: 500,
    lineHeight: '24px',
    fontSize: '16px',
  },
  '& .MuiSwitch-root': {
    height: '28px',
    width: '48px',
    padding: 0,
  },
  '& .MuiIconButton-root': {
    padding: '2px !important'
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    background: '#E2E8F0',
    borderRadius: '40px',
  },
  "& .MuiSwitch-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#FFF",
    boxShadow: "0 2px 8px 0 #00000014"
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    opacity: 1
  },
  '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
    backgroundColor: "#1A469C"
  },
  '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
    backgroundColor: "#CBD5E1"
  },
  '& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
    backgroundColor: "#F1F5F9"
  },
});