import React from "react";
// Customizable Area Start
import {
  Typography,
  IconButton,
  Chip as MuiChip,
  Box,
  FormControlLabel,
  styled,
  CardContent,
  InputBase,
  Radio,
  Paper,
  LinearProgress,
  CircularProgress,
  Modal,
  MenuProps,
  Menu,
  MenuItem,
  Chip,
  Checkbox
} from "@material-ui/core";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import { lightTheme, darkTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import Pagination from "@material-ui/lab/Pagination";
import { ArrowForwardIos, RadioButtonChecked, RadioButtonUnchecked, SortByAlpha } from "@material-ui/icons";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { hamburger, flower, bg } from "./assets";

import BreadcrumbNavigationPage from "../../breadcrumbnavigation/src/BreadcrumbNavigationPage.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Button from "../../utilities/src/Button";
import OutsideAlerter from "../../utilities/src/OutsideAlerter";
import CustomisedSelect from "../../utilities/src/CustomisedSelect";
import MultiSelect from "../../utilities/src/MultiSelect";

const CustomFormControlLabel = styled(FormControlLabel)({
  '& .MuiRadio-root': { padding: "0 8px" },
  '& .MuiRadio-colorSecondary.Mui-checked svg': {
    fill: 'blue',
    transform: 'scale(0.8)',
  },
  '& .MuiRadio-colorSecondary.Mui-checked span div': {
    left: 2
  },
  '& .MuiRadio-colorSecondary span div': {
    left: 2
  },
  '& .MuiRadio-colorSecondary.Mui-checked span div svg:first-child': {
    background: '#1A469C',
    borderRadius: '50%'
  },
  '& .MuiRadio-colorSecondary.Mui-checked span div svg:nth-child(2)': {
    fill: 'white',
    background: '#1A469C',
    borderRadius: '50%',
  },
  '& .MuiFormControlLabel-label': { color: '#334155' }
})
// Customizable Area End

import FilteritemsController, {
  Props,
} from "./FilteritemsController";

export default class Filteritems extends FilteritemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }

  // Customizable Area Start
  renderChipSection = (title: string, filterType: 'status' | 'sort', filters: string[], selectedChips: string[]) => {
    const isSingleSelect = () => {
      if (this.state.roleId === "worker") {
        return true;
      }
      if (this.state.roleId === "instructor") {
        if (filterType === "status") {
          return false;
        }
        if (filterType === "sort") {
          return true;
        }
      }
      if (this.state.roleId === "supervisor") {
        return false;
      }
    }

    return (
      <ColStack>
        <Text3 variant="h6" style={{ fontWeight: 500, color: "#0F172A", fontSize: 16, fontFamily: "Rubik" }}>{title}</Text3>
        <ColStack flexWrap="wrap">
          {filters?.map((filter: string, index: number) => (
            <StyledChip
              key={index}
              label={this.formatStatusFilter(filter)}
              className={selectedChips.includes(filter) ? "selected" : ""}
              onClick={() => {
                this.handleChipClick(filter, filterType);
                this.handleBreadcrumbClick(this.formatStatusFilter(filter));
              }}
              onDelete={
                selectedChips?.includes(filter)
                  ? () => this.handleRemoveFilter(filterType, filter)
                  : undefined
              }
              data-test-id={`chip-${filter}`}
              disabled={isSingleSelect() && !selectedChips?.includes(filter) && selectedChips?.length > 0}
            />
          ))}
        </ColStack>
      </ColStack>
    )
  }
  renderCheckboxSection = (title: string, filters: { id: string; name: string }[], selectedChips: string[], filterType: "category" | "subCategory") => (
    <ColStack style={{ gap: "6px" }}>
      <Label variant="h6" style={{ color: "#0F172A" }}>{title}</Label>
      <ScrollableSection 
        maxHeight="90px" 
        overflow="auto" 
        style={{ display: "flex", flexDirection: "column", gap: "8px" }}
      >
        {filters?.map((option: { id: string; name: string }, index: number) => (
          <div key={index} data-test-id={`radio-${index}`}>
            <CustomFormControlLabel
              key={index}
              control={
                <Checkbox
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={<RadioButtonChecked />}
                  checked={selectedChips.includes(option.id)}
                  disableRipple
                  onChange={() => {
                    this.handleRadioboxChange(option.id, filterType);
                  }}
                  value={option.id}
                  name="radio-buttons-group"
                  data-test-id={`radio-control-${option.id}`}
                  style={{ color: '#18408E' }}
                />
              }
              label={option.name}
              data-test-id={`radio-label-${option.id}`}
            />
          </div>
        ))}
      </ScrollableSection>
    </ColStack>
  );
  renderSelectSection = (title: string, filterType: "company" | "group" | "instructor", filters: { value: string; label: string }[], selectedValues: string[]) => {
    if (filterType === "company") {
      return (
        <CustomisedSelect 
          data-test-id={`${filterType}-select`}
          addable={false}
          searchable={false}
          placeholder={title}
          onChange={obj => this.handleSelectChange(obj.value, filterType)} 
          defaultValueObject={{
            label: filters.find(filter => filter.value === selectedValues[0])?.label ?? title,
            value: selectedValues && selectedValues[0] ? selectedValues[0] : "-1"
          }}
          fullWidth={false}
          selectStyle={{ minWidth: "150px", borderRadius: "30px" }} 
          crmFields={filters}
          borderless
        />
      )
    }
    return (
      <MultiSelect 
        data-test-id={`${filterType}-multi-select`}
        addable={false}
        searchable={false}
        placeholder={title}
        onChange={obj => this.handleSelectChange(obj.value, filterType)} 
        defaultValueObject={selectedValues.length > 0 ? selectedValues.map(each => (
          {
            label: filters.find(filter => filter.value === each)?.label || "",
            value: each
          }
        )) : [{ value: "-1", label: title }]}
        fullWidth={false}
        selectStyle={{ minWidth: "150px", borderRadius: "30px" }} 
        crmFields={filters}
        borderless
      />
    )
  }
  Sidebar = () => (
    <SidebarContainer>
      <Text3 variant="body1" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: "16px" }}>
        <Label>Filter Menu</Label>
        {this.state.FiltersSelectes.length > 0 && (
          <RowStack style={{ alignItems: "center" }}>
            <Button 
              data-test-id="reset-filters-button"
              variant="ghost" 
              size="sm" 
              style={{ color: "#DC2626", fontSize: "12px", fontWeight: 500 }}
              onClick={this.handleReset}
            >Reset</Button>
          </RowStack>
        )} 
      </Text3>
      <ColStack style={{ gap: "8px" }}>
          {(this.state.roleId === "instructor" ||  this.state.roleId === "supervisor") 
            && this.renderSelectSection("Group", "group", this.state.allFilters.groupValue, this.state.selectedChips.group)}
          {this.state.roleId === "instructor" 
            && this.renderSelectSection("Company", "company", this.state.allFilters.companyValue, [this.state.selectedChips.company])}
          {this.state.roleId === "supervisor"
            && this.renderSelectSection("Instructor", "instructor", this.state.allFilters.instructorValue, this.state.selectedChips.instructor)}
          {this.renderChipSection("Status", "status", this.state.allFilters.status, this.state.selectedChips.status)}
          {this.renderChipSection("Sort by", "sort", this.state.allFilters.sort, this.state.selectedChips.sort)}
          {(this.state.roleId === "worker" || this.state.roleId === "instructor")
            && this.renderCheckboxSection("Category", this.state.allFilters?.categoryValue, this.state.selectedChips.category, "category")}
      </ColStack>
    </SidebarContainer>
  );

  renderStatusChip = (item: string) => {
    const getBgColor = () => {
      switch (item) {
        case "completed":
          return "#D1FAE5"
        case "in_progress":
          return "#FEF3C7"
        case "assigned":
          return "#FEF3C7"
        case "expired":
          return "#FEE2E2"
        case "published":
          return "#D1FAE5"  
        case "draft":
          return "#FEF3C7"
        default:
          break;
      } 
    }
    const getColor = () => {
      switch (item) {
        case "completed":
          return "#059669"
        case "in_progress":
          return "#D97706"
        case "assigned":
          return "#D97706"
        case "expired":
          return "#DC2626"
        case "published":
          return "#059669"  
        case "draft":
          return "#D97706"
        default:
          break;
      } 
    }
   
    return (
      <Chip label={this.formatStatus(item)} style={{ minWidth: "160px", backgroundColor: getBgColor(), color: getColor() }}/>
    )
  }
  renderWorkerCourseStatus = (item: any, index: number) => (
    <CourseStatus>
       {item.mostRelavent && 
        <Typography 
          variant="body2" 
          style={{ fontSize: "14px", fontWeight: 500, marginBottom: "5px", color: "#334155" }} 
          data-test-id={`course-relevance-${index}`}
        >
          Most Relevant
        </Typography>}
        {this.renderStatusChip(item.status)}
        <RowStack justifyContent={item.status !== "completed" ? "space-around" : "flex-end"} alignItems="center">
          {item.status !== "completed" && 
            <Text2 variant="body2" color="textSecondary">
              <>{item.percentage || 0}%</>
            </Text2>}
          <Button
            data-test-id={`course-next-button-${index}`} 
            variant="outline" 
            style={{ borderRadius: "100%", minWidth: 24, height: 24, width: 24 }}
            onClick={() => this.handleNavigate(item.id)}
          >
            <ArrowForwardIos style={{ width: 12, height: 12 }} />
          </Button>
        </RowStack>
    </CourseStatus>
  )
  renderInstructorCourseStatus = (item: any, index: number) => (
    <CourseStatus>
       {item.mostRelavent && 
        <Typography 
          variant="body2" 
          style={{ fontSize: "14px", fontWeight: 500, marginBottom: "5px", color: "#334155" }} 
          data-test-id={`course-relevance-${index}`}
        >
          Most Relevant
        </Typography>}
        {this.renderStatusChip(item.status)}
        <RowStack justifyContent={item.status !== "draft" ? "space-around" : "flex-end"} alignItems="center">
          {item.status !== "draft" && (
            <ColStack>
              <CreationText1 data-test-id={`course-name-${index}`}>{`${item.status === "published" ? "creation" : "expiration"} date`}</CreationText1>
              {item.status === "published" && item.creationDate && <CreationText2>{item.creationDate}</CreationText2>}
              {item.status === "expired" && item.expirationDate && <CreationText2>{item.expirationDate}</CreationText2>}
            </ColStack>
          )}
          <Button
            data-test-id={`course-next-button-${index}`} 
            variant="outline" 
            style={{ borderRadius: "100%", minWidth: 24, height: 24, width: 24 }}
            onClick={() => this.handleNavigate(item.id)}
          >
            <ArrowForwardIos style={{ width: 12, height: 12 }} />
          </Button>
        </RowStack>
    </CourseStatus>
  )
  renderSupervisorCourseStatus = (item: any, index: number) => (
    <CourseStatus>
       {item.mostRelavent && 
        <Typography 
          variant="body2" 
          style={{ fontSize: "14px", fontWeight: 500, marginBottom: "5px", color: "#334155" }} 
          data-test-id={`course-relevance-${index}`}
        >
          Most Popular
        </Typography>}
        {this.renderStatusChip(item.status)}
        <RowStack justifyContent="flex-end" alignItems="center">
          <Button
            data-test-id={`course-next-button-${index}`} 
            variant="outline" 
            style={{ borderRadius: "100%", minWidth: 24, height: 24, width: 24 }}
            onClick={() => this.handleNavigate(item.id)}
          >
            <ArrowForwardIos style={{ width: 12, height: 12 }} />
          </Button>
        </RowStack>
    </CourseStatus>
  )
  CourseList = () => (
    <ColStack style={{ gap: "20px" }}>
      {this.state.courses.map((item, index) => (
        <RowStack key={index} data-test-id={`course-card-${index}`} style={{ gap: "14px", alignItems: "center" }}>
          <div style={{ fontWeight: "bold" }}>{index + 1}</div>
          <CourseInfo>
            <CourseImage
              src={item.image || flower}
              data-test-id={`course-image-${index}`}
            />
            <CourseInfoText>
              <ColStack>
                <Text1 data-test-id={`course-name-${index}`}>Course Name</Text1>
                <Text2>{item.course}</Text2>
              </ColStack>
              <ColStack>
                <Text1 data-test-id={`group-name-${index}`}>Group Name</Text1>
                <Text2>{item.group}</Text2>
              </ColStack>
              {(this.state.roleId === "instructor" || this.state.roleId === "supervisor") && (
                <ColStack>
                  <Text1 data-test-id={`category-name-${index}`}>Instructor Name</Text1>
                  <Text2>{item.instructorName}</Text2>
                </ColStack>
              )}
              {this.state.roleId === "worker" && (
                <ColStack>
                  <Text1 data-test-id={`category-name-${index}`}>Course Category / Sub-Category</Text1>
                  <Text2>{item.category} / {item.subCategory} </Text2>
                </ColStack>
              )}
              {Boolean(item.percentage) &&
                <RowStack position="relative" alignItems="center">
                  <Box width="100%">
                    {item.status !== "completed" && 
                      <LinearProgressBar variant="determinate" value={item.percentage || 0} />
                    }
                    {this.state.roleId === "instructor" && (
                      <Box
                        position="absolute"
                        top={-20}
                        right={10}
                      >
                        <Text2 variant="body2" color="textSecondary">
                          {item.percentage || 0}%
                        </Text2>
                      </Box>
                    )}
                  </Box>
                </RowStack>
              }
            </CourseInfoText>

          </CourseInfo>
          {this.state.roleId === "supervisor" && item.status !== "completed" &&
            <RowStack position="relative">
              <CircularProgress
                variant="determinate"
                value={90}
                size={30}
                thickness={4}
                style={{ color: 'rgba(26, 70, 156, 1)', width: '70.17px', height: '76.23px', transform: 'rotate(90deg)', }}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption" color="textSecondary" style={{
                  fontFamily: "Rubik",
                  fontSize: "15px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  color: 'rgba(15, 23, 42, 1)',
                }}>
                  {`90%`}
                </Typography>
              </Box>
            </RowStack>
          }
          {this.state.roleId === "worker" && this.renderWorkerCourseStatus(item, index)}
          {this.state.roleId === "instructor" && this.renderInstructorCourseStatus(item, index)}
          {this.state.roleId === "supervisor" && this.renderSupervisorCourseStatus(item, index)}
        </RowStack>
      ))}
    </ColStack>
  );

  renderPageSize = () => {
    const options = [10, 25, 50, 100];

    return (
      <OutsideAlerter onOutsideClick={this.togglePageDropdown}>
        <Dropdown style={{ height: "160px", width: '55px', zIndex: 100, top: -225, right: 0 }} data-test-id="page-size-dropdown">
          {options.map((option) => (
            <SortText
              key={option}
              onClick={() => this.handlePageSizeChange(option)}
              style={{
                borderRadius: '8px',
                backgroundColor: this.state.selectedPageSize === option ? "#E8EDF5" : "",
                color: this.state.selectedPageSize === option ? "#1A469C" : "#475569",
              }}
            >
              {option}
            </SortText>
          ))}
        </Dropdown>
      </OutsideAlerter>
    );
  };
  renderCourses = () => (
    <Content>
      <Box style={{ flexGrow: 1 }}>
        {this.CourseList()}
      </Box>
      <PaginationBox>
        <PaginationBoxContent>
          {this.state.totalPage >= 2 && (
            <Pagination
              count={this.state.totalPage}
              onChange={this.changePage}
              data-test-id="pagination"
            />
          )}
        </PaginationBoxContent>
        <Box>
          <PageSizeBox onClick={this.togglePageDropdown}>
            <PageSizeTypography>Page Size</PageSizeTypography>
            <KeyboardArrowDownIcon height="5.5px" width='9.5px' />
            {this.state.isPageDropdownOpen && this.renderPageSize()}
          </PageSizeBox>
        </Box>
      </PaginationBox>
    </Content>
  )
  renderEmpty = () => (
    <RowStack style={{ justifyContent: 'center' }}>
      <NoSearchContent data-test-id="empty-content">
        <NoSearchTypography data-test-id="empty-no-search">
          "No Search Found"
        </NoSearchTypography>
        <NoSearchDesc data-test-id="empty-explore-text">
          Start exploring and your searches will appear here!
        </NoSearchDesc>
      </NoSearchContent>
    </RowStack>
  )
  renderContent = () => (
    <MainContentSection>
      {this.state.courses.length === 0 ? (
        this.renderEmpty()
      ) : (
        this.renderCourses()
      )}
    </MainContentSection>
  )

  renderSort = ({ open, onClose, anchorEl }: Pick<MenuProps, "open" | "onClose" | "anchorEl">) => {
    const options = [
      { label: "Ascending", value: "ascending" },
      { label: "Descending", value: "descending" },
    ];

    return (
      <StyledMenu data-test-id="sort-dropdown" open={open} anchorEl={anchorEl} keepMounted onClose={onClose}>
        <GroupMenuItem disabled>sort by</GroupMenuItem>
        {options.map((option) => (
          <StyledMenuItem
            key={option.value}
            data-test-id={`dropdown-item-${option.value}`}
            onClick={() => this.handleSort(option.value)}
            style={{
              backgroundColor: this.state.sortOrder === option.value ? "#E8EDF5" : "",
              color: this.state.sortOrder === option.value ? "#1A469C" : "#475569",
            }}
          >
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    );
  };

  renderLoadingModal = () => {
    return (
      <Modal
        open={this.state.loading}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <ModalStyle>
          <CircularProgress
            value={90}
            size={30}
            thickness={4}
            style={{ color: 'rgba(26, 70, 156, 1)', width: '162px', height: '162px', transform: 'rotate(90deg)', }}
          />
        </ModalStyle>
      </Modal>
    )
  }
  // Customizable Area End

  render() {
    return (
      <div>
        {/* Customizable Area Start */}
        <ThemeProvider theme={this.state.selectedTheme ? darkTheme : lightTheme}>
          <NavigationMenu 
            navigation={this.props.navigation}
            id={""} 
            open={this.state.open} 
            filter="filter"
            theme={this.state.selectedTheme} 
            navBarStyle={{ width: "100%" }}
          />
          <Container style={{ height: "100vh" }}>
            <Box style={{ marginTop: "20px", height: "100%", display: "flex", gap: "20px" }}>
              <Box style={{ width: "20%", }}>
                {this.Sidebar()}
              </Box>
              <MainContainer>
                <Header>
                  <RowStack style={{ width: "25%", height: '100%', padding: "15px 0 12px 0", backgroundColor: '#FFF', alignItems: 'center' }}>
                    <BreadcrumbNavigationPage
                      breadcrumbs={['Dashboard', 'Current page']}
                      gotoPage={this.navigatetoPage.bind(this)}
                    />
                  </RowStack>
                  <HeaderTab>
                    <hr style={{ flexGrow: 1, height: "1px", backgroundColor: "#D7D7D780" }} />
                    <Button 
                      data-test-id="sort-by-name-btn"
                      variant="ghost" 
                      style={{ padding: "9px 5px" }} 
                      iconRight={<SortByAlpha style={{ width: "32px", height: "32px" }} />}
                      onClick={(e: React.MouseEvent<HTMLElement>) => this.setState({ sortByNameAlchorEl: e.currentTarget })}
                    >Sort by Name</Button>
                    {this.renderSort({ 
                      open: Boolean(this.state.sortByNameAlchorEl), 
                      onClose: () => this.setState({ sortByNameAlchorEl: null }),
                      anchorEl: this.state.sortByNameAlchorEl
                    })}
                  </HeaderTab>
                </Header>
                {this.renderContent()}
              </MainContainer>
            </Box>
          </Container>
          {this.renderLoadingModal()}
        </ThemeProvider>
        {/* Customizable Area End */}
      </div>
    );
  }
}

// Customizable Area Start
const RowStack = styled(Box)({
  display: "flex"
})

const ColStack = styled(RowStack)({
  flexDirection: "column"
})

const ScrollableSection = styled(Box)({
  overflowX: "hidden",
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '13px'
  },
  '&::-webkit-scrollbar-track': {
    border: '0.94px solid #CBD5E1'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '28.34px 28.34px 85.01px',
    backgroundColor: '#EDF1F6',
    border: '0.94px solid #94A3B8'
  }
})

const NormalText = styled(Typography)({
  fontFamily: "Rubik",
  fontSize: "14px",
  color: "#334155"
})

const Label = styled(NormalText)({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "40px"
})

const StyledMenu = withStyles((theme) => ({
  paper: {
    width: "220px",
    border: '1px solid #FFF',
    borderRadius: "18px",
    backgroundColor: theme.palette.primary.main,
  },
  list: {
    padding: 0
  }
}))((props: MenuProps) => (
  <Menu
    elevation={2}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    marginThreshold={0}
    {...props}
  />
));

const GroupMenuItem = withStyles({
  root: {
    borderBottom: "0.5px solid #D7D7D780",
    borderRadius: "8px",
    fontSize: 12,
    lineHeight: "18px",
    opacity: "1 !important",
    color: "#475569",
    textTransform: "uppercase"
  },
})(MenuItem);

const StyledMenuItem = withStyles({
  root: {
    borderRadius: "8px",
    fontSize: 14,
    lineHeight: "22px",
    color: "#475569",
    textTransform: "capitalize",
    "&:hover": { backgroundColor: "#E8EDF5", color: "#1A469C" }
  },
})(MenuItem);

const Dropdown = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: 220,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  textAlign: 'left',
  marginTop: "50px"
}));

const PageSizeBox = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: '95px',
  height: '29px',
  borderRadius: "30px",
  padding: '12px 8px',
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  textAlign: 'left',
  color: 'rgba(71, 85, 105, 1)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const PageSizeTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  color: 'rgba(71, 85, 105, 1)'
}));

const StyledChip = styled(MuiChip)(({ theme }) => ({
  width: "fit-content",
  textTransform: "capitalize",
  margin: "6px 0",
  padding: "5px 12px",
  "&.selected": {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.primary.main
  },
  "&:not(.selected)": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.action.selected,
  },
  "& .MuiChip-deleteIcon": {
    display: "none",
  },
  "&.selected .MuiChip-deleteIcon": {
    display: "block",
    color: theme.palette.primary.main
  },
}));

const Content = styled(ColStack)({
  height: "100%",
  padding: "45px 2rem 1rem",
  gap: "27px"
})

const CourseInfo = styled(RowStack)(({ theme }) => ({
  width: "100%",
  padding: "0 0 0 32px",
  backgroundColor: theme.palette.background.default,
  gap: "16px",
  alignItems: "stretch"
}))

const CourseImage = styled('img')({
  width: "50%", 
  height: "134px", 
  borderRadius: "18px", 
  objectFit: "cover"
})

const CourseInfoText = styled(CardContent)({
  flex: 1, 
  display: "flex", 
  flexDirection: "column", 
  gap: "4px",
  padding: "14px 14px 14px 0",
})

const CourseStatus = styled(CardContent)({
  padding: "11px 0",
  display: "flex",
  flexDirection: "column",
  gap: '15px'
})

const ModalStyle = styled(Box)({
  width: '212.44px',
  height: '212.44px',
  backgroundColor: 'rgba(249, 249, 249, 1)',
  borderRadius: '18px',
  display: 'flex',
  justifyContent:'center',
  alignItems: 'center',
})

const Text1 = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "12px",
  color: theme.palette.info.light
}))

const TextStyle = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "21px",
  color: '#334155'
}))

const Text2 = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  color: theme.palette.info.main
}))

const CreationText1 = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "13.8px",
  fontWeight: 500,
  lineHeight: "20.71px",
  color: theme.palette.info.light,
  textTransform: "capitalize"
}))

const CreationText2 = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16.1px",
  fontWeight: 700,
  lineHeight: "20.71px",
  color: theme.palette.info.main
}))

const Text3 = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}))

const SortText = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  height: "40px",
  paddingLeft: "16px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left",
  display: 'flex',
  alignItems: 'center',
  "&:hover": {
    borderRadius: '8px',
    backgroundColor: "#E8EDF5",
  },
  color: theme.palette.info.light
}))

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: '0 36.45px 26px 33.5px',
}))

const SaveButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.action.active,
  border: "none",
  color: theme.palette.text.secondary,
  borderRadius: "30px",
  padding: "6px 10px 6px 10px",
  cursor: "pointer",
  width: "100%",
  height: "32px",
  fontSize: "16px",
  fontWeight: "bold",
  marginTop: '1rem',
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.primary.main,
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: "relative",
  borderRadius: "50px",
  backgroundColor: theme.palette.background.default,
  cursor: "pointer",
  "&:hover": {
    border: `2px solid ${theme.palette.action.selected}`,
  },
  marginRight: "16px",
  marginLeft: 0,
  width: "100%",
  "@media (min-width:600px)": {
    marginLeft: "24px",
    width: "auto",
  },
  color: "rgb(140, 140, 140)",
  border: "1px solid #CBD5E1",
}));

const SearchIconWrapper = styled("div")({
  padding: "0 16px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "gray"
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  paddingLeft: "50px",
  paddingTop: "4px",
  paddingBottom: "4px",
  '&::placeholder': {
    color: theme.palette.primary.main
  },
}));

const SidebarContainer = styled(ColStack)(({ theme }) => ({
  minHeight: 'calc(100% - 52px)',
  borderRadius: "18px",
  backgroundColor: theme.palette.action.focus,
  padding: "56px 2rem", 
  gap: "10px"
}));

const MainContainer = styled(ColStack)({
  backgroundColor: "#FFF",
  height: "100%", 
  width: "80%", 
});

const Header = styled(RowStack)({
  backgroundColor: "#FFF",
  alignItems: 'stretch', 
  justifyContent: 'space-between',
})

const HeaderTab = styled(RowStack)(({ theme }) => ({
  height: '100%', 
  width: '75%', 
  borderTopLeftRadius: "18px",
  borderTopRightRadius: "18px",
  background: theme.palette.action.focus,
  padding: "15px 27px 0",
  alignItems: "center",
  gap: "14px",
  position: "relative",
  "&::before": { 
    content: '""',
    background: "#FFF",
    height: "36px",
    width: "36px",
    position: "absolute",
    bottom: 0,
    left: "-36px",
    borderRadius: "50px",
    zIndex: 3
  },
  "&:after": { 
    content: '""',
    background: theme.palette.action.focus,
    height: "18px",
    width: "18px",
    position: "absolute",
    bottom: 0,
    left: "-18px",
  },
}))

const MainContentSection = styled(Box)(({ theme }) => ({
  flex: 1,
  borderRadius: "18px 0 18px 18px",
  background: theme.palette.action.focus,
}))

const CustomRadiobox = styled(Radio)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    width: "1em",
    height: "1em",
    borderRadius: "50%",
  },
  color: theme.palette.action.selected,
  "&.Mui-checked": {
    color: theme.palette.action.selected,
  },
}));

const PaginationBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "29px",
  marginTop: "2rem",
  marginBottom: "1rem",
  display: "flex",
  justifyContent: "center",
  opacity: "0px",
}));
const PaginationBoxContent = styled(Box)(({ theme }) => ({
  width: '80%',
  display: 'flex',
  justifyContent: 'center',
  '& .MuiPagination-root': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '18px'
  },
  '& .MuiPagination-ul': {
    gap: '1rem'
  },
  '& .MuiPaginationItem-root': {
    borderRadius: '8px',
    backgroundColor: 'rgba(241, 245, 249, 1)',
    color: 'rgba(67, 83, 106, 1)',
    minWidth: '18px',
    height: '18px',
    fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  '& .MuiPaginationItem-page.Mui-selected': {
    borderRadius: '8px',
    backgroundColor: theme.palette.action.selected,
    color: 'rgba(248, 250, 252, 1)',
    minWidth: '18px',
    height: '18px',
    fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "18px",
  },

}));
const NoSearchContent = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${bg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 50px top 30px',
  width: "734.31px",
  height: "372px",
  gap: "10px",
  borderRadius: "4.97px",
  opacity: "0px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "left",
  margin: "30px",
}));
const NoSearchTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Kallisto !important",
  fontSize: "32.25px",
  fontWeight: 700,
  lineHeight: "43px",
  letterSpacing: "-0.005em",
  textAlign: "left",
  color: theme.palette.text.primary,
  gap: "0px",
  opacity: "0px",
}))
const NoSearchDesc = styled(Typography)(({ theme }) => ({
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "21.1px",
  fontWeight: 400,
  lineHeight: "30.1px",
  textAlign: "left",
  color: theme.palette.text.primary
}))

const Inprogress = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FEF3C7',
  color: '#D97706',
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "13.55px",
  textAlign: "left",
  padding: '8px 12px',
  whiteSpace: 'nowrap',
  height: "34px",
  gap: "7.53px",
  borderRadius: "40px",
  opacity: "0px"
}))
const Completed = styled(Typography)(({ theme }) => ({
  backgroundColor: '#D1FAE5',
  color: '#059669',
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
  width: "208x",
  height: "34px",
  padding: "8px 16px 8px 16px",
  gap: "7.53px",
  borderRadius: "40px",
  opacity: "0px"
}))

const Expired = styled(Typography)(({ theme }) => ({
  backgroundColor: '#FEE2E2',
  color: '#DC2626',
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  marginBottom: 10,
  lineHeight: "18px",
  textAlign: "left",
  width: "208x",
  height: "34px",
  padding: "8px 16px 8px 16px",
  gap: "7.53px",
  borderRadius: "40px",
  opacity: "0px"
}))

const AdvanceSearch = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "40px",
  textAlign: "center",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#334155"
}))
const AdvanceSearchWapper = styled('div')(({ theme }) => ({
  display: "flex", height: "50px", textAlign: "right", borderBottomRightRadius: "15px",
  justifyContent: "space-between",
  width: "auto",
  marginLeft: 'auto',
  gap: "40px",
  cursor: "pointer",
  alignItems: 'center',
  position: "relative",
}));
const LinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 4.4,
  borderRadius: 9.09,
  background: "#E2E8F0",
  [`& .MuiLinearProgress-colorPrimary`]: {
    backgroundColor: theme.palette.secondary.main,
  },
  [`& .MuiLinearProgress-bar`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.action.selected,
  },
}));

// Customizable Area End