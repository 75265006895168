import { Box, Grid, styled } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { CSSProperties, useRef, useState, ChangeEvent, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import Button from "./Button";
import IconButton from "./IconButton";

type IPreviewDisplayProps = | {
    multiple: true,
    urls: string[],
    fileType?: "video" | "image" | string;
    onRemovePreviewMedia: (index: number) => void
} | {
    multiple?: false,
    url: string,
    fileType?: "video" | "image" | string;
    onRemovePreviewMedia: () => void
}

const PreviewDisplay = (props: IPreviewDisplayProps) => {
    if (props.multiple) {
        return (
            <Box style={{ padding: "30px" }}>
                <Grid container spacing={1}>
                    {props.urls.map((url, index) => (
                        <Grid item xs={4} justifyContent="center" alignItems="stretch">
                            <ImagePreview src={url} alt={`Image ${index}`} style={{ height: "204px" }} />
                            <RemoveButton style={{ height: "fit-content", left: "80%" }} onClick={() => props.onRemovePreviewMedia(index)}>
                                <Close />
                            </RemoveButton>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        )
    }

    return (
        <FilePreview>
            {!props.fileType || props.fileType.includes("image") 
                ? <ImagePreview src={props.url} />
                : <VideoPreview src={props.url} controls />
            }
            <RemoveButton style={{ height: "fit-content" }} onClick={() => props.onRemovePreviewMedia()}>
                <Close />
            </RemoveButton>
        </FilePreview>
    )
}

type ISingleFileUploadWithPreviewProps = {
    id?: string;
    required?: boolean;
    label?: string;
    labelStyle?: CSSProperties;
    triggerStyle?: CSSProperties;
    supportText?: string;
    fileType?: "video" | "image" | string;
    uploadingFileIcon?: string;
    value?: File | string;
    onChange?: (file?: File) => void;
}

const SingleFileUploadWithPreview = (props: ISingleFileUploadWithPreviewProps) => {
    const { 
        id, 
        required, 
        label, 
        labelStyle, 
        triggerStyle,
        supportText, 
        fileType = "image/*",
        uploadingFileIcon, 
        value,
        onChange,
    } = props;
    const fileRef = useRef<HTMLInputElement>(null);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        handleFilesToPreview(acceptedFiles[0]);
        onChange && onChange(acceptedFiles[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const [previewUrl, setPreviewUrl] = useState<string>(value ? (typeof value === "string" ? value : URL.createObjectURL(value)) : "");

    const handleFilesToPreview = (file: File) => {
        if (!file) {
            setPreviewUrl("");
        } else {
            setPreviewUrl(URL.createObjectURL(file));
        }
    }
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            handleFilesToPreview(file);
            onChange && onChange(file)
        }
    }
    const handleFileUploadClick = () => {
        if (fileRef && fileRef.current) {
            fileRef.current.click();
        }
    };
    const removePreviewMedia = (index?: number) => {
        setPreviewUrl("");
        if (fileRef && fileRef.current) {
            fileRef.current.value = "";
        }
        onChange && onChange();
    }

    return (
        <Container>
            {label && (
                <Label 
                    htmlFor={id} 
                    role="alert" 
                    style={labelStyle}
                >
                    {label} {required && <span style={{ fontSize: '8px' }}>(Required <span style={{ color: "#DC2626" }}>*</span>)</span>}
                </Label>)}
            {previewUrl === "" ? (
                <label htmlFor="uploadedImage" className="profile-upload-btn">
                    <input
                        id="uploadedImage"
                        type="file"
                        accept={fileType}
                        ref={fileRef}
                        style={{ display: "none" }}
                        onChange={handleChange}
                        {...getInputProps}
                    />
                    <FileUploadZone {...getRootProps()} style={triggerStyle}>
                        <img src={uploadingFileIcon} />
                        <RowStack>
                            <SupportText>{isDragActive ? "Is dragging file" : supportText}</SupportText>
                            <Button variant="ghost" size="sm" style={{ fontSize: "12px", lineHeight: "18px" }} onClick={handleFileUploadClick}>browse</Button>
                        </RowStack>
                    </FileUploadZone>
                </label>
            ) : (
                <PreviewDisplay url={previewUrl || ""} onRemovePreviewMedia={() => removePreviewMedia()} fileType={fileType} />
            )}
        </Container>
    )
}

export default SingleFileUploadWithPreview;

const Container = styled(Box)({
    width: '100%',
    maxWidth: '100%'
})
const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Rubik',
    paddingBottom: '4px',
    color: theme.palette.text.primary,
    lineHeight: '22px'
}))
const FilePreview = styled(Box)(({ theme }) => ({
    position: "relative",
    display: 'flex',
    alignItems: 'center',
    height: '239px',
    borderRadius: '22px',
    border: `1px solid ${theme.palette.primary.main}`,
    flexDirection: 'column'
}))
const ImagePreview = styled('img')({
    width: "100%", 
    height: "100%", 
    borderRadius: "15px",
})
const VideoPreview = styled('video')({
    width: "100%", 
    height: "100%", 
    borderRadius: "15px",
})
const RemoveButton = styled(IconButton)(({ theme }) => ({
    position: "relative",
    bottom: "85%",
    left: "40%",
    backgroundColor: "#D7D7D780", 
    padding: "3px", 
    borderRadius: 0,
    "& svg": { 
        height: "15px",
        width: "15px",
        fill: theme.palette.secondary.main 
    }
}))
const FileUploadZone = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "22px",
    height: "239px",
    border: `1px dashed ${theme.palette.secondary.contrastText}`,
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer"
}))
const RowStack = styled(Box)({
    display: "flex",
    alignItems: "center"
})
const SupportText = styled('span')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '10px',
    lineHeight: '12px',
}))