import { MenuItem, MenuProps, Select, SelectProps, styled } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React, { CSSProperties, forwardRef, ReactNode } from "react";

type WithChildren<T = {}> = T & { children?: ReactNode }

const getSizeStyles = (size?: string) => {
    switch (size) {
        case "lg":
            return "56px";
        case "sm":
            return "32px";     
        default:
            return "44px";
    }
}

const StyledMenuProps: Partial<MenuProps> = {
    PaperProps: {
      style: {
        maxHeight: "282px",
        width: "192px",
        marginTop: "10px",
        padding: "8px 0",
        borderRadius: "8px",
        backgroundColor: "#FFF",
        border: "1px solid #F1F5F9",
        boxShadow: "0 2px 4px 0 #00000026",
      }
    },
    MenuListProps: { style: { padding: 0 } },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    transformOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    getContentAnchorEl: null
};

interface MuiSelectProps extends SelectProps {
    id?: string;
    style?: CSSProperties;
    size?: "sm" | "md" | "lg";
    placeholder?: string;
    errors?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    items?: { value: string; label: string }[];
}

const MuiSelect = forwardRef<SelectProps, WithChildren<MuiSelectProps>>((props, ref) => {
    const { children, size = "md", placeholder, error, disabled, readOnly, style, items = [], value, onChange, ...rest } = props;

    return (
        <Container 
            style={{ 
                opacity: disabled ? 60 : 100, 
                cursor: disabled || readOnly ? "not-allowed" : "",
                ...style 
            }}
        >
            <StyledSelect
                ref={ref}
                disabled={disabled || readOnly}
                style={{ 
                    height: getSizeStyles(size as string), 
                    border: error ? "1px solid #DC2626" : "",
                    cursor: disabled || readOnly ? "not-allowed" : "",
                }}
                IconComponent={KeyboardArrowDownIcon}
                defaultValue={value}
                onChange={onChange}
                MenuProps={StyledMenuProps}
                {...rest}
            >
                <PlaceholderItem disabled value={-1}>{placeholder}</PlaceholderItem>
                {items.length > 0 && items.map(({ value, label }) => (
                    <StyledMenuItem key={value} value={value}>{label}</StyledMenuItem>
                ))}
                {children}
            </StyledSelect>
        </Container>
    )
})

export default MuiSelect;

const Container = styled('span')({
    position: "relative",
    width: '100%',
})
const StyledSelect = styled(Select)({
    width: '100%',
    maxWidth: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    backgroundColor: "#FFF",
    padding: "14px 12px",
    appearance: "none",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#475569",
    '&:active, &:focus, &:focus-within': { border: "border: 2px solid #1A469C" },
    '&:before, &:after': { boxSizing: "border-box" },
    '& > .MuiSelect-icon': {
        color: "#475569",
        width: '24px',
        height: "24px",
        '&:active, &:focus, &:focus-within': { color: "#1A469C" },
    },
    '& > .MuiSelect-select': { 
        padding: 0, 
        appearance: 'none', 
        userSelect: "none",
        '&:active, &:focus, &:focus-within': { background: "none", '-webkit-appearance': "none" },
    },
    "& .MuiMenu-list.MuiList-padding": { padding: 0 }
})
const StyledMenuItem = styled(MenuItem)({
    borderRadius: "8px",
    padding: "8px 16px",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#475569",
    "&.Mui-selected": {
        color: "#0F172A",
        backgroundColor: "#6683BD"
    },
    "&.Mui-selected:hover":{
        color: "#0F172A",
        backgroundColor: "#6683BD"
    },
    "&:hover":{
        backgroundColor: "#EDF1F6",
        color: "#1A469C"
    }
});
const PlaceholderItem = styled(MenuItem)({
    color: '#475569', 
    fontWeight: 400,
    opacity: 100,  
    transitioProperty: 'opacity',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: "75ms"
})