Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Scoring";
exports.labelBodyText = "Scoring Body";
exports.btnExampleTitle = "CLICK ME";
exports.requestFinalAssessmentEndpoint = "bx_block_assessmenttest/request_for_assessments/create_final_assessment_request";
exports.verifyOtp = "bx_block_assessmenttest/request_for_assessments/verify_otp";
exports.startTestEndpoint= "/bx_block_assessmenttest/request_for_assessments/start_test";
exports.retakeFinalTest = "/bx_block_assessmenttest/request_for_assessments/retake_test";
exports.submitTest = "bx_block_assessmenttest/final_tests/submit_assessment";
exports.submitQuiz = "bx_block_profile/quiz_attempts/submit_answer";
exports.submitReDoQuiz = "bx_block_profile/quiz_attempts/resubmit_incorrect_answers";
exports.listFinalTestEndPoint = "bx_block_assessmenttest/final_tests/listing_final_test_questions";
exports.showIncorrectAns = "bx_block_assessmenttest/final_tests/incorrect_questions";
exports.createCertificate = "bx_block_certification_tracking/employee_certificates";
exports.opponentID = 0;
exports.gameID = 0
// Customizable Area End