import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AllCategory } from "./FilteroptionsController";
type FilterCategory = 'status' | 'sort';
import { getStorageData } from "../../../framework/src/Utilities";

interface SelectedChips {
  status: string[];
  sort: string[];
  category: string[];
  subCategory: string[];
  company: string;
  group: string[];
  instructor: string[];
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  data: AllCategory[];
  arrayHolder: AllCategory[];
  isOpen: boolean;
  selectedChips: {
    status: string[];
    sort: string[];
    category: string[];
    subCategory: string[];
    company: string;
    group: string[];
    instructor: string[];
  },
  breadcrumbs: any[],
  allFilters: {
    status: string[];
    sort: string[];
    category: string[];
    subCategory: string[];
    categoryValue: { id: string, name: string }[];
    subCategoryValue: { id: string, name: string }[];
    company: string;
    group: string[];
    companyValue: { value: string, label: string }[];
    groupValue: { value: string, label: string }[];
    instructor: string[];
    instructorValue: { value: string, label: string }[];
  };
  courses: {
    image: string;
    course: string;
    group: string;
    category: string;
    subCategory: string;
    status: string;
    mostRelavent: boolean;
    percentage: number;
    instructorName?: string;
  }[];
  selectedTheme: boolean;
  error: string;
  searchQuery: string;
  open: boolean;
  roleId: string;
  currentPage: number;
  totalPage: number;
  isDropdownOpen: boolean,
  isPageDropdownOpen: boolean,
  sortOrder: string,
  selectedPageSize: number,
  loading: boolean,
  FiltersSelectes: string[],
  sortByNameAlchorEl: HTMLElement | null,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FilteritemsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: string = "";
  // Customizable Area Start
  coursesApiCallId: string = "";
  listCategoryApiCallId: string = "";
  subcategoryListApiCallId: string = "";
  apiProfileGETCallId: string = "";
  companiesApiCallId: string = "";
  groupsApiCallId: string = "";
  instructorsApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      data: [],
      arrayHolder: [],
      isOpen: false,
      allFilters: {
        status: [],
        sort: ["Status", "Relevance"],
        category: ["category1", "category2"],
        subCategory: ["SubCategory 1", "SubCategory 2", "SubCategory 3"],
        categoryValue: [{ id: "", name: "" }],
        subCategoryValue: [],
        company: "Company 1",
        group: ["Group 1", "Group 2", "Group 3"],
        companyValue: [{ value: "0", label: "Company 1" }],
        groupValue: [],
        instructor: [],
        instructorValue: []
      },
      selectedChips: {
        status: [],
        sort: [],
        category: [],
        subCategory: [],
        company: "",
        group: [],
        instructor: []
      },
      courses: [],
      selectedTheme: false,
      breadcrumbs: ['Dashboard', 'Current page'],
      error: "",
      searchQuery: "",
      open: false,
      roleId: "",
      currentPage: 1,
      totalPage: 1,
      isDropdownOpen: false,
      isPageDropdownOpen: false,
      sortOrder: "ascending",
      selectedPageSize: 10,
      loading: false,
      FiltersSelectes: [],
      sortByNameAlchorEl: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    this.listCategory()
    this.handleFilterCourses()
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    const mode = await getStorageData("darkMode");
    if (mode === 'true') {
      this.setState({ selectedTheme: true })
    }
    this.getUserProfileData();
    // Customizable Area End
  }

  getToken = () => {
    const messageValue: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(messageValue);
  };

  navigateToFilter = () => {
    let arrayHolder = this.state.arrayHolder;
    const priceRange = arrayHolder.map(element => element.attributes.price);
    var minimumValue = 0;
    var maximumValue = 0;
    if (
      (typeof priceRange === "number" || priceRange?.length >= 1) &&
      !priceRange
    ) {
      minimumValue = Math.min.apply(null, priceRange);
      maximumValue = Math.max.apply(null, priceRange);
    }
    let params = this.props.navigation.state?.params;
    if (params != undefined) {
      if (params.priceSelectedMin && params.priceSelectedMax) {
        this.props.navigation.push("Filteroptions", {
          min: minimumValue,
          max: maximumValue,
          priceSelectedMin: params.priceSelectedMin,
          priceSelectedMax: params.priceSelectedMax
        });
      }
    } else {
      this.props.navigation.push("Filteroptions", { min: minimumValue, max: maximumValue });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      runEngine.debugLog("Message Received", message);
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      runEngine.debugLog("API Message Received", message);

      if (this.coursesApiCallId === apiRequestCallId) {
        if (this.state.roleId === "worker") {
          this.processCoursesResponseWorker(responseJson)
        } else {
          this.processCoursesResponse(responseJson);
        }
        this.hideLoader();
      }
      if (responseJson) {
        this.handleOtherAPIsResponse(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }
  getListRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area Start
  processCoursesResponseWorker(responseJson: any) {
    if (!responseJson.errors) {
      const _courses = responseJson?.data.map((item: any) => ({
        id: item.id,
        image: item.attributes.course.data.attributes.image || "",
        course: item.attributes.course.data.attributes.course_name || "N/A",
        group: item.attributes.group || "N/A",
        category: item.attributes.course.data.attributes.category_name || "N/A",
        subCategory: item.attributes.course.data.attributes.sub_category_name || "N/A",
        status: item.attributes.status || "N/A",
        endTime: item.attributes.expiration_date || 'N/A',
        mostRelavent: item.attributes.course.data.attributes.most_relavent,
        percentage: item.attributes.progress_percentage,
      }));
      this.setState({
        courses: _courses,
        totalPage: responseJson?.meta?.total_pages || 1,
        currentPage: responseJson?.meta?.current_page || 1
      });
    } else {
      this.setState({
        error: responseJson?.errors || "Unknown error",
        courses: [],
      });
    }
  }

  processCoursesResponse(responseJson: any) {
    if (!responseJson.errors) {
      const _coureses = responseJson?.data.map((item: any) => ({
        id: item.id,
        image: item.attributes.image || "",
        course: item.attributes.course_name || "N/A",
        group: item.attributes.group_names || "N/A",
        category: item.attributes.category_name || "N/A",
        subCategory: item.attributes.sub_category_name || "N/A",
        status: item.attributes.status || "N/A",
        endTime: item.attributes.end_date || 'N/A',
        creationDate: new Date(item.attributes.created_at).toLocaleDateString() || 'N/A',
        expirationDate: new Date(item.attributes.end_date).toLocaleDateString() || 'N/A',
        mostRelavent: item.attributes.most_relavent,
        percentage: item.attributes.percentage,
        instructorName:  item.attributes.instructor,
      }));
      this.setState({
        courses: _coureses,
        totalPage: responseJson?.meta?.total_pages || 1,
        currentPage: responseJson?.meta?.current_page || 1
      });
    } else {
      this.setState({
        error: responseJson?.errors || "Unknown error",
        courses: [],
      });
    }
  }

  handleOtherAPIsResponse = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.listCategoryApiCallId: {
        const categoryNames = responseJson.data.map((item: any) => {
          return item.attributes?.name;
        });

        const categoryValue = responseJson.data.map((item: any) => {
          return { id: item?.id, name: item.attributes?.name };
        });

        this.setState((prevState) => ({
          allFilters: {
            ...prevState.allFilters,
            category: categoryNames,
            categoryValue: categoryValue,
          },
        }));
        break;
      }
      case this.subcategoryListApiCallId: {
        const subCategoryNames = responseJson.data.map((item: any) => {
          return item.attributes?.name;
        });

        const subCategoryValue = responseJson.data.map((item: any) => {
          return { id: item?.id, name: item.attributes?.name };
        });

        this.setState((prevState) => ({
          allFilters: {
            ...prevState.allFilters,
            subCategory: subCategoryNames,
            subCategoryValue: subCategoryValue,
          },
        }));
        break;
      }
      case this.companiesApiCallId: {
        this.setState((prevState) => ({
          allFilters: {
            ...prevState.allFilters,
            company: responseJson.data.map((item: any) => {
              return item.attributes?.company_name;
            }),
            companyValue: responseJson.data.map((item: any) => {
              return { value: item.id, label: item.attributes?.company_name };
            }),
          },
        }));
        break;
      }
      case this.groupsApiCallId: {
        this.setState((prevState) => ({
          allFilters: {
            ...prevState.allFilters,
            group: responseJson.data.map((item: any) => {
              return item.attributes?.group_name;
            }),
            groupValue: responseJson.data.map((item: any) => {
              return { value: item.id, label: item.attributes?.group_name };
            }),
          },
        }));
        break;
      }
      case this.instructorsApiCallId: {
        this.setState((prevState) => ({
          allFilters: {
            ...prevState.allFilters,
            instructor: responseJson.data.map((item: any) => {
              return `${item.attributes?.first_name} ${item.attributes?.last_name}`;
            }),
            instructorValue: responseJson.data.map((item: any) => {
              return { value: item.id, label: `${item.attributes?.first_name} ${item.attributes?.last_name}` };
            }),
          },
        }));
        break;
      }
      case this.apiProfileGETCallId: {
        this.setState({
          roleId: responseJson.role_id,
        }, () => { 
          this.setFiltersForRoles(); 
          if (responseJson.role_id === "instructor") {
            this.getCompanies();
            this.getGroups();
          }
          if (responseJson.role_id === "supervisor") {
            this.getGroups();
            this.getInstructors();
          }
        });
        break;
      }

      default:
        return
    }
  }

  appendFilter = (key: string, filterValue: string, filterKey: 'categoryValue' | 'subCategoryValue' | 'companyValue' | 'groupValue', formData: FormData) => {
    if (filterKey === "categoryValue" || filterKey === "subCategoryValue") {
      const foundItem = (this.state.allFilters[filterKey] as { id: string; name: string }[]).find(
        (item) => item.id === filterValue
      );
      foundItem && formData.append(`[filters][${key}_ids][]`, foundItem?.id);
    } else {
      const foundItem = (this.state.allFilters[filterKey] as { value: string; label: string }[]).find(
        (item) => item.value === filterValue
      );
      if (filterKey === 'groupValue') {
        foundItem && formData.append(`[filters][${key}_ids][]`, foundItem?.value);
      } else {
        foundItem && formData.append(`[filters][${key}_id]`, foundItem?.value);
      }
    }
  };

  filterCourses = async () => {
    this.showLoader();

    const header = {
      token: await getStorageData("authToken"),
    };

    const sortOrder = this.state.sortOrder;
    const current_page = this.state.currentPage
    const per_page = this.state.selectedPageSize

    const formData = new FormData();
    formData.append("q", "");
    formData.append("page", current_page.toString());
    formData.append("per_page", per_page.toString());
    if (this.state.selectedChips) {
      const { sort, status, category, company, group, instructor } = this.state.selectedChips;
      sort && sort.length > 0 
        ? formData.append("filters[sort]", sort[0] === "published_date" ? "creation_date" : sort[0])
        : formData.append("filters[sort]", sortOrder);
      if (status) {
        this.state.roleId !== "worker" 
          ? status.map(each => formData.append("filters[course_status][]", each))
          : status[0] && formData.append("filters[status]", status[0]);
      }
      category && category.map(each => formData.append("filters[category_ids][]", each));
      company && formData.append("filters[company_id]", company);
      group && group.map(each => formData.append("filters[group_ids][]", each));
      instructor && instructor.map(each => formData.append("filters[instructor_profile_ids][]", each));
    }

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.filterCoursesEndPoint);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);
    this.coursesApiCallId = message.messageId;

    try {
      runEngine.sendMessage(message.id, message);

    } catch (error) {
      console.error("Error filtering courses:", error);
    }
  };

  listCategory = async () => {
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.listCategoryApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.categoryListEndPoint);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(message.id, message);
  }

  getCompanies = async () => {
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.companiesApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.fetchCompanyList);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(message.id, message);
  }

  getGroups = async () => {
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.groupsApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.fetchGroupList);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(message.id, message);
  }
  getInstructors = async () => {
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.instructorsApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.fetchInstructorList);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(message.id, message);
  }
  subCategoryListApi = async (categoryId?: string) => {
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.subcategoryListApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.subcategoryListEndPoint + (categoryId ? `?category_id=${categoryId}` : "")
    );
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(message.id, message);
  }

  handleChipClick = (chip: string, type: FilterCategory) => {
    this.setState((prevState) => {
      const selectedChips = { ...prevState.selectedChips };
     
      const index = selectedChips[type].indexOf(chip);
      if (index === -1) {
        selectedChips[type].push(chip);
        this.addBreadcrumb(chip);
      } else {
        selectedChips[type].splice(index, 1);
        this.removeBreadcrumb(chip);
      }
      const selected = Object.values(selectedChips).flat();
      return { selectedChips,
        FiltersSelectes: selected
      };
    }, () => this.filterCourses());
  };

  handleRemoveFilter = (type: FilterCategory, filter: string) => {
    this.setState((prevState) => {
      const selectedChips = { ...prevState.selectedChips };
      selectedChips[type] = selectedChips[type].filter(chip => chip !== filter);
      this.removeBreadcrumb(filter);
      return { selectedChips };
    }, () => this.filterCourses());
  };

  handleRadioboxChange = (option: string, type: "category" | "subCategory") => {
    this.setState((prevState) => {
      const selectedChips = { ...prevState.selectedChips };
      const index = selectedChips[type].indexOf(option);
      if (index === -1) {
        selectedChips[type].push(option);
        this.addBreadcrumb(option);
      } else {
        selectedChips[type].splice(index, 1);
        this.removeBreadcrumb(option);
      }
      const selected = Object.values(selectedChips).flat();
      return { selectedChips: { ...selectedChips, }, FiltersSelectes: selected};
    }, () => this.filterCourses());
  };

  handleSelectChange = (value: string, type: "company" | "group" | "instructor") => {
    this.setState((prevState) => {
      const currentChips = { ...prevState.selectedChips };
      if (type === "company") {
        if (currentChips.company !== value) {
          currentChips.company = value;
        } else {
          currentChips.company = "";
        }
      } else {
        const index = currentChips[type].indexOf(value);
        if (index === -1) {
          currentChips[type].push(value);
          this.addBreadcrumb(value);
        } else {
          currentChips[type] = [...currentChips[type].slice(0, index), ...currentChips[type].slice(index + 1, currentChips[type].length)];
          this.removeBreadcrumb(value);
        }
      }
      
      const selected = Object.values(currentChips).flat();
      return { 
        selectedChips: currentChips, 
        FiltersSelectes: selected 
      };
    }, () => this.filterCourses());
  };

  handleBreadcrumbClick = (item: string) => {
    this.setState((prevState) => ({
      breadcrumbs: [...prevState.breadcrumbs, item]
    }));
  };

  addBreadcrumb = (breadcrumb: string) => {
    this.setState((prevState) => {
      const breadcrumbs = [...prevState.breadcrumbs];
      if (!breadcrumbs.includes(breadcrumb)) {
        breadcrumbs.push(breadcrumb);
      }
      return { breadcrumbs };
    });
  };

  removeBreadcrumb = (breadcrumb: string) => {
    this.setState((prevState) => {
      const breadcrumbs = prevState.breadcrumbs.filter(crumb => crumb !== breadcrumb);
      return { breadcrumbs };
    });
  };

  navigatetoPage = (page: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), page);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  getUserProfileData = () => {
    const webHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProfileGETCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileGetURL
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }
  setFiltersForRoles = () => {
    if (this.state.roleId === "worker") {
      const newFilters = {
        status: ["in_progress", "completed"],
        sort: ["status"],
      }
      this.setState({
        allFilters:{...this.state.allFilters, ...newFilters},
      });
    } else if(this.state.roleId==="instructor"){
      const newFilters = {
        status: ["draft", "published", "expired"],
        sort: ["published_date", "expiration_date"],
      }
      this.setState({
        allFilters:{...this.state.allFilters ,...newFilters},
      });
    }
    else if (this.state.roleId === "supervisor") {
      const newFilters = {
        status: ["published", "expired"],
        sort: ["completion_rate"],
      }
      this.setState({
        allFilters: { ...this.state.allFilters, ...newFilters },
      });
    }
  }
  formatStatusFilter = (status: string): string => {
    return status
      .replace(/[_-]/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  };
  changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value }, () => this.filterCourses())
  }
  formatStatus = (status: string): string => {
    return status.toUpperCase().replace(/_/g, ' ');
  };

  handleFilterCourses = async () => {
    let selectedFilter = await getStorageData("selectedFilter");
    if (selectedFilter) {
      selectedFilter = JSON.parse(selectedFilter)
      const _slectedFilter: SelectedChips = { sort: selectedFilter.filters.sort, status: selectedFilter.filters.status, category: [], subCategory: [], company: "", group: [], instructor: [] }
      this.setState({ currentPage: 1, searchQuery: selectedFilter.searchQuery, selectedChips: _slectedFilter }, () => this.filterCourses())
    }
    else {
      this.filterCourses()
    }
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  };

  togglePageDropdown = () => {
    this.setState((prevState) => ({
      isPageDropdownOpen: !prevState.isPageDropdownOpen,
    }));
  };

  handleSort = (sortOrder: string) => {
    this.setState({ sortByNameAlchorEl: null, sortOrder }, () => this.filterCourses());
  };

  handlePageSizeChange = (size: number) => {
    this.setState({ selectedPageSize: size }, () => {
      this.filterCourses();
    });
  };
  

  handleReset = async () => {
    // Reset the necessary states
    this.setState({
      FiltersSelectes: [],
      selectedChips: {
        status: [],
        sort: [],
        category: [],
        subCategory: [],
        company: "",
        group: [],
        instructor: []
      },
      breadcrumbs: ['Dashboard', 'Current page']
    });
    await this.filterCourses();
  };

  handleNavigate = (courseId: string) => {
    this.props.navigation.navigate("Projectnotes", { id: courseId })
  }
  showLoader = () => {
    this.setState({ loading: true });
  }
  hideLoader = () => {
    this.setState({ loading: false });
  }
  // Customizable Area End
}
