import { Box, Input as MuiInput, InputProps, styled } from "@material-ui/core";
import React, { CSSProperties, forwardRef, ReactNode, useEffect, useRef, useState } from "react";

const CustomisedTimeInput = ({ value, onChange }: Omit<InputProps, "onChange" | "value"> & { value?: string; onChange?: (value: string) => void }) => {
    console.log(value);
    const [min, setMin] = useState("");
    const [sec, setSec] = useState("");

    const secondInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (value) {
            const [currMin, currSec] = value.split(":");
            setMin(currMin ?? "");
            setSec(currSec ?? "");
        }
    }, [value])

    const handleMinuteChange = (value: string) => {
        if (min.length === 1) {
            secondInputRef.current && secondInputRef.current?.click();
        }
        setMin(value);
    }
    const handleSecondChange = (value: string) => {
        setSec(value);
        onChange && onChange(`${min}:${value}`);
    }

    return (
        <TimeInputContainer>
            <MuiInput 
                id="minutes" 
                name="minute" 
                placeholder="MM"
                style={{ width: "25px" }} 
                inputProps={{ pattern: "[0-9][0-9]", maxLength: 2 }} 
                onKeyDown={event => {
                    if (!/\d/.test(event.key) && !['Backspace','Delete','ArrowRight','ArrowLeft','Tab'].includes(event.key)) {
                        event.preventDefault();
                    }
                }}
                value={min}
                onChange={event => handleMinuteChange(event.target.value)}
            />
            <span>:</span>
            <MuiInput 
                id="seconds" 
                name="second" 
                placeholder="SS"
                ref={secondInputRef}
                style={{ width: "25px" }} 
                inputProps={{ pattern: "[0-5][0-9]", maxLength: 2 }}
                onKeyDown={event => {
                    if (!/\d/.test(event.key) && !['Backspace','Delete','ArrowRight','ArrowLeft'].includes(event.key)) {
                        event.preventDefault();
                    }
                }}
                value={sec}
                onChange={event => handleSecondChange(event.target.value)}
            />
        </TimeInputContainer>
    )
}

interface TimeInputProps extends Omit<InputProps, "onChange"> {
    id?: string;
    inputSize?: 'sm' | 'md' | 'lg' | string;
    layoutStyle?: CSSProperties;
    label?: string;
    labelStyle?: CSSProperties;
    helperText?: ReactNode;
    errorText?: string;
    value?: string;
    onChange?: (value: string) => void;
}

const TimeInput = forwardRef<InputProps, TimeInputProps>((props, ref) => {
    const { inputSize = 'md', id, layoutStyle, label, labelStyle, required, helperText, errorText, ...inputProps } = props;

    return (
        <Container style={layoutStyle}>
            {label && (
                <Label 
                    htmlFor={id} 
                    role="alert" 
                    style={labelStyle}
                >
                    {label} {required && <span style={{ fontSize: '8px' }}>(Required <span style={{ color: "#DC2626" }}>*</span>)</span>}
                </Label>)}
            <CustomisedTimeInput 
                id={id}
                ref={ref}
                style={{ height: inputSize === 'lg' ? '56' : inputSize === 'sm' ? '32' : '44', border: !!errorText ? '1px solid #DC2626' : '' }}
                {...inputProps}
                onChange={inputProps.onChange}
            />
            {errorText && <HintText>{errorText}</HintText>}
        </Container>
    )
})

export default TimeInput;

const Container = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: '100%',
    maxWidth: '100%',
})
const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Rubik',
    paddingBottom: '4px',
    color: theme.palette.text.primary,
    lineHeight: '22px'
}))
const TimeInputContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '100%',
    padding: '10px 12px',
    display: "flex",
    alignItems: "center",
    appearance: 'none',
    fontFamily: 'Rubik',
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.info.dark}`,
    gap: '2px',
    borderRadius: '8px',
    lineHeight: '22px',
    background: theme.palette.background.default,
    "& .MuiInputBase-input": { padding: 0 },
    "& .MuiInput-underline.Mui-focused": { borderBottom: "1px solid #334155" }
}))
const HintText = styled('p')({
    display: 'inline-block',
    marginTop: '2px',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#DC2626'
})