import { Box, styled, Typography } from "@material-ui/core";
import React, { ChangeEvent, CSSProperties, useEffect, useRef, useState } from "react";

import { db } from "./fetch-files";
import useOfflineBlob from "./hooks/useOfflineBlob";

import { UploadedFile } from "../../projectnotes/src/ProjectnotesController";

const previousButton = require("../../projectnotes/assets/previousButton.png")
const PauseButton = require("../../projectnotes/assets/PauseButton.png")
const playPauseButton = require("../../projectnotes/assets/playPauseButton.png")
const AutoplayOn = require("../../projectnotes/assets/AutoPlayOn.png")
const AutoplayOff = require("../../projectnotes/assets/Autoplay.png")
const nextButton = require("../../projectnotes/assets/nextButton.png")
const Replay = require("../../projectnotes/assets/Replay.png")
const expand = require("../../projectnotes/assets/expand.png")
const Volume = require("../../projectnotes/assets/Volume.png")

type ICustomControlsProps = {
    currentTime: number;
    duration: number,
    autoplay: boolean;
    isFullscreen: boolean; 
    isCompleted: boolean;
    isPlaying: boolean;
    onReplay: () => void; 
    onPrevClick: () => void; 
    onNextClick: () => void; 
    onTogglePlay: () => void;
    onExpand: () => void;
    onToggleAutoplay: () => void;
    onProgressChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onVolumeChange: (volume: number) => void;
}

const CustomControls = ({ 
    currentTime,
    duration, 
    autoplay,
    isFullscreen, 
    isCompleted, 
    isPlaying, 
    onReplay, 
    onPrevClick, 
    onNextClick, 
    onTogglePlay, 
    onExpand,
    onToggleAutoplay,
    onProgressChange,
    onVolumeChange 
}: ICustomControlsProps) => {
    const [volume, setVolume] = useState(1);
    const [showVolumeBar, setShowVolumeBar] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    
    useEffect(() => {
        if (isMuted) {
            setVolume(0);
        }
    }, [isMuted])
    useEffect(() => {
        onVolumeChange(volume);
    }, [volume])

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${minutes}:${formattedSeconds}`;
    };
    
    const  handleVolumeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newVolume = parseFloat(event.target.value);
        setVolume(newVolume);
        if (newVolume === 0) {
            setIsMuted(true);
        } else {
            setIsMuted(false);
        }
    };

    return (
        <ColStack 
            style={{
                position: isFullscreen ? "fixed" : 'absolute',
                width: isFullscreen ? "100%" : "calc(100% - 2rem)", 
                bottom: isFullscreen ? "0" : "1rem", 
                left: isFullscreen ? "0" : "1rem", 
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottomLeftRadius: isFullscreen ? "0px" : "18px", 
                borderBottomRightRadius: isFullscreen ? "0px" : "18px", 
                zIndex: isFullscreen ? 1001 : "auto", 
            }} 
        >
            <ProgressBar
                type="range"
                min="0"
                max="100"
                value={(currentTime / duration) * 100 || 0}
                onChange={onProgressChange}
                style={{
                    background: `linear-gradient(to right, rgba(26, 70, 156, 1) ${((currentTime / duration) * 100) || 0}%, rgba(255, 255, 255, 0.3) ${((currentTime / duration) * 100) || 0}%)`,
                }}
            />
            <RowStack style={{ width: '100%', height: '44px', justifyContent: 'space-between' }}>
                <RowStack style={{ alignItems: 'center' }}>
                    {isCompleted && <ImgIconButton onClick={onReplay} src={Replay}  />}  
                    <ImgIconButton onClick={onPrevClick} src={previousButton} />
                    <button
                        onClick={onTogglePlay}
                        style={{
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: "white",
                        }}
                    >
                        {isPlaying ? (
                            <ImgIconButton src={PauseButton} />
                            ) : (
                            <ImgIconButton src={playPauseButton} />
                        )}
                    </button>
                    <ImgIconButton onClick={onNextClick} src={nextButton} />   

                    <Box
                        onClick={() => setIsMuted(!isMuted)}
                        onMouseEnter={() => setShowVolumeBar(true)}
                    >
                        <ImgIconButton src={Volume} style={{ width: '18px', height: '18px' }}/> 
                    </Box>
                    {showVolumeBar && (
                        <VolumeControl
                            type="range"
                            id="volume-slider"
                            min="0"
                            max="1"
                            step="0.01"
                            value={volume}
                            onChange={handleVolumeChange}
                            style={{
                                background: `linear-gradient(to right, rgba(248, 250, 252, 1) ${volume * 100}%, rgba(249, 249, 249, 0.2) ${volume * 100}%)`,
                            }}
                        />)}
        
                    <Typography 
                        style={{
                            fontFamily: "Rubik",
                            fontWeight: 500,
                            fontSize: '10px',
                            color: 'rgba(248, 250, 252, 1)',
                            paddingLeft: '6px'
                        }}
                    >{`${formatTime(currentTime)} / ${formatTime(duration)}`}</Typography>                   
                </RowStack>
        
                <RowStack style={{ alignItems: 'center' }}>
                    <ControlsTypo>Auto Play</ControlsTypo>
                    <Box style={{ marginLeft: autoplay ? '6px' : '0' }}>
                        <ImgIconButton data-test-id= "autoplay" onClick={onToggleAutoplay} src={autoplay ? AutoplayOn : AutoplayOff} />
                    </Box>
                    <ImgIconButton data-test-id= "expand" onClick={onExpand} src={expand} />
                </RowStack>
            </RowStack>
        </ColStack>
    )
}

type IVideoPlayerProps = { 
    files: UploadedFile[];
    style?: CSSProperties;
    onComplete: () => void;
}
const VideoPlayer = ({
    files = [],
    style,
    onComplete,
}: IVideoPlayerProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [currentTime, setCurrentTimer] = useState(0);
    const [duration, setDuration] = useState(0);
    const [autoplay, setAutoplay] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [showControls, setShowControls] = useState(false);
    const [currentUrl, setCurrentUrl] = useState("");
    const [currentFile, setCurrentFile] = useState<UploadedFile>(files[currentIndex]);

    const hookUrl = useOfflineBlob(currentFile);

    useEffect(() => setCurrentUrl(hookUrl || ""), [hookUrl]);
    useEffect(() => {
        setCurrentFile(files[currentIndex]);
    }, [files, currentIndex])
    useEffect(() => {
        if (completed && currentIndex === files.length - 1) {
            onComplete();
        }
    }, [completed])

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            setCurrentTimer(videoRef.current.currentTime);
        }
    };
    const handleLoadedMetadata = () => {
        if (videoRef.current) {
            setDuration(videoRef.current.duration);
        }
    };
    const handleVideoEnded = () => {
        const nextIndex = currentIndex + 1;

        setCompleted(true);
        setIsPlaying(false);

        if (nextIndex < files.length) {
            if (autoplay) {
                setCurrentIndex(nextIndex);
                setIsPlaying(true);
                setCompleted(false);
                if (videoRef && videoRef?.current) {
                    videoRef.current.currentTime = 0;
                    videoRef.current.play();
                }
            }
        }
    };
    const handleProgressChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (videoRef.current) {
            const value = parseFloat(e.target.value);
            videoRef.current.currentTime = (value / 100) * duration;
            setCurrentTimer(videoRef.current.currentTime);
        }
    };
    const handleVolumeChange = (volume: number) => {
        if (videoRef && videoRef.current) {
            videoRef.current.volume = volume;
        }
    }
    const handleReplay = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }
        setIsPlaying(true);
        // this.setState({ videoCompleted: false, isPlaying: true });
    };
    const handleTogglePlay = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
          }
    }

    if (isFullscreen) {
        return (
            <>
                <FullscreenVideo
                    ref={videoRef}
                    // data-test-id= "currentVideo"
                    src={currentUrl}
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleLoadedMetadata}
                    onEnded={handleVideoEnded}
                    controls={false}
                    style={style}
                />
                {showControls && <CustomControls 
                    isFullscreen={isFullscreen}
                    isCompleted={completed}
                    onReplay={handleReplay} 
                    currentTime={currentTime} 
                    duration={duration || 0} 
                    autoplay={autoplay} 
                    isPlaying={isPlaying} 
                    onPrevClick={() => setCurrentIndex(prev => prev - 1)} 
                    onNextClick={() => setCurrentIndex(prev => prev + 1)} 
                    onTogglePlay={handleTogglePlay} 
                    onExpand={() => setIsFullscreen(!isFullscreen)} 
                    onToggleAutoplay={() => setAutoplay(!autoplay)} 
                    onProgressChange={handleProgressChange}
                    onVolumeChange={handleVolumeChange}
                />}
            </>

          )
    }

    return (
        <Box
            style={{ position: "relative", width: "100%", height: "641px" }}
            onMouseEnter={() => setShowControls(true)}
            onMouseLeave={() => setShowControls(false)}
        >
            <Video
                ref={videoRef}
                // data-test-id= "currentVideo"
                src={currentUrl}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
                onEnded={handleVideoEnded}
                controls={false}
                style={style}
            />
            {showControls && <CustomControls 
                isFullscreen={isFullscreen}
                isCompleted={completed}
                onReplay={handleReplay} 
                currentTime={currentTime} 
                duration={duration || 0} 
                autoplay={autoplay} 
                isPlaying={isPlaying} 
                onPrevClick={() => setCurrentIndex(prev => prev - 1)} 
                onNextClick={() => setCurrentIndex(prev => prev + 1)} 
                onTogglePlay={handleTogglePlay} 
                onExpand={() => setIsFullscreen(!isFullscreen)} 
                onToggleAutoplay={() => setAutoplay(!autoplay)} 
                onProgressChange={handleProgressChange}
                onVolumeChange={handleVolumeChange}
            />}
        </Box>
    )
}

export default VideoPlayer;

const RowStack = styled(Box)({
    display: "flex"
})
const ColStack = styled(Box)({
    flexDirection: "column"
})
const Video = styled('video')({
    position: "relative",
    top: "auto",
    left: "auto",
    width: "100%",
    height: "641px",
    objectFit: "contain",
    borderRadius: "18px",
    zIndex: "auto",
})
const FullscreenVideo = styled('video')({
    position: "fixed",
    top: 0,
    left: 0 ,
    width: "100vw",
    height: "100vh",
    objectFit: "cover",
    borderRadius: 0,
    zIndex: 1000,
})
const ImgIconButton = styled('img')({
    width: "24px",
    height: "24px"
})
const ControlsTypo = styled(Typography)({
    fontFamily: "Poppins",
    fontSize: '11px',
    color: 'rgba(255, 255, 255, 1)'
})
const ProgressBar = styled('input')({
    flexGrow: 1,
    margin: "0",
    height: "4px",
    cursor: "pointer",
    appearance: "none",
    width: '100%'
})
const VolumeControl = styled('input')({
    width: "52px",
    height: "2px",
    borderRadius: "4px",
    outline: "none",
    appearance: "none",
    cursor: "pointer",
    "&#volume-slider::-webkit-slider-thumb": {
        "-webkit-appearance": "none",
        width: "6px",
        height: "6px",
        backgroundColor: "rgba(248, 250, 252, 1)",
        borderRadius: "50%",
        borderColor: "rgba(248, 250, 252, 1)",
        cursor: "pointer"
    },
    
    "&#volume-slider::-moz-range-thumb": {
        width: "6px",
        height: "6px",
        backgroundColor: "rgba(248, 250, 252, 1)",
        borderRadius: "50%",
       borderColor: "rgba(248, 250, 252, 1)",
        cursor: "pointer"
    },
    
    "#volume-slider::-webkit-slider-runnable-track": {
        width: "100%",
        height: "2px",
        backgroundColor: "transparent",
        border: "none",
        borderRadius: "4px"
    },
    "#volume-slider::-moz-range-track": {
        width: "100%",
        height: "2px",
        backgroundColor: "transparent",
        border: "none",
        borderRadius: "4px"
    }
})