import { Box, Link, styled, Typography } from "@material-ui/core";
import React, { CSSProperties, useEffect, useState } from "react";

import Button from "./Button";

const placeholder = require("../../projectnotes/assets/link-preview-placeholder.png");

type ILinkPreviewProps = { 
    url: string;
    style?: CSSProperties;
    onComplete: () => void;
}

const LinkPreview = ({
    url,
    style,
    onComplete,
}: ILinkPreviewProps) => {
    const [previewData, setPreviewData] = useState<{ title: string; image: string } | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(url);
            const data = await response.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, 'text/html');
            const title = doc.querySelector('title')?.textContent || '';
            const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || '';
    
            setPreviewData({ title, image });
            setLoading(false);
          } catch (error) {
            console.error(error);
            setLoading(false);
          }
        };
    
        fetchData();
    }, [url]);

    if (loading) {
        return <Typography>Loading...</Typography>;
    }
    
    const handleClick = () => {
        onComplete();
        window.open(url, '_blank');
    };

    return (
       <Container style={style}>
            <img 
                src={previewData && previewData?.image || placeholder} 
                style={{ width: "100%", height: "223px", objectFit: "cover" }} 
                alt="Link preview" 
            />
            <ColStack style={{ padding: "0 22px 28px 21px", gap: "13px" }}>
                <LinkTitle>{previewData?.title || "Failed to fetch link preview"}</LinkTitle>
                <RowStack justifyContent="space-between" alignItems="center">
                    <CustomLink href={url} target="_blank">{url}</CustomLink>
                    <Button style={{ borderRadius: "30px", padding: "5px 16px" }} onClick={handleClick}>View link</Button>
                </RowStack>
            </ColStack>
       </Container>
    )
}

export default LinkPreview;

const RowStack = styled(Box)({
    display: "flex"
})
const ColStack = styled(RowStack)({
    flexDirection: "column"
})
const Container = styled(ColStack)(({ theme }) => ({
    borderRadius: "17px",
    backgroundColor: "#F0F0F0",
    gap: "14px"
}))
const CustomLink = styled(Link)({
    fontFamily: "Rubik",
    fontSize: "14px",
    lineHeight: 1.5,
    color: "#1A469C"
})
const LinkTitle = styled(CustomLink)({
    fontWeight: 700,
    color: "#0F172A"
})
