import React, { ComponentPropsWithoutRef, CSSProperties, ElementType, PropsWithChildren, ReactNode, useCallback, useState } from "react";
import { Button as ButtonComponent, styled } from "@material-ui/core";

type ButtonSettingProps = {
    children?: ReactNode;
    size?: "sm" | "md" | "lg";
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
    animation?: "progress" | "success" | "error" | "pulse" | boolean;
    disabled?: boolean;
    variant?: "fill" | "outline" | "primary" | "secondary" | "ghost";
    fullWidth?: boolean;
}

const getSmPadding = ({ iconLeft, iconRight, fullWidth }: ButtonSettingProps): CSSProperties => {
    if (fullWidth) {
        return { paddingRight: '10px', paddingLeft: '10px' }
    }
    if (iconLeft) {
        return { paddingInlineStart: '10px' }
    }
    if (iconRight) {
        return { paddingInlineEnd: '10px' }
    }
    return { paddingRight: '10px', paddingLeft: '10px' }
}
const getMdPadding = ({ iconLeft, iconRight, fullWidth }: ButtonSettingProps): CSSProperties => {
    if (fullWidth) {
        return { paddingRight: '16px', paddingLeft: '16px' }
    }
    if (iconLeft) {
        return { paddingInlineStart: '16px' }
    }
    if (iconRight) {
        return { paddingInlineEnd: '16px' }
    }
    return { paddingRight: '16px', paddingLeft: '16px' }
}
const getFontSize = ({ size }: ButtonSettingProps): CSSProperties => {
    if (size === 'sm') {
        return { fontSize: "14px", lineHeight: "22px" }
    }
    if (size === 'lg') {
        return { fontSize: "18px", lineHeight: "26px" }
    }
    return { fontSize: "16px", lineHeight: "24px" }
}
const getButtonSize = ({ size, iconLeft, iconRight, fullWidth }: ButtonSettingProps): CSSProperties => {
    if (size === 'sm') {
        return { height: '32px', paddingTop: '4px', paddingRight: '4px', gap: '6px', ...getSmPadding({ iconLeft, iconRight, fullWidth }) }
    }
    if (size === 'lg') {
        return { height: '56px', paddingTop: '16x', paddingRight: '16px', gap: '8px', ...getMdPadding({ iconLeft, iconRight, fullWidth }) }
    }
    return { height: '44px', paddingTop: '10x', paddingRight: '10px', gap: '8px', ...getMdPadding({ iconLeft, iconRight, fullWidth }) }
}
const getButtonVariants = ({ variant, disabled }: Pick<ButtonSettingProps, 'variant' | 'disabled'>): CSSProperties => {
    if (variant === "secondary" || variant === "outline") {
        return { 
            color: !disabled ? '#1A469C' : '#64748B', 
            backgroundColor: !disabled ? 'transparent' : '#F1F5F9', 
            border: !disabled ? '1px solid #1A469C' : 'none' 
        }
    }
    if (variant === "ghost") {
        return { color: !disabled ? '#1A469C' : '#94A3B8', backgroundColor: 'transparent' }
    }
    return { color: !disabled ? '#F8FAFC' : '#43536A', backgroundColor: !disabled ? '#1A469C' : '#F1F5F9' }
}

type ButtonProps<C extends ElementType> = {
    as?: C;
    fullWidth?: boolean;
    style?: CSSProperties;
} & ButtonSettingProps;
type IButtonProps<C extends ElementType> = PropsWithChildren<ButtonProps<C>> & Omit<ComponentPropsWithoutRef<C>, keyof ButtonProps<C>>

const Button = <C extends ElementType = "button">({
    children,
    variant = "primary",
    size = "md",
    iconLeft,
    iconRight,
    fullWidth,
    disabled,
    animation,
    as,
    style,
    ...rest
}: IButtonProps<C>) => {
    // const hasAnimationContent = animation === "progress" || animation === "success";

    const [isHover, setIsHover] = useState(false);

    const onMouseEnter = useCallback(() => setIsHover(true), [setIsHover]);
    const onMouseLeave = useCallback(() => setIsHover(false), [setIsHover]);

    return (
        <CustomButtonComponent 
            style={{ 
                ...getButtonSize({ size, iconLeft, iconRight, fullWidth }),
                ...(variant === "ghost" ? getFontSize({ size }) : { fontSize: '16px', lineHeight: '24px' }),
                padding: variant === 'ghost' ? 0 : '',
                ...getButtonVariants({ variant, disabled }),
                // delay animation
                width: fullWidth ? '100%' : style?.width,
                ...style
            }}
            {...((!as || as === 'button') && { type: 'button' })}
            {...(disabled && { disabled })}
            {...rest}
            startIcon={iconLeft}
            endIcon={iconRight}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
            {isHover && <Hover 
                style={{ 
                    backgroundColor: (variant === 'outline' || variant === 'secondary') ? '#E8EDF5' : (variant !== 'ghost' ? '#173F8C' : 'transparent'), 
                    color: variant === 'ghost' ? '#173F8C' : '' 
                }} 
            />}
        </CustomButtonComponent>
    )
}

export default Button;

const CustomButtonComponent = styled(ButtonComponent)({
    position: "relative",
    zIndex: 0,
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 700,
    textTransform: 'none',
    textDecorationLine: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    userSelect: 'none',
    "&:disabled": { cursor: "not-allowed" }
})
const Hover = styled('span')({
    zIndex: -1,
    position: 'absolute',
    inset: 0,
    pointerEvents: 'none',
    transitionProperty: 'color, background-color, border-color, text-decoration-color, fill, stroke',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '150ms'
})