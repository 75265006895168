// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import React, {createRef, CSSProperties} from 'react';
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "./components/ApiCall";

import { getStorageData , setStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { OnChangeProps } from "../../utilities/src/CustomisedSelect";
import { downloadFileFromUrl } from "../../utilities/src/fetch-files";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
export interface ItemData {
  id: "259",
  type: "note",
  attributes: {
    id: 259,
    note_content: "<div><a href=\"www.instagram.com\">www.instagram.com</a></div>",
    reference_id: 1,
    is_deleted: false,
    is_updated: false,
    created_at: "2023-06-07T11:53:01.383Z",
    updated_at: "2023-06-07T11:53:01.383Z",
    created_time: "11:53:01",
    created_date: "2023-06-07"
  },
}

export interface ResponseData {
  message: boolean;
  id: number,
  type: string,
  attributes: ObjectAttributes,
  data: []
}

interface IResponseData<T> {
  message: boolean;
  id: number,
  type: string,
  attributes: T,
}

interface ObjectAttributes {
  id: number,
  note_content: string,
  reference_id: number,
  is_deleted: false,
  is_updated: false,
  created_at: string,
  updated_at: string,
  created_time: string,
  created_date: string
}

export interface DeleteItem {
  attributes: {
    created_at: "",
    created_date: "",
    created_time: "",
    id: 96,
    is_deleted: false,
    is_updated: false,
    note_content: "",
    reference_id: 1,
    updated_at: ""
  },
  id: "",
  type: ""
}
export interface Image {
  id: number;
  url: string;
}

interface FileContent {
  fileName: string;
  storage: string;
  download?: boolean;
}

interface MediaContent {
  fileContent1: FileContent[];
  image1: string;
  image2: string;
  fileContent2: FileContent[];
  fileContent3: FileContent[];
}
export interface AudioState {
  isAudioPlaying: boolean;
  currentAudioTime: number;
  audioDuration: number;
  audioProgress: number;
}

interface RemainingTime  {
  [chapterIndex: number]: number; 
};

interface CompletedStatus  {
  [subTopicIndex: string]: boolean; 
};

export interface NoteAttributes {
  id: number;
  title: string;
  note_content: string;
  account_id: number;
  role_id: string;
  preferred_name: string;
  course_id: number;
  is_deleted: boolean;
  is_updated: boolean;
  created_at: string;
  updated_at: string;
  images: Image[];
  created_time: string;
  created_date: string;
}
export interface IndNoteAttributes {
  id: number;
  title: string;
  note_content: string;
  account_id: number;
  role_id: string;
  preferred_name: string;
  course_id: number;
  is_deleted: boolean;
  is_updated: boolean;
  created_at: string;
  updated_at: string;
  images: Image[];
  created_time: string;
  created_date: string;
}

export interface Notes {
  id: string;
  type: string;
  attributes: NoteAttributes;
}
export interface Note {
  id: string;
  type: string;
  attributes: IndNoteAttributes;
}

export interface NotesData {
  data: Notes[];
}
export interface NoteDataResponse {
  data:Note | [];
}
interface AudioChapter {
  intro: string;
  time: number;
  src: string;
}
interface Video {
  intro: string;
  time: number;
  type: 'ppt' | 'video' | 'pdf' | 'audio';
  src?: string; 
  audioDetails?: AudioChapter[]; 
}
type responseData=string | number | boolean;
interface ColorObject {
   color: string;
   bgColor: string;
}
export interface UploadedFile {
  id: number;
  filename: string;
  size?: number;
  content_type?: string;
  url: string;
}
export interface SubTopic {
  id: string;
  title: string;
  lesson_type: string;
  lesson_timer?: string;
  files?: UploadedFile[];
  quiz_data:any;
  content?: string;
  completed: boolean;
}
interface Lesson {
  id: string;
  title: string;
  subTopics: SubTopic[]
}
interface Chapter {
  id: string;
  title: string;
  lessons: Lesson[];
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  notesList: [];
  isLoading: boolean;
  open: boolean;
  darkTheme: boolean;
  openNoteModal: boolean;
  anchorEl: null | HTMLElement;
  title: string;
  note: string;
  enableEdit: boolean;
  editId: string;
  editModal: boolean;
  deleteModal: boolean;
  deleteSuccessfulModal: boolean;
  rightMinimise: boolean;
  leftMinimise: boolean;
  startCourse: boolean;
  progress: number;
  unlockedChapterIndex: number;
  unlockedLessonIndex: number;
  unlockedSubTopicIndex: number;
  selectedChapterIndex: number;
  remainingTime: RemainingTime;
  isCompleted: CompletedStatus;
  currentChapterIndex: number; 
  currentLessonIndex: number; 
  duration: string,
  isMediaClicked: boolean,
  userRole: string;
  course_status: string;
  previewMode: boolean;
  statusColorObj: ColorObject;
  isApproved: boolean;
  isRejectedPopover: boolean;
  isRejected: boolean;
  course_name:string;
  course_category:string;
  instr_name:string;
  course_description: string;
  course_image:string;
  quiz_data:any;
  assigned_worker_id:number | null;
  feedback:string;
  assignModal : boolean,
  companiesNames: { "attributes": { [key: string]: responseData } }[];
  searchCompany:string,
  companyFilterData: { "attributes": { [key: string]: responseData} }[];
  companyName:string|number|undefined|boolean,
  companyId:string|number,
  groupData: any,
  groupDataFilter: { "attributes": { [key: string]: responseData } }[],
  searchGroup: string,
  showGroupModal: boolean,
  selectedGroups: (string|number|boolean)[],
  showSuperVisorModal: boolean,
  superVisorData: { [key: string]:responseData } [],
  superVisorDataFilter: { [key: string]: responseData } [],
  selectedSuperVisor: responseData[],
  searchSupervisor: string,
  showDoneModal: boolean,
  showAllSelectedGroups: boolean,
  isTest: boolean;
  worker_id:number | null;
  userId: string;
  courseId: string;
  chapters: Chapter[];
  upload_media: UploadedFile[];
  currentChapterId: string;
  currentLessonId: string;
  interacted: boolean;
  instructorId: number | null;
  isOnline: boolean;
}

interface SS {
  id: any;
}

export default class ProjectnotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getNotesListApiCallId: string = "";
  deleteNotesCallId: string = "";
  postProjectNotesAPIId:string="";
  getAllListNoteAPIId:string="";
  editProjectNotesAPIId:string="";
  getCourseApiCallId:string="";
  approveCourseApiCallId:string="";
  rejectCourseApiCallId:string="";
  deleteProjectNoteAPId:string="";
  getCompanyDetailsApiCallId:string="";
  getGroupsApiCallId:string="";
  getGroups1ApiCallId:string="";
  getSupervisorApiCallId:string="";
  askForReviewApiCallId:string="";
  markSubTopicCompletedApiCallId: string = "";
  quillRef:any;
  videoRef = createRef<HTMLVideoElement>();
  courseNameRef = createRef<HTMLDivElement>();
  audioRefs: (HTMLAudioElement | null)[] = [];
  webPing: ReturnType<typeof setInterval>;

  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.handleConnectionChange = this.handleConnectionChange.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      notesList: [],
      isLoading: false,
      open: false,
      darkTheme: false,
      openNoteModal: false,
      anchorEl: null,
      title:"",
      note:'',
      enableEdit: false,
      editId: "",
      editModal: false,
      deleteModal: false,
      deleteSuccessfulModal: false,
      rightMinimise: false,
      leftMinimise: false,
      startCourse: false,
      progress: 0,
      unlockedChapterIndex: 0,
      unlockedLessonIndex: 0,
      unlockedSubTopicIndex: 0,
      selectedChapterIndex: 0,
      remainingTime: {}, 
      currentChapterIndex: 0, 
      currentLessonIndex: 0,
      duration: "0h 00m",
      userRole: "worker",
      course_status: "",
      previewMode: false,
      statusColorObj: {
        color: "#000000",
        bgColor: "transparent",
      },
      isMediaClicked: false,
      isApproved:false,
      isRejectedPopover:false,
      isRejected:false,
      course_name: "",
      course_category: "",
      instr_name: "",
      course_description: "",
      course_image: "",
      quiz_data:[],
      assigned_worker_id: null,
      feedback: "",
      worker_id:null,
      userId: "",
      assignModal: false,
      companiesNames: [],
      searchCompany: "",
      companyFilterData: [],
      companyName: "",
      companyId: "",
      groupData: [], 
      groupDataFilter: [],
      showGroupModal: false,
      selectedGroups: [],
      showSuperVisorModal:false,
      superVisorData:[],
      superVisorDataFilter:[],
      selectedSuperVisor:[],
      searchGroup:"",
      searchSupervisor:"",
      showDoneModal:false,
      showAllSelectedGroups:false,
      courseId: "",
      chapters: [],
      upload_media: [],
      currentChapterId: "",
      currentLessonId: "",
      interacted: false,
      isTest: false,
      isCompleted: {},
      instructorId: null,
      isOnline: navigator.onLine,
    }
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: this.doButtonPressed,
  };

  doButtonPressed() {
    let mesg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    mesg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(mesg);
  }

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  async componentDidMount() {
    this.setState({ courseId: this.props.navigation.getParam("id") }, () => this.getCourse());
    const userRole = await getStorageData("user_role");
    this.setState({ userRole, userId: await getStorageData("user_role_id") })
    
    const previewMode = await getStorageData("previewMode")
    if (previewMode === "true") {
      this.setState({ previewMode: true })
    }

    if (!this.state.isOnline) {
      this.getSavedData();
    }

    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }
  async componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = async () => {
    await setStorageData("chapters", JSON.stringify(this.state.chapters));
    await setStorageData("isCompleted", JSON.stringify(this.state.isCompleted));
    await setStorageData("course_name", JSON.stringify(this.state.course_name));
    await setStorageData("course_category", JSON.stringify(this.state.course_category));
    await setStorageData("duration", JSON.stringify(this.state.duration));
    await setStorageData("instr_name", JSON.stringify(this.state.instr_name));
    await setStorageData("course_description", JSON.stringify(this.state.course_description));
    await setStorageData("upload_media", JSON.stringify(this.state.upload_media));

    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      this.setState({ isOnline: true });
      this.webPing = setInterval(
        async () => {

        }, 2000);
    } else {
      this.setState({ isOnline: false });
    }
  }
  getSavedFieldData = async (fieldName: string) => {
    const exampleData = await getStorageData(fieldName);

    return JSON.parse(exampleData);
  };
  getSavedData = async () => {
    const chapters = await this.getSavedFieldData("chapters");
    const isCompleted = await this.getSavedFieldData("isCompleted");
    const course_name = await this.getSavedFieldData("course_name");
    const course_category = await this.getSavedFieldData("course_category");
    const duration = await this.getSavedFieldData("duration");
    const instr_name = await this.getSavedFieldData("instr_name");
    const course_description = await this.getSavedFieldData("course_description");
    const upload_media = await this.getSavedFieldData("upload_media");

    this.setState({ 
      chapters, 
      isCompleted,
      course_name, 
      course_category, 
      duration, 
      instr_name, 
      course_description, 
      upload_media 
    });
  };
  handleChapterChange = (valueObj: OnChangeProps) => {
    if (valueObj.label === "Final Test") {
      this.callTestScoring();
    } else {
      const optionIndex = this.state.chapters.findIndex(chapter => chapter.id === valueObj.value) || 0;
      this.setState({ 
        isTest: false,
        currentChapterId: valueObj.value, 
        currentChapterIndex: optionIndex, 
        unlockedLessonIndex: 0,
        currentLessonIndex: 0, 
        unlockedSubTopicIndex: 0, 
        selectedChapterIndex: 0 
      }, () => this.startSubtopicTimer());
    }
    
  } 
  handleLessonChange = (valueObj: OnChangeProps) => {
    const lessonList = this.state.chapters[this.state.currentChapterIndex]?.lessons || [];
    const optionIndex = lessonList.findIndex(lesson => lesson.id === valueObj.value) || 0;

    this.setState({ 
      currentLessonId: valueObj.value, 
      currentLessonIndex: optionIndex,
      unlockedSubTopicIndex: 0,
      selectedChapterIndex: 0 
    }, () => this.startSubtopicTimer());
  }
  handleSubTopicClick = (index : number) => {
    if (index <= this.state.unlockedSubTopicIndex || this.state.isCompleted[`${this.state.currentChapterIndex}${this.state.currentLessonIndex}${index}`]) {
      this.setState({
        selectedChapterIndex: index,
      }, () => this.startSubtopicTimer());
    }
  };
  handleUnlockNextLessonOrChapter = () => {
    const { currentChapterIndex, currentLessonIndex, selectedChapterIndex: currentSubTopicIndex } = this.state;
    const chapterList = this.state.chapters;
    const lessonList = chapterList[currentChapterIndex]?.lessons || [];
    const subTopicList = lessonList[currentLessonIndex]?.subTopics || [];

    if (currentSubTopicIndex === (subTopicList.length - 1)) {
      this.setState(prevState => {
        this.markCompleted({ 
          courseId: prevState.courseId,
          chapterId: chapterList[prevState.currentChapterIndex].id,
          lessonId: lessonList[prevState.currentLessonIndex].id,
          assignedId: prevState.assigned_worker_id?.toString() || ""
        });
        return {
          unlockedLessonIndex: prevState.unlockedLessonIndex + 1,
        }
      }, () => {
        if (currentLessonIndex === (lessonList.length - 1)) {
          this.setState(prev => ({
            unlockedChapterIndex: prev.unlockedChapterIndex + 1,
          }))
        }
      })
    }
  }
  handleUnlockNextLessonOnCompletingCurr = () => {
    const currentSubTopicIndex = this.state.selectedChapterIndex;

    this.setState((prevState) => {
      const nextUnlockedIndex = currentSubTopicIndex + 1;
      
      return {
        unlockedSubTopicIndex: Math.max(prevState.unlockedSubTopicIndex, nextUnlockedIndex),
      };
    }, this.handleUnlockNextLessonOrChapter);
  }
  startSubtopicTimer = () => {
    const currentChapterIndex = this.state.currentChapterIndex;
    const currentLessonIndex = this.state.currentLessonIndex;
    const currentSubTopicIndex = this.state.selectedChapterIndex;
    const subTopic = this.state.chapters[currentChapterIndex]?.lessons[currentLessonIndex]?.subTopics[currentSubTopicIndex];
    const subTopicTimeInSecs = parseInt(subTopic?.lesson_timer?.split(":")[0] || "0") * 60 + parseInt(subTopic?.lesson_timer?.split(":")[1] || "0");

    if (subTopicTimeInSecs > 0) {
      this.setState((prevState) => ({
        remainingTime: {
          ...prevState.remainingTime,
          [currentSubTopicIndex]: 
            !prevState.isCompleted[`${prevState.currentChapterIndex}${prevState.currentLessonIndex}${prevState.selectedChapterIndex}`] ? subTopicTimeInSecs : 0, 
        },
      }));

      if (!this.state.isCompleted[`${currentChapterIndex}${currentLessonIndex}${currentSubTopicIndex}`] && (subTopic.lesson_type === null || subTopic.lesson_type === "text" || subTopic.lesson_type === "multimedia" 
        || subTopic.lesson_type === "presentation" || subTopic.lesson_type === "interactive_traninng" || subTopic.lesson_type === "image")) {
        const interval = setInterval(() => {
          this.setState((prevState) => {
            const currentTime = prevState.remainingTime[currentSubTopicIndex] - 1;
            if (currentTime <= 0) {
              clearInterval(interval);
              return {
                remainingTime: {
                  ...prevState.remainingTime,
                  [currentSubTopicIndex]: 0, 
                },
                isCompleted: {
                  ...prevState.isCompleted,
                  [`${currentChapterIndex}${currentLessonIndex}${currentSubTopicIndex}`]: true,
                },
              };
            }
        
            return {        
              remainingTime: {
                ...prevState.remainingTime,
                [currentSubTopicIndex]: currentTime,
              },
              isCompleted: { ...prevState.isCompleted },
            };
          });
        }, 1000);
      
        setTimeout(this.handleUnlockNextLessonOnCompletingCurr, subTopicTimeInSecs * 1000);
      }
    }
  };
  isLastSubTopic = () => {
    const { currentChapterIndex, currentLessonIndex, selectedChapterIndex: currentSubTopicIndex, chapters } = this.state;
    const lessons = chapters[currentChapterIndex]?.lessons || [];
    const subTopics = lessons[currentLessonIndex]?.subTopics || [];

    return currentSubTopicIndex === subTopics.length - 1;
  }
  isSubTopicCompleted = () => {
    const { currentChapterIndex, currentLessonIndex, selectedChapterIndex: currentSubTopicIndex, isCompleted } = this.state;
    return isCompleted[`${currentChapterIndex}${currentLessonIndex}${currentSubTopicIndex}`];
  }
  isLastLesson = () => {
    const { currentChapterIndex, currentLessonIndex, chapters } = this.state;
    const lessons = chapters[currentChapterIndex].lessons;

    return currentLessonIndex === lessons.length - 1;
  }
  showNotesModalUpdateState = (item: ItemData) => {
    this.props.navigation.navigate("NewProjectNotes", { data: item })
  }

  showNotesModalSetState = () => {
    this.props.navigation.navigate("NewProjectNotes")
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)

      )

      if (errorReponse || responseJson.error) {
        this.handleErrorResponse()
      }

      if (responseJson && responseJson.data) {

        await this.handleResponseData(apiRequestCallId, responseJson);
      }        

      this.handleOtherResponse(apiRequestCallId);

      if(apiRequestCallId===this.getSupervisorApiCallId){
        this.setState({superVisorData:responseJson.supervisors,superVisorDataFilter:responseJson.supervisors})
      }
      if (apiRequestCallId === this.markSubTopicCompletedApiCallId) {
        this.setState({ progress: responseJson.progress ? responseJson.progress?.split(" ")[0] : 0 });
      }
    }
  }

  handleErrorResponse = () => {
    if (!this.state.isOnline) {
      this.setState({ isLoading: false });
    }
  }
  handleFetchingCourse = (courseData: any) => {
    const assignedWorkers = courseData.assigned_workers;
    if (this.state.userRole === "worker" && !assignedWorkers.some((worker: any) => Number(worker.profile_id) === Number(this.state.userId))) {
      this.props.navigation.navigate("Dashboard");
    }

    const matchedWorker = assignedWorkers.find(
      (worker:any) => Number(worker.profile_id) === Number(this.state.userId)
    );

    this.setState({
      course_name:courseData.course_name,
      course_status:courseData.status,
      statusColorObj: courseData.status_colors,
      course_category:courseData.category_name,
      instructorId: courseData.profile_id,
      instr_name:courseData.instructor,
      course_description: courseData.course_description,
      course_image:courseData.image,
      chapters: courseData.chapters.data.map((chapter: any) => ({
        id: chapter.id,
        title: chapter.attributes.title,
        lessons: chapter.attributes.lessons.data.map((lesson: any) => ({
          id: lesson.id,
          title: lesson.attributes.title,
          subTopics: lesson.attributes.sub_lessons.data.map((subLesson: any) => ({
            id: subLesson.id,
            title: subLesson.attributes.title,
            completed: subLesson.attributes.completed,
            lesson_type: subLesson.attributes.lesson_type || "quiz",
            lesson_timer: subLesson.attributes.lesson_timer,
            files: subLesson.attributes.files,
            quiz_data:subLesson.attributes.quiz || [],
            content: subLesson.attributes.content || "N/A",
          }))
        }))
      })),
      assigned_worker_id: matchedWorker ? matchedWorker.id : null,
      upload_media: courseData.upload_media.data.length > 0 
        ? courseData.upload_media.data[0]?.attributes.media_and_documents_urls.map((file: Omit<UploadedFile, "filename"> & { name: string }) => ({
          ...file,
          filename: file.name
        })) : [],
      progress: courseData.progress || 0
    }, async () => {
      if (this.state.userRole !== "worker") {
        if (this.state.userRole === "instructor" && parseInt(this.state.userId) !== this.state.instructorId) {
          this.props.navigation.navigate("Dashboard");
        }
        this.setState({ startCourse: true }, () => this.startSubtopicTimer());
      }
      this.setState({ duration: this.calculateDuration() });
      await this.downloadAllBlobData();
    });
  }
  handleResponseData = async (apiRequestCallId: string, responseJson: { data: any }) => {
    if (apiRequestCallId === this.getCourseApiCallId) {
      this.handleFetchingCourse(responseJson.data.attributes);
    }
    if(apiRequestCallId===this.getCompanyDetailsApiCallId){
      this.setState({companiesNames:responseJson.data})
    }
    if(apiRequestCallId===this.getGroupsApiCallId){
      this.setState({groupData:responseJson.data,groupDataFilter:responseJson.data})
    }
  }
  handleOtherResponse = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.approveCourseApiCallId) {
      this.setState(
        { isApproved: true },
        () => {
          setTimeout(() => {
            this.setState({ isApproved: false });
          }, 2000);
        }
      );
      this.getCourse()
    }
    if (apiRequestCallId === this.rejectCourseApiCallId) {
      this.getCourse();
        this.setState(
          {
            isRejected: false,
            isRejectedPopover: true,
          },
          () => {
            setTimeout(() => {
              this.setState({ isRejectedPopover: false });
            }, 2000);
          }
        );
    }  
  }


  downloadAllBlobData = async () => {
    await Promise.all(this.state.chapters.map(async chapter => {
      await Promise.all(chapter.lessons.map(async lesson => {
        await Promise.all(lesson.subTopics.map(async subTopic => {
          if (!subTopic.files || !subTopic?.files?.length || subTopic?.files?.length <= 0) {
            return;
          }
          await Promise.all(subTopic.files?.map(async file => {
            await downloadFileFromUrl(file.id, file.url);
          }))
        }))
      }))
    }))
  }
  handleClose=()=>{
    if(this.state.assignModal===true
      &&this.state.showGroupModal===false
      &&this.state.showSuperVisorModal===false){
      this.setState({assignModal:false,companyName:"",searchCompany:""})
    }else if(this.state.showGroupModal===true){
      this.setState({showGroupModal:false,selectedGroups:[]})
    }else if(this.state.showSuperVisorModal===true){
      this.setState({showSuperVisorModal:false})
    }
  }
  handleStartCourseClick = () => {
    this.setState({ startCourse: !this.state.startCourse }, () => {
      this.startSubtopicTimer();
    })
  }
  getAllCompanies = async () => {
    const token = await getStorageData('authToken');
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCompanyDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/company_list`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getGroups = async () => {
    const token = await  getStorageData('authToken');
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/group_list?id=${this.state.companyId}`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  companySearch=(event: any) => {
    const value = event.target.value.toLowerCase();
    this.setState({ searchCompany: value }, () => {
      const filtered = this.state.companiesNames.filter((company: any) =>
        company.attributes.company_name.toLowerCase().includes(value)
      );
      this.setState({ companyFilterData: filtered });
    });
  
  };
  groupSearch=(event:any)=>{
    const value=event.target.value.toLowerCase()
    this.setState({ searchGroup: value }, () => {
      const filtered = this.state.groupData.filter((company: any) =>
        company.attributes.group_name.toLowerCase().includes(value)
      );
      this.setState({ groupDataFilter: filtered });
    });
  }
  askForReview=()=>{
  this.setState({showDoneModal:true,showGroupModal:false,showSuperVisorModal:false})
  this.createTask();
  }
  createTask=async ()=>{
    let token = await getStorageData('authToken')
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const httpBody = {
        "task": {
            "title": "Review Course Content",
            "description": "Click on the course to view content and review it",
            "due_date": null,
            "task_lists_attributes": [],
            "status": "in_progress",
            "is_important": false,
            "is_assigned": true,
            "assign_ids": this.state.selectedSuperVisor,
            "course_id":localStorage.getItem("courseId"),
            company_ids:[this.state.companyId]
        }
    
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.askForReviewApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDone=()=>{
    this.setState({showDoneModal:false,assignModal:false,companyName:"",companyId:"",selectedGroups:[],selectedSuperVisor:[]})
  }
  superVisorSearch=(event:any)=>{
    const value=event.target.value.toLowerCase()
    this.setState({ searchSupervisor: value }, () => {
      const filtered = this.state.superVisorData.filter((company: any) =>
        company.first_name.toLowerCase().includes(value)
      );
      this.setState({ superVisorDataFilter: filtered });
    });
  }
  showSuperVisorModal=()=>{
    this.showSuperVisor();
    this.setState({showSuperVisorModal:true,showGroupModal:false})
  }
  showSuperVisor=async ()=>{
    const token = await  getStorageData('authToken');
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSupervisorApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/courses/supervisors?company_id=${this.state.companyId}`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSelectCompany=(event:any)=>{
    const selectedCompanyId = event.target.value;
    const selectedCompany = this.state.companiesNames.find(
      (company) => company.attributes.id === selectedCompanyId
    );
  
   
    if (selectedCompany) {
      this.setState({
        companyName: selectedCompany.attributes.company_name, 
      });
    }
    this.setState({companyId:selectedCompanyId})
  }
  selectCompany=(id:any)=>{
    const selectedCompanyId = id;
    const selectedCompany = this.state.companiesNames.find(
      (company) => company.attributes.id === selectedCompanyId
    );
  
   
    if (selectedCompany) {
      this.setState({
        companyName: selectedCompany.attributes.company_name, 
        searchCompany:String(selectedCompany.attributes.company_name)
      });
    }
    this.setState({companyId:selectedCompanyId})
  }
  showGroups=()=>{
    this.getGroups()
    this.setState({showGroupModal:true})
  }
  handleSelectGroup = (selectedId:any) => {
    this.setState((prevState) => {
      let selectedGroups1 = [...prevState.selectedGroups];
      if (selectedGroups1.includes(selectedId)) {
        selectedGroups1 = selectedGroups1.filter(id => id !== selectedId);
      } else {         
        selectedGroups1.push(selectedId);
      }
      return { selectedGroups: selectedGroups1 };
    }, () => {

    });
  };
  handleSelectSuperVisor= (selectedId:any) => {
    this.setState((prevState) => {
      let selectedSuperVisor1 = [...prevState.selectedSuperVisor];
      if (selectedSuperVisor1.includes(selectedId)) {
        selectedSuperVisor1 = selectedSuperVisor1.filter(id => id !== selectedId);
      } else {
        selectedSuperVisor1.push(selectedId);
      }
      return { selectedSuperVisor: selectedSuperVisor1 };
    }, () => {});
  };
  handleAssgingroup=async()=>{
   this.setState({showAllSelectedGroups:true})
    const courseId=await localStorage.getItem("courseId")
    const token = await  getStorageData('authToken');
    const header = {
      "Content-Type": "application/json",
      "Accept": "*/*",
      token
    };
    
    const httpBody = {
      group_ids:this.state.selectedGroups
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroups1ApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/courses/${courseId}/submit_approval`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
   'PUT'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  showNotesModalDeleteNotes = async (item: ItemData) => {
    const noteId = item.attributes.id;
    const bodyData = {
      reference_id: 1
    }
    this.deleteNotesCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: "DELETE",
      endPoint: `bx_block_projectnotes/notes/${noteId}`,
      body: bodyData
    });
  }
  markCompleted = async ({ courseId, chapterId, lessonId, assignedId }: { courseId: string; chapterId: string; lessonId: string; assignedId: string }) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.markSubTopicCompletedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markSubTopicCompletedEndPoint}?course_id=${courseId}&chapter_id=${chapterId}
        &lesson_id=${lessonId}&student_course_assignment_id=${assignedId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMenu=()=>{
    this.setState({open:!this.state.open});
    localStorage.setItem('open',JSON.stringify(!this.state.open));
  }
  
  handleTheme=()=>{
    this.setState({darkTheme:!this.state.darkTheme})
  }

  handleEditModal=()=>{
    this.setState({
      editModal:true,
    })
  }

  handleDeleteModal=(noteId:string)=>{
    this.setState({
      deleteModal:true,
      editId:noteId
    })
  }
  closeDeleteModal=()=>{
    this.setState({
      deleteModal:false,
      deleteSuccessfulModal:true
    })
  }

  editPost=(note:any)=>{
    this.setState({
      title:note.attributes.title,
      note:note.attributes.note_content,
      editId:note.attributes.id,
      openNoteModal:true,
      enableEdit:true
    });
  }

  calculateDuration = () => {
    const durationInSecs = this.state.chapters.reduce(
      (totalTime, currentChapter) => totalTime + currentChapter.lessons.reduce(
        (totalTime, currentLesson) => totalTime + currentLesson.subTopics.reduce(
          (totalTime, currentSubtopic) => 
            totalTime + parseInt(currentSubtopic.lesson_timer?.split(":")[0] || "0") * 60 + parseInt(currentSubtopic.lesson_timer?.split(":")[1] || "0"),
          0 
        ),
        0
      ),
      0
    );
    const hours  = Math.floor(durationInSecs / 3600);
    const minutes = Math.floor((durationInSecs - (hours * 3600)) / 60);

    return `${hours}h ${minutes > 10 ? minutes : "0" + minutes}m`;
  }
  formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${formattedSeconds}`;
  };

  handleEnableNextLesson = () => {
    const { currentChapterIndex, currentLessonIndex, selectedChapterIndex: currentSubTopicIndex } = this.state;

    this.setState((prevState) => {
      return {
        unlockedSubTopicIndex: Math.max(
          prevState.unlockedSubTopicIndex,
          prevState.selectedChapterIndex + 1
        ), 
        isCompleted: {
          ...prevState.isCompleted,
          [`${currentChapterIndex}${currentLessonIndex}${currentSubTopicIndex}`]: true,
        }
      };
    }, this.handleUnlockNextLessonOrChapter);
  }

  handleMediaOpen = () => {
    this.setState({ isMediaClicked: true });
  }
  handleAboutOpen = () => {
    this.setState({ isMediaClicked: false });
  }
  handlePreviousChapter = () => {
    this.setState((prevState) => ({
      currentChapterIndex: Math.max(prevState.currentChapterIndex - 1, 0),
    }));
  };
  handlePreviousLesson = () => {
    this.setState((prevState) => ({
      currentLessonIndex: Math.max(prevState.currentLessonIndex - 1, 0),
    }));
  };
  handleNextLesson = () => {
    this.setState((prevState) => ({
      currentLessonIndex: Math.min(
        prevState.currentLessonIndex + 1,
        prevState.chapters.length - 1
      ),
      unlockedSubTopicIndex: 0,
      selectedChapterIndex: 0
    }));
  };

  getSelectionBoxStyle = (id: any) => {
    const isSelected = this.state.selectedSuperVisor.includes(id);
    return {
      width: isSelected ? "24px" : "16.67px",
      height: isSelected ? "24px" : "16.67px",
      borderRadius: "7.2px",
      background: isSelected ? "#18408E" : "transparent",
      border: isSelected ? "none" : "1.2px solid #18408E",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "all 0.3s ease-in-out",
      cursor: "pointer",
    };
  };
  getGroupSelectionStyle = (id: any) => {
    const isSelected = this.state.selectedGroups.includes(id);
    return {
      width: isSelected ? "24px" : "16.67px",
      height: isSelected ? "24px" : "16.67px",borderRadius: "7.2px",
      background: isSelected ? "#18408E" : "transparent",
      border: isSelected ? "none" : "1.2px solid #18408E",
      display: "flex",alignItems: "center",
      justifyContent: "center",
      transition: "all 0.3s ease-in-out",
      cursor: "pointer",
    };
  };
  
  goToPostCreationPage = async () => {
    await setStorageData("courseId", this.state.courseId);
    await removeStorageData("step");
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'PostCreation');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  showText=()=>{
    if(this.state.showSuperVisorModal){
      return "Review Course"
    }else{
      return "Assign Course"
    }
  }
  assignModal =()=> {
    this.getAllCompanies();
    this.setState({assignModal:true})
  }

  getCourse = async()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCourseApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCourseDataAPIEndpoint}/${this.state.courseId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleFeedBack = async () => {
    await setStorageData("courseId", this.state.courseId);
    await setStorageData("step", 4);
    this.props.navigation.navigate("PostCreation");
  }
  approveCourse = async () => {
    const courseId = this.props.navigation.getParam("id")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.approveCourseApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCourseDataAPIEndpoint}/${courseId}/submit_approval`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateNoteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClosePopover = () =>{
    this.setState({isApproved:false})
  }

  rejectCourse = () =>{
    this.setState({isRejected:true})
  }

  handleCloseReject = () =>{
    this.setState({isRejected:false})

  }
  
  submitFeedback = async () => {
    const courseId = this.props.navigation.getParam("id")

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestData = {
        message: this.state.feedback,
        course_id:courseId,
    };

    const httpBody = {
      feedback: requestData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.rejectCourseApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postFeedbackAPIEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addFeedbackText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ feedback: event.target.value.slice(0, 500) });
  };  

  callTestScoring = async () =>{
    await setStorageData("testType", "final_test")
    this.setState({isTest:true})
  }
  getCurrentChapter = (): Chapter => this.state.chapters[this.state.currentChapterIndex] ?? { title: "Chapter Name", lessons: [] };
  getCurrentLesson = (): Lesson => this.getCurrentChapter().lessons[this.state.currentLessonIndex] ?? { title: "Lesson Name", subTopics: [] };
  getCurrentSubTopic = (): SubTopic => this.getCurrentLesson().subTopics[this.state.selectedChapterIndex] ?? { title: "Currently Selected Lesson Type Name" };

  getSelectedValueObj = (type: "chapter" | "lesson", options: { value: string; label: string }[]) => {
    if (this.state.isTest) {
      return { value: options.length.toString(), label: type === "chapter" ? "Final Test" : "Lesson Name"};
    }
    const currentIndex = type === "chapter" ? this.state.currentChapterIndex : this.state.currentLessonIndex;
    const typeLabel = type === "chapter" ? "Chapter" : "Lesson";
    const value = options.length > 0 ? options[currentIndex].value : "-1";
    const label = options.length > 0 ? options[currentIndex].label : `${options.length} ${typeLabel}`;
    return { value, label }
  }
  getSubTopicMenuItemStyle = (subTopicIndex: number): CSSProperties => {
    const { isCompleted, currentChapterIndex, currentLessonIndex, unlockedSubTopicIndex } = this.state;
    if (isCompleted[`${currentChapterIndex}${currentLessonIndex}${subTopicIndex}`] || subTopicIndex <= unlockedSubTopicIndex) {
      return { cursor: "pointer", opacity: 1 }
    }
    return { cursor: "not-allowed", opacity: 0.5 }
  }
}
// Customizable Area End