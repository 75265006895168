import PropTypes from "prop-types";
import React, { Component, CSSProperties, forwardRef, ReactNode, useState } from "react";
import { Platform, Switch } from "react-native";
import CheckBox from "@react-native-community/checkbox";
import { CheckboxProps, styled, Checkbox as MuiCheckbox } from "@material-ui/core";

const checkboxIcon = require("../../blocks/assessmenttest/assets/checkbox-icon.svg");
const checkboxCheckedIcon = require("../../blocks/assessmenttest/assets/checkbox-checked-icon.svg");
const disabledCheckboxIcon = require("../../blocks/assessmenttest/assets/disabled-checkbox-icon.svg");
const disabledCheckboxCheckedIcon = require("../../blocks/assessmenttest/assets/disabled-checkbox-checked-icon.svg");

type MyProps = {
  testID: string;
  isChecked: boolean;
  onChangeValue?: (value: boolean) => void;
};
type MyState = { isChecked: boolean; onChangeValue?: (value: boolean) => void };

export default class CustomCheckBox extends Component<MyProps, MyState> {
  static propTypes = {
    testID: PropTypes.string,
    isChecked: PropTypes.bool.isRequired,
    onChangeValue: PropTypes.func.isRequired,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      isChecked: this.props.isChecked,
    };
  }

  render() {
    const { testID } = this.props;

    if (Platform.OS === "ios") {
      return (
        <Switch
          testID={testID} 
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ marginRight: 5, marginLeft: 5 }}
          value={this.state.isChecked}
          onValueChange={(value) => this.handleValueChange(value)}
        />
      );
    } else {
      return (
        <CheckBox
          testID={testID}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            marginRight: Platform.OS === "web" ? 5 : 0,
            marginLeft: Platform.OS === "web" ? 5 : 0,
          }}
          value={this.state.isChecked}
          onValueChange={(value: boolean) => this.handleValueChange(value)}
        />
      );
    }
  }

  componentWillReceiveProps(nextProps: any) {
    if (true) {
      this.setState({
        isChecked: nextProps.isChecked,
      });
    }
  }

  handleValueChange(value: boolean) {
    this.setState({
      isChecked: value,
    });
    if (this.props.onChangeValue) {
      this.props.onChangeValue(value);
    }
  }
}

type ICheckboxProps = {
  label?: ReactNode;
  ariaLabel?: string;
  bgColor?: string;
  style?: CSSProperties;
}

export const Checkbox = forwardRef<any, ICheckboxProps & CheckboxProps>(({
  label,
  ariaLabel,
  checked,
  disabled,
  indeterminate,
  ...rest
}, ref) => {
  const [isChecked, setIsChecked] = useState(checked || false)

  return (
      <Container>
        <CustomCheckbox
          aria-label={ariaLabel}
          ref={ref}
          aria-checked={indeterminate ? "mixed" : isChecked}
          checked={isChecked}
          icon={<CustomIcon src={disabled ? disabledCheckboxIcon : checkboxIcon} />}
          checkedIcon={<CustomIcon src={disabled ? disabledCheckboxCheckedIcon : checkboxCheckedIcon} />}
          disabled={disabled}
          disableRipple
          color="default"
          onChange={event => setIsChecked(event.target.checked)}
          {...rest}
        />
        {label}
      </Container>
  )
})

const Container = styled('label')({
  padding: 0,
  display: "flex",
  alignItems: "center",
  gap: "8px",
  fontFamily: "Rubik",
  lineHeight: "22px",
  color: "#475569",
  cursor: "pointer",
})
const CustomCheckbox = styled(MuiCheckbox)({
  color: "#1A469C",
  padding: 0,
})
const CustomIcon = styled('img')({
  width: "20px",
  height: "20px",
  objectFit: "contain",
  objectPosition: "center"
})