import React, { CSSProperties } from "react";
import { Box, Dialog, DialogActions, DialogTitle, styled, Typography } from "@material-ui/core";
import Button from "./Button";

type IModalProps = | {
    open: boolean;
    onClose: () => void;
    style?: CSSProperties;
    title?: string;
    contentProps?: {
        imageUrl?: string;
        text: string;
        subText?: string;
    }
    confirmText: string;
    confirmable?: false;
} | {
    open: boolean;
    onClose: () => void;
    style?: CSSProperties;
    title?: string;
    contentProps?: {
        imageUrl?: string;
        text: string;
        subText?: string;
    }
    confirmText: string;
    confirmable: true;
    onConfirm: () => void;
};

const Modal = ({
    title,
    contentProps,
    confirmText,
    open,
    onClose,
    style,
    ...rest
}: IModalProps) => {

    return (
        <ModalRoot 
            onClose={onClose} 
            aria-labelledby="common-modal" 
            open={open}
            style={style}
        >
            {title && <DialogTitle id="common-dialog-title">{title}</DialogTitle>}
            {contentProps && (
                <ColStack style={{ padding: '30px', justifyContent: "center", alignItems: 'center' }}>
                    {contentProps?.imageUrl && <img 
                        src={contentProps?.imageUrl} 
                        alt="Modal image" 
                        style={{ height: '176px', width: '275px' }}
                    />}
                    <ModalContentText>{contentProps?.text}</ModalContentText>
                    {contentProps?.subText && <ModalContentSubText>{contentProps?.subText}</ModalContentSubText>}
               </ColStack>
            )}
            <ModalActions>
                {rest.confirmable
                    ? (
                        <>
                            <CustomButton onClick={rest.onConfirm}>
                                {confirmText}
                            </CustomButton>
                            <CancelButton onClick={onClose}>
                                Cancel
                            </CancelButton>
                        </>
                    )
                    : (
                        <CustomButton onClick={onClose}>
                            {confirmText}
                        </CustomButton>
                    )
                }
            </ModalActions>
        </ModalRoot>
    )
}

export default Modal;

const ColStack = styled(Box)({ display: 'flex', flexDirection: 'column' });

const ModalRoot = styled(Dialog)({
    minWidth: "645px",
    borderRadius: 30,
    border: "1px solid #FFF",
    boxShadow: "0 8px 32px 0 #0000000F, 0 4px 8px 0 #00000008",
    "& .MuiBackdrop-root": { backgroundColor: "#0F172A", opacity: "40% !important" }, 
    "& .MuiDialog-paper": { borderRadius: "30px", backgroundColor: "#F9F9F9" },
    "& .MuiDialog-paperWidthSm": { minWidth: "645px" }
});

const ModalContentText = styled(Typography)({
    fontFamily: "Rubik",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "40px",
    color: "#0F172A",
    textAlign: "center"
})

const ModalContentSubText = styled(ModalContentText)({
    fontWeight: 400,
})

const ModalActions = styled(DialogActions)({
    padding: "2px 30px 30px",
    flexDirection: "column",
    gap: 10,
    "& .MuiDialogActions-spacing > :not(:first-child)": { marginLeft: 0 },
})

const CustomButton = styled(Button)({
    width: "100%",
    borderRadius: 30
})

const CancelButton = styled(CustomButton)({
    backgroundColor: "#D7D7D780 !important", 
    color: "#0B1D42 !important",
    "&:hover": { color: "#FFF !important" }
})