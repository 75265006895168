import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Avatar,
  ButtonGroup,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Input,
  InputAdornment,
  Snackbar,
  Paper
} from "@material-ui/core";
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import { styled, ThemeProvider } from "@material-ui/core/styles";
import { notify_button, doneAll, deleteIcon, checkIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { Alert, Pagination } from "@material-ui/lab";
import { FieldModal, ModalHeading, ModalLine, ModalSubHead } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CardSection = () => (
    <CardHeader>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
        <Badge color='secondary' variant="dot" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
          <Avatar alt="Remy Sharp" />
        </Badge>
        <div style={{ marginLeft: '10px' }}>
          <NameField>Ibrahim M.</NameField>
          <DesignationField>Instructor</DesignationField>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CourseTitle>Yesterday at 4:54 PM</CourseTitle>
        <DetailsButton variant="text" data-test-id="ReplyButton" onClick={this.openReplyModal}>Reply</DetailsButton>
        <MoreVertOutlinedIcon />
      </div>
    </CardHeader>
  )

  CommentCard = () => (
    <CommentMessageCard>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt="Remy Sharp" src="" />
        <div style={{ marginLeft: '10px' }}>
          <NameField>Ibrahim M.</NameField>
          <DesignationField>Instructor</DesignationField>
        </div>
      </div>
      <CourseValue>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</CourseValue>
      <CourseTitle style={{ textAlign: 'right' }}>Yesterday at 4:54 PM</CourseTitle>
    </CommentMessageCard>
  )

  ReplyModal = () => (
    <FieldModal
      data-test-id='replyModal'
      open={this.state.replyModal}
      onCancel={this.closeReplyModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">Message Notification</ModalHeading>
          <ModalLine style={{ width: '15%' }} />
          <ModalSubHead variant="subtitle2">Reply to Message</ModalSubHead>
          <ModalLine style={{ width: '15%' }} />
        </div>}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={772}
      style={{ paddingBottom: '40px' }}
    >
      <div className="scrollModal">
        {this.CommentCard()}
        {this.CommentCard()}
        {this.CommentCard()}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CommentField
          data-test-id="replyText"
          placeholder="Type your reply"
          fullWidth
          value={this.state.commentMessage}
          onChange={this.changeCommentMessage}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
              >
                <AttachmentOutlinedIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <SendOutlinedIcon />
      </div>
    </FieldModal>
  )

  successIcon = () => {
    return <img src={checkIcon} />
  }

  showSnackbar = () => {
    return (
      <CustomizeSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={this.state.snackBarOpen}
        onClose={this.handleClose}
        autoHideDuration={5000}
        key={'bottomright'}>
        <Alert
          onClose={this.handleClose}
          severity="success"
          variant="standard"
          style={{ width: '100%' }}
          icon={this.successIcon()}
        >
          Your notification Deleted Successfully .
        </Alert>
      </CustomizeSnackbar>
    )
  }

  renderPageSize = () => {
    const options = [10, 25, 50, 100];
    return (
      <Dropdown style={{ height: "160px", width: '55px', zIndex: 100, top: -225, right: 0 }} data-test-id="page-size-dropdown">
        {options.map((option) => (
          <SortText
            key={option}
            data-test-id="sortText"
            onClick={() => this.handlePageSizeChange(option)}
            style={{
              borderRadius: '8px',
              backgroundColor: this.state.selectedPageSize === option ? "#E8EDF5" : "",
              color: this.state.selectedPageSize === option ? "#1A469C" : "#475569",
            }}
          >
            {option}
          </SortText>
        ))}
      </Dropdown>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.state.darkThemeStatus ? darkTheme : lightTheme}>
        <Loader loading={this.state.isLoading} />
        <NavigationMenu navigation={this.props.navigation} id={""} open={this.state.open} theme={this.state.darkThemeStatus}/>
        <HamburgerMenu
          navigation={this.props.navigation}
          handleMenu={this.toggleSideMenu}
          open={this.state.menuStatus}
          themeDark={this.state.darkThemeStatus}
          handleTheme={this.toggleDarkTheme}
        />
        <MainContainer>
        <UpperDiv>
          <HeadDiv >
            <BodyHeader>
              <NotificationTitle>{configJSON.notifications}</NotificationTitle>
              <FlexBox>
                <ButtonGroup>
                  <Button
                    data-test-id='showAllNotification'
                    variant="text"
                    onClick={() => this.showUnreadNotification(false)}
                    className={!this.state.unread ? "selectedButton" : ""}
                  >
                    All
                  </Button>
                  <Button
                    data-test-id='showUnreadNotification'
                    variant="text"
                    onClick={() => this.showUnreadNotification(true)}
                    className={this.state.unread ? "selectedButton" : ""}
                  >
                    Unread
                  </Button>
                </ButtonGroup>
                <NotificationSettingsButton data-test-id="gotoSettings" startIcon={<img src={notify_button} />} onClick={this.goToNotificationSettings}>
                  Notification Settings
                </NotificationSettingsButton>
              </FlexBox>
            </BodyHeader>
            <Grid container>
              {this.showSnackbar()}
                {this.state.data?.map((item) => (
              <Grid item xs={6}>
                  <CardSection>
                    <CardHeader>
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                        <Badge invisible={item.attributes.is_read} color='secondary' variant="dot" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                          <Avatar alt="Sharp" src={item.attributes.created_by?.data?.attributes?.image} />
                        </Badge>
                        <div style={{ marginLeft: '10px' }}>
                          <NameField>{item.attributes.created_by?.data?.attributes?.first_name || "Ibrahim"}</NameField>
                          <DesignationField>{item.attributes.created_by?.data?.attributes?.role_id || "Educator"}</DesignationField>
                        </div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <CourseTitle>{this.timeSince(item.attributes.created_at)}</CourseTitle>
                        <DotSection>
                          <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={(event) => this.handleOpenNavMenu(event, item.attributes.id)}
                            color="inherit"
                            data-test-id="openOption"
                          >
                            <MoreVertOutlinedIcon />
                          </IconButton>
                          <CusomizeMenu
                            id="menu-appbar"
                            anchorEl={this.state.anchorElNav}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(this.state.anchorElNav)}
                            onClose={this.handleCloseNavMenu}
                            elevation={0}
                            style={{ top: 30 }}
                          >
                            <MenuItem data-test-id='markAsRead' onClick={() => this.markAsRead(item.attributes.id)}>
                              <img height={16} width={16} src={doneAll} style={{ marginRight: 10 }} />
                              <Typography style={{ fontSize: 12, color: '#1A469C' }}>{configJSON.markAsReadText}</Typography>
                            </MenuItem>

                            <MenuItem data-test-id='deleteNotification' onClick={() => this.deleteNotifications(item.attributes.id)}>
                              <img height={16} width={16} src={deleteIcon} style={{ marginRight: 10 }} />
                              <Typography style={{ fontSize: 12, color: '#DC2626' }}>{configJSON.deleteText}</Typography>
                            </MenuItem>
                          </CusomizeMenu>
                        </DotSection>
                      </div>
                    </CardHeader>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ margin: '10px 5px 0px 0px' }}>
                        <CourseTitle>{item.attributes.headings}</CourseTitle>
                        <CourseValue>{item.attributes.contents}</CourseValue>
                      </div>
                    </div>
                  </CardSection>
              </Grid>
                ))}
            </Grid>
            <PaginationBox>
            <PaginationBoxContent>
            <Pagination
              count={this.state.totalPage}
              data-test-id="changePageNumber"
              onChange={this.changePageNumber}
              page={this.state.currentPage}
              size="small"
            />
              </PaginationBoxContent>
            <Box>
                {this.state.data.length > 0 &&
                <PageSizeBox data-test-id="pageSizeBox" onClick={this.togglePageDropdown}>
                  <PageSizeTypography>Page Size</PageSizeTypography>
                  <KeyboardArrowDownIcon height="5.5px" width='9.5px' />
                  {this.state.isPageDropdownOpen && this.renderPageSize()}
                </PageSizeBox>
                }
              </Box>
              </PaginationBox>
          </HeadDiv>
        </UpperDiv>
          {this.state.replyModal && this.ReplyModal()}
        </MainContainer>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const PaginationBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "29px",
  marginTop: "2rem",
  display: "flex",
  justifyContent: "center",
  opacity: "0px",
}));

const SortText = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  height: "40px",
  paddingLeft: "16px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left",
  display: 'flex',
  alignItems: 'center',
  "&:hover": {
    borderRadius: '8px',
    backgroundColor: "#E8EDF5",
  },
  color: theme.palette.info.light
}))

const Dropdown = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: 220,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  textAlign: 'left',
  marginTop: "50px"
}));

const PaginationBoxContent = styled(Box)(({ theme }) => ({
  width: '80%',
  display: 'flex',
  justifyContent: 'center',
  '& .MuiPagination-root': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '18px'
  },
  '& .MuiPagination-ul': {
    gap: '1rem'
  },
  '& .MuiPaginationItem-root': {
    borderRadius: '8px',
    backgroundColor: 'rgba(241, 245, 249, 1)',
    color: 'rgba(67, 83, 106, 1)',
    minWidth: '18px',
    height: '18px',
    fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  '& .MuiPaginationItem-page.Mui-selected': {
    borderRadius: '8px',
    backgroundColor: theme.palette.action.selected,
    color: 'rgba(248, 250, 252, 1)',
    minWidth: '18px',
    height: '18px',
    fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "18px",
  },

}));

const PageSizeBox = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: '95px',
  height: '29px',
  borderRadius: "30px",
  padding: '12px 8px',
  backgroundColor: theme.palette.primary.main,
  textAlign: 'left',
  color: 'rgba(71, 85, 105, 1)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const PageSizeTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  color: 'rgba(71, 85, 105, 1)'
}));

const FlexBox = styled(Box)({
display:"flex",
minWidth:"326px",
justifyContent:"space-between",
alignItems:"center"
})


const CustomizeSnackbar = styled(Snackbar)(({ theme }) => ({
  "& .MuiAlert-standardSuccess":{
    background: "#FFFFFF",
    color: "#0F172A",
    borderRadius:"8px"
  },
  boxShadow: "0px 6px 15px -3px #00000026"
}))

const UpperDiv = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
maxWidth:"84.5%",
marginLeft:"11%",
height:"100%",
minHeight:"100vh"
}))

const CusomizeMenu = styled(Menu)(({ theme }) => ({
  "& .MuiMenu-paper":{
    borderRadius:"18px"
  },
  "& .MuiPaper-root":{
    background:"#fff"
  },
  "& .MuiListItem-button:hover":{
    backgroundColor:"#E8EDF5"
  },
  "& .MuiList-padding":{
    padding:0
  },
  "& .MuiMenuItem-root":{
    height:"32px"
  }
}))

export const MainContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  paddingBottom:"1rem",
  "& .styles-module_navigation__1pqAE": {
    fontSize: "24px"
  },
  "& .styles-module_image__2hdkJ": {
    borderRadius: "13px"
  },
  "& .styles-module_wrapper__1I_qj": {
    left: "20%",
    top: "10%",
    width: "65%",
    height: "85%",
    borderRadius: "13px"
  }
}))

export const HeadDiv = styled(Container)(({ theme }) => ({
  background: theme.palette.action.focus,
  transition: 'all 300ms linear',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth:"100%",
  borderRadius: '18px',
  "& .MuiButtonGroup-root": {
    borderRadius: '50px',
    background: theme.palette.background.default
  },
  "& .MuiButtonGroup-grouped": {
    textTransform: 'capitalize',
    fontWeight: 500,
    fontsize: '14px',
    lineHeight: '22px',
    color: theme.palette.info.light,
    borderRadius: '30px',
    padding: '5px',
    margin: '5px'
  },
  "& .selectedButton": {
    color: theme.palette.info.contrastText,
    background: theme.palette.background.paper
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    background: "#1A469C",
    color: "#FFF",
  },
  "& .MuiPaginationItem-root": {
    fontFamily: "Rubik",
    background: "#F1F5F9",
    fontWeight: 500,
    lineHeight: "18px",
    fontSize: "10px",
    margin: "0px 4px"
  },
  "& .MuiPagination-ul": {
    background: theme.palette.background.default,
    padding: '5px',
    borderRadius: '40px'
  }
}))

const CourseTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '10px',
  color: theme.palette.info.light
}))

const CourseValue = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '12px',
  color: theme.palette.info.main
}))

export const NameField = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '18px',
  color: theme.palette.info.main
}))

export const DesignationField = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '11.51px',
  lineHeight: '14.8px',
  color: theme.palette.secondary.light
}))

const DetailsButton = styled(Button)(({ theme }) => ({
  maxWidth: '81px',
  height: '32px',
  padding: '10px 16px',
  borderRadius: '30px',
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '22px',
  textAlign: 'left',
  color: theme.palette.info.contrastText,
  textTransform: 'capitalize',
  marginLeft: '10px'
}))

const CourseSection = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  padding: '14.8px',
  border: `1px solid ${theme.palette.text.hint}`,
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px',
}))

const NotificationSettingsButton = styled(Button)(({ theme }) => ({
  maxHeight: '189px',
  height: '24px',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  textTransform: 'capitalize',
  color: theme.palette.info.contrastText
}))

const NotificationTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '30px',
  lineHeight: '40px',
  color: theme.palette.info.main
}))

const CardSection = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: '12px 16px',
  borderRadius: '18px',
  margin: '5px'
}))

const CardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  "& .MuiBadge-colorSecondary": {
    backgroundColor: '#DC2626'
  },
  "& .MuiSvgIcon-root": {
    width: '18px',
    fill: theme.palette.secondary.main,
  },
}))

const BodyHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  margin: '10px',
  "& .MuiBadge-colorSecondary": {
    backgroundColor: '#DC2626'
  }
})

export const DotSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  "& .MuiMenu-paper": {
    height: '80px',
    borderRadius: '18px',
    background: theme.palette.background.default
  },
  "& .MuiSvgIcon-root": {
    fill: `${theme.palette.secondary.main} !important`,
    width: '18px'
  },
  "& .MuiIconButton-root": {
    paddingRight: 0
  }
}))

const GoalRightSection = styled(Box)(({ theme }) => ({
  background: theme.palette.action.focus,
  borderRadius: '6px',
  borderLeft: `4px solid ${theme.palette.action.selected}`,
  width: '50%',
  padding: '10px',
  marginTop: '15px'
}))

const CommentMessageCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 10,
  background: theme.palette.primary.main,
  borderRadius: '14px',
  padding: '12px 24px'
}))

const CommentField = styled(Input)(({ theme }) => ({
  height: '44px',
  padding: '10px 24px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.primary.main}`,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  textAlign: 'left',
  background: theme.palette.background.default,
  margin: '10px 10px 0px 0px',
  color: theme.palette.info.main,
  "& .MuiInputBase-input": {
    border: 'none',
    padding: "10px 12px 10px 0px"
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.dark
  }
}))
// Customizable Area End
