import { Box, CircularProgressProps, CircularProgress as MuiCircularProgress, styled, Typography } from "@material-ui/core";
import React, { CSSProperties } from "react";


type ICircularProgressProps = CircularProgressProps & { 
    style?: CSSProperties;
    labelStyle?: CSSProperties;
    withText?: boolean; 
    mode?: "light" | "dark";
}
const CircularProgress = ({
    value = 0,
    size = 20,
    withText = false,
    mode = "light",
    style,
    labelStyle
}: ICircularProgressProps) => {
    if (!withText) {
        return (
            <Box style={{ padding: "3px"}}>
                <MuiCircularProgress
                    variant="determinate"
                    style={{ position: "absolute", left: 0, color: '#1A469C', opacity: "15%" }}
                    size={size}
                    thickness={5}
                    value={100}
                />
                <MuiCircularProgress
                    variant="determinate"
                    value={value}
                    size={size}
                    thickness={5}
                    style={{ ...style, position: "absolute", inset: 0, color: mode === 'light' ? '#1A469C' : '#FFF' }}
                />
            </Box>
        )
    }
    return (
        <Box position="relative" display="inline-flex" style={{ padding: "3px" }}>
            <MuiCircularProgress
                variant="determinate"
                style={{ ...style, color: '#1A469C', opacity: "15%" }}
                size={size}
                thickness={5}
                value={100}
            />
            <MuiCircularProgress
                variant="determinate"
                value={value}
                size={size}
                thickness={5}
                style={{ ...style, position: "absolute", top: 3, left: 3, color: '#1A469C' }}
            />
            <RowStack
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                alignItems="center"
                justifyContent="center"
            >
                <Label variant="caption" color="textSecondary" style={labelStyle}>{`${value}%`}</Label>
            </RowStack>
        </Box>
    )
}

export default CircularProgress;

const RowStack = styled(Box)({
    display: "flex",
    alignItems: "center"
})
const Label = styled(Typography)({
    fontFamily: "Rubik",
    fontSize: "4px",
    lineHeight: "5px",
    color: '#0F172A',
})