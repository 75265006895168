import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent, createRef, CSSProperties, ReactNode } from "react";

// Customizable Area Start
type WithChildren<T = {}> = T & { children?: ReactNode }

export type ICardProps = WithChildren<{
    thumbnailSrc?: string;
    title: string;
    subtitle?: string;
    actionButton?: ReactNode;
    style?: CSSProperties;
}>

interface UploadedFile {
  size?: number;
  id: number;
  name: string;
  url: string;
  content_type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  fileType?: "video" | "image" | "pdf" | string;
  disableBrowsing?: boolean;
  multiple?:boolean;
  withoutPreview?:boolean;
  onChange?: (files: File[] | File | null) => void;
  value?: File | (File | UploadedFile)[];
  required?: boolean;
  label?: string;
  labelStyle?: CSSProperties;
  supportText?: string;
  uploadingFileIcon?: string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  items: Array<string>;
  item: string;
  isEditing: boolean;
  formValidate: boolean;
  previewUrls: string[];
  previewUrl: string;
  fileName: string,
  fileSize: number;
  fileList: File[];
  data:{ key: string; label: number; backgroundColor: string; }[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DragDropInterfaceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileRef: React.RefObject<HTMLInputElement> | null = null
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      item: "",
      items: [],
      isEditing: false,
      formValidate: false,
      previewUrls:[],
      previewUrl:"",
      fileName:"",
      fileSize:0,
      fileList:[],
      data: [...Array(10)].map((d, index) => ({
        key: `item-${index}`,
        label: index,
        backgroundColor: `rgb(${Math.floor(Math.random() * 255)}, ${
          index * 5
        }, ${132})`,
      })),
      // Customizable Area End
    };

    // Customizable Area Start
    this.fileRef = createRef()
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    if(this.props.value){
      const {value} = this.props
      const getValue = value instanceof File ? [value] : value as File[]
      this.setState({fileList:value ? getValue : []})
    }
  }

   handleFileUploadClick = () => {
    if (this.fileRef && this.fileRef.current) {
        this.fileRef.current.click();
    }
};

componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
  if(prevState.fileList !== this.state.fileList){
    if(this.props.multiple){
      this.props.onChange && this.props.onChange(this.state.fileList[0])
    }else{
      this.props.onChange && this.props.onChange(this.state.fileList)
    }
}  
}

handleFilesToPreview = (files: Array<File>) => {
  if (this.props.multiple) {
      if (files.length === 0) {
          this.setState({previewUrls:[]});
      } else {
          this.setState({previewUrls:[...this.state.previewUrls, ...Array.from(files).map(file => URL.createObjectURL(file))]});
      }
  } else {
      this.handleFilesToPreview2(files)
  }
}

handleFilesToPreview2 = (files: Array<File>) => {
  if (!this.props.withoutPreview) {
    if (files.length === 0) {
        this.setState({previewUrl:""});
    } else {
        this.setState({previewUrl:URL.createObjectURL(files[0])});
    }
} else {
    if (files.length === 0) {
        this.setState({fileName:""});
        this.setState({fileSize:0});
    } else {
        this.setState({fileName:files[0].name});
        this.setState({fileSize:files[0].size});               
    }
}
}

  onhandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
            this.handleFilesToPreview(Array.from(event.target.files));
            if (!this.props.multiple) {
                this.setState({fileList:[event.target.files[0]]})
            } else {
                this.setState({fileList:[...this.state.fileList, ...Array.from(event.target.files)]});
            }
        }
    }
  // Customizable Area End
}
