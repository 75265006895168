import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";

interface IPerformanceTracker {
  profile: {
    id: string;
    type: string;
    attributes: {
      id: number;
      first_name: string;
      last_name: string;
      email: string;
      date_of_birth: string;
      preferred_name: string;
      role_id: string;
      full_phone_number: string;
      employee_number: number;
      location: string;
      company_reference: string | null;
      completed_course: string | null;
      account_id: number;
      image: string | null;
      company_id: number[];
      company_name: string;
      phone_number_verified: boolean;
      selected_theme: string;
      font_size: string;
      group_name: string;
      group_ids: number[];
      manager_name: string;
    }
  },
  progress_course_count: number;
  completed_course_count: number;
  assigned_course_percentage: number;
  progress_course_percentage: number;
  completed_course_percentage: number;
  overall_progress_percentage: number;
  average_score: string;
  watch_time: string;
  certificate_count: number;
  average_score_per_courses: { [key: string]: number};
}

export interface ICourse {
  id: number;
  name: string;
  image: string | null;
  instructor: string;
  status: string;
  progress_percentage: number;
  expiration_date: string;
  feedback: []
}

interface ProfileAttribute {
  last_name: string | null;
  first_name: string | null;
  date_of_birth: string;
  email: string;
  id: number;
  full_phone_number: string;
  preferred_name: string;
  role_id: string;
  company_reference: string | null;
  completed_course: string | null;
  account_id: number;
  image: string | null;
  group_id: number;
  employee_number: string | null;
  location: string | null;
  group_name: string;
  font_size: string;
  company_name: string;
  phone_number_verified: boolean;
  selected_theme: string;
  company_id: number;
}

interface Profile {
  id: string;
  type: "profile";
  attributes: ProfileAttribute;
}
interface ProfileData  {
  id: string;
  type: string;
  attributes: ProfileAttribute;
};
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  open:boolean;
  darkTheme:boolean;
  openChatModal:boolean;
  profileData:ProfileData;
  openFeedbackModal:boolean;
  openDirectMessageModal:boolean;
  teamMemberId: number | null;
  roleId: string;
  performaceTrackerInfo: IPerformanceTracker;
  coursesFiltered: "all" | "in_progress" | "assigned" | "completed" | "feedback",
  courses: Array<ICourse>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PerformanceTrackerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiProfileGETCallId:string="";
  getPerformaceTrackerApiCallId: string = "";
  getCoursesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      open:false,
      darkTheme:false,
      openChatModal:false,
      openFeedbackModal:false,
      openDirectMessageModal:false,
      profileData: {
        id: "",
        type: "profile",
        attributes: {
          last_name: null,
          first_name: null,
          email: "",
          date_of_birth: "",
          location: null,
          id: 0,
          completed_course: null,
          preferred_name: "",
          role_id: "",
          full_phone_number: "",
          phone_number_verified: false,
          company_reference: null,
          account_id: 0,
          image: null,
          font_size: "",
          group_id: 0,
          company_id: 0,
          group_name: "",
          company_name: "",
          employee_number: null,
          selected_theme: "",
        },
      },
      roleId: "",
      teamMemberId: null,
      performaceTrackerInfo: {
        profile: {
          id: "",
          type: "",
          attributes: {
            id:  0,
            first_name: "",
            last_name: "",
            email: "",
            date_of_birth: "",
            preferred_name: "",
            role_id: "",
            full_phone_number: "",
            employee_number: 0,
            location: "",
            company_reference: null,
            completed_course: null,
            account_id: 0,
            image: null,
            company_id: [],
            company_name: "",
            phone_number_verified: false,
            selected_theme: "",
            font_size: "",
            group_name: "",
            group_ids: [],
            manager_name: ""
          }
        },
        progress_course_count: 0,
        completed_course_count: 0,
        assigned_course_percentage: 0,
        progress_course_percentage: 0,
        completed_course_percentage: 0,
        overall_progress_percentage: 0,
        average_score: "",
        watch_time: "",
        certificate_count: 0,
        average_score_per_courses: {},
      },
      coursesFiltered: "all",
      courses: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage),
    );
    this.send(message);
  };

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getToken();

    this.getUserProfileData();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }

    const saveUserInfoParse = await getStorageData("saveUserInfo");
    const saveUserInfo = JSON.parse(saveUserInfoParse);

    if (saveUserInfo.id && !this.state.teamMemberId) {
      this.setState({
        roleId: saveUserInfo.attributes.role_id,
        teamMemberId: Number(saveUserInfo.id)
      }, () => { 
        this.getPerformaceTracker();
        this.getCourses();
      })
    }
    
    this.checkTheme();
    window.addEventListener('storage', this.handleStorageChange)

    let menuState: any = localStorage.getItem('open');
    if (menuState != null) {
      this.setState({ open: JSON.parse(menuState)})
    }
  }

  handleStorageChange(event :any) {
    if (event.key === 'darkMode') {
      this.checkTheme();
    }
  }

  getUserProfileData=()=>{
    const webHeader = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken")
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProfileGETCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileGetURL
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  checkTheme = async () => {
    const mode = await getStorageData("darkMode");
    if (mode === 'true') {
      this.setState({ darkTheme: true })
    }
    else {
      this.setState({ darkTheme: false })
    }
  }
  navigateToViewCourses = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'PtCoursesScreen',
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToViewStudents = () => {
    const messages = new Message(getName(MessageEnum.NavigationMessage));
    messages.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'PtStudentRankingScreen',
    );

    messages.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage1: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage1.addData(getName(MessageEnum.SessionResponseData), {});
    messages.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage1);

    this.send(messages);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
       if (webApiRequestCallId === this.apiProfileGETCallId) {
          this.setState({
            profileData: webResponseJson.data,
          });
        } else if (webApiRequestCallId === this.getPerformaceTrackerApiCallId) {
          this.setState({
            performaceTrackerInfo: webResponseJson
          });
        } else if (webApiRequestCallId === this.getCoursesApiCallId && webResponseJson.assigned_courses) {
          this.setState({
            courses: webResponseJson.assigned_courses.data.map((course: any) => {
              return {
                id: course.attributes.id,
                expiration_date: course.attributes.expiration_date,
                status: course.attributes.status,
                name: course.attributes.course.data.attributes.course_name,
                image: course.attributes.course.data.attributes.image,
                instructor: course.attributes.course.data.attributes.instructor,
                progress_percentage: course.attributes.progress_percentage,
                feedback: course.attributes.feedbacks
              }
            })
          })
        }
      } 
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const teamMemberData = message.getData(
        getName(MessageEnum.SessionResponseData),
      );
      
      if (teamMemberData) {
        this.setState({
          teamMemberId: teamMemberData.teamMemberId
        }, () => { 
          this.getPerformaceTracker();
          this.getCourses();
        })
      }
    }
  }
  handleMenu=()=>{
    this.setState({open:!this.state.open});
    localStorage.setItem('open',JSON.stringify(!this.state.open));
  }
  
  handleTheme=()=>{
    this.setState({darkTheme: !this.state.darkTheme})
    console.log(`${!this.state.darkTheme}`,"??????DarkTheme")
    setStorageData('darkMode', `${!this.state.darkTheme}`)
  }
  closeChatModal=()=>{
    this.setState({
      openChatModal:false
    })
  }
  handleChatModal=()=>{
    this.setState({
      openChatModal:true
    })
  }
  
  closeFeedbackModal=()=>{
    this.setState({
      openFeedbackModal:false
    })
  }
  handleFeedbackModal=()=>{
    this.setState({
      openFeedbackModal:true
    })
  }
  closeDMModal=()=>{
    this.setState({
      openDirectMessageModal:false
    })
  }
  handleDMModal=()=>{
    this.setState({
      openDirectMessageModal:true
    })
  }

  goBack=()=>{this.props.navigation.goBack()}

  handleFilterCourses = (coursesFiltered: "all" | "in_progress" | "assigned" | "completed" | "feedback") => {
    this.setState({
      coursesFiltered
    }, () => this.getCourses())
  }

  getCourses = () => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getCoursesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${this.state.roleId === "worker" ? configJSON.getCoursesApiEndpointWorker: configJSON.getCoursesApiEndpoint.replace(":teamMemberId", this.state.teamMemberId)}${this.state.coursesFiltered === "all" ? "" : `?status=${this.state.coursesFiltered}`}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSignedUpCoursesMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPerformaceTracker = () => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getPerformaceTrackerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPerformaceTrackerApiEndpoint}/${this.state.roleId === "worker" ? "" : this.state.teamMemberId}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSignedUpCoursesMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
