import React, { useState, useEffect, FC, ReactNode, CSSProperties } from "react";
import {
  Select,
  OutlinedInput,
  MenuItem,
  Grid,
  Input,
  InputAdornment,
  Box,
} from "@material-ui/core";
import { Add, Check, KeyboardArrowDown, KeyboardArrowUp, Search } from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/styles";

import CustomLabel from "./Label";
import TextInput from "./TextInput";
import Button from "./Button";

const mainOptions: OptionProps[] = [
  {
    id: 2,
    value: "Contact",
    label: "Contact",
  },
  {
    id: 3,
    label: "Company",
    value: "Company",
  },
  {
    id: 4,
    label: "Event",
    value: "event"
  }
];

const isEmpty = (value: Object | null) => {
  if (value == null) {
    return true;
  }
  for (var key in value) {
    if (React.hasOwnProperty.call(value, key)) {
      return false;
    }
  }
  return true;
}
const toLower = (value: string) => {
  return typeof value === "string" ? value.toLowerCase() : "";
};

const defaultVal = {
  label: "",
  value: "-1",
};

type OptionProps = {
  id?: number | string;
  value: string;
  label: string;
};

export type OnChangeProps = {
  label: string;
  value: string;
};

type defaultValueObjectsProps = {
  label: string;
  value: string;
};

type Props = {
  crmFields?: OptionProps[];
  onChange: (changeObj: OnChangeProps) => void;
  fullWidth?: boolean;
  selectStyle?: CSSProperties;
  defaultLabel?: string;
  labelStyle?: CSSProperties;
  placeholder?: string;
  defaultValueObject: defaultValueObjectsProps[];
  logo?: ReactNode;
  searchable?: boolean;
  addable?: boolean;
  addButtonText?: string;
  addPlaceholder?: string;
  required?: boolean;
  borderless?: boolean;
  onCreateNew?: (value: string) => void;
  searchPlaceholder?: string;
};

const MultiSelect: FC<Props> = ({
  crmFields = mainOptions,
  onChange,
  fullWidth = false,
  selectStyle,
  searchable = true,
  addable = true,
  searchPlaceholder,
  defaultLabel,
  labelStyle,
  placeholder = "Select",
  addButtonText = "Add New",
  addPlaceholder = "Typing...",
  required = false,
  borderless = false,
  onCreateNew = (val) => console.log("Sent ", val),
  defaultValueObject = [defaultVal],
  logo
}) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const borderlessOutlinedInputClasses = useBorderlessOutlinedInputStyles();

  const [anchorEl, setAnchorEl] = useState({});
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [focusedObject, setFocusedObject] = useState("");
  const [selectedValue, setSelectedValue] = useState<OnChangeProps[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [newCategory, setNewCategory] = useState("");

  useEffect(() => {
    // Get initially selected objects and set to state
    if (defaultValueObject && defaultValueObject.length > 0) {
      defaultValueObject.map(each => {
          updateValuesFromSelectedTree(
              each.label,
              each.value
          );
      })
    }
  }, [defaultValueObject]);

  /**
   * getFilteredObject - Filter options by default value and object and returns fitlered object tree
   * @returns {Object} - Returns an array of object
   */
  const getFilteredObject = (
    array: OptionProps[] = [],
    selectedLabel: string,
    selectedVal: string
  ) => {
    // const fn = ({ label, value }: { label: string; value: string }) => {
    //   return (
    //     toLower(value) === toLower(selectedVal) &&
    //     toLower(label) === toLower(selectedLabel)
    //   );
    // };
    return array?.filter((each) => {
      const { label, value } = each;
      return (
        toLower(value) === toLower(selectedVal) &&
        toLower(label) === toLower(selectedLabel)
      );
    });
    // return filterData(filteredObjectList, fn);
  };

  /**
   * getSelectedObjectKeyValCombination
   * @returns {Object} - Returns an array of string in the format [label+value].
   * @example
   * // returns ["Namefirst_name", "Contactemail"]
   */
  // const getSelectedObjectKeyValCombination = (
  //   obj = {} as OptionProps,
  //   results: string[] = [],
  //   searchKey: string,
  //   defaultVal: string
  // ) => {
  //   let r = results;
  //   Object.keys(obj).forEach((key) => {
  //     const value = obj[key as keyof OptionProps];
  //     const label = obj?.label;
  //     if (
  //       key === searchKey &&
  //       typeof value !== "object" &&
  //       obj?.options?.length
  //     ) {
  //       r.push(label + value);
  //     } else if (typeof value === "object") {
  //       if (value?.length) {
  //         value.sort((each: OptionProps) => {
  //           if (each?.options?.length) {
  //             return 1;
  //           }
  //           return -1;
  //         });
  //       }
  //       //@ts-ignore
  //       if (defaultVal && value?.value === defaultVal) {
  //         r = [];
  //       }

  //       //@ts-ignore
  //       getSelectedObjectKeyValCombination(value, r, searchKey, defaultVal);
  //     }
  //   });
  //   return r;
  // };

  const updateValuesFromSelectedTree = (
    fieldLabel: string,
    fieldValue: string
  ) => {
    const [defaultObject]: OptionProps[] = getFilteredObject(
      crmFields,
      fieldLabel,
      fieldValue
    );

    if (!isEmpty(defaultObject) && !selectedValue.some(each => each.value === defaultObject.value)) {
      setSelectedValue(defaultValueObject.filter(value => value.value !== "-1"));
    } else {
      setSelectedValue([{ value: "-1", label: placeholder }]);
    }
  };

  const handleSelect = (selectedObject: OnChangeProps) => {
    onChange(selectedObject);
    setSelectedValue([...selectedValue, selectedObject]);
    // updateValuesFromSelectedTree(
    //   selectedObject.label,
    //   selectedObject.value
    // );
    // reset
    setAnchorEl({});
    setFocusedObject("");
    setSearchValue("");
  };

  const handleCreateNewClick = () => {
    onCreateNew(newCategory);
    // reset
    setNewCategory("");
    setIsCreatingNew(false);
    setAnchorEl({});
  }

  const filterData = (array: any[], fn: { ({ label, value }: { label: any; value: any; }): boolean; ({ value, label }: { value: any; label: any; }): boolean; (arg0: any): any; }) => {
    return array.reduce((r: any[], o: { options: any; }) => {
      var options = filterOptionsByKeyword(o.options || []);
      if (fn(o) || options?.length)
        r.push({ ...o, ...(options.length && { options }) });
      return r;
    }, []);
  };

  const filterOptionsByKeyword = (array: OptionProps[] = []) => {
    const fn = ({ value, label }: { value: string; label: string }) => {
      return (
        toLower(value).includes(toLower(searchValue)) ||
        toLower(label).includes(toLower(searchValue))
      );
    };
    return filterData(array, fn);
  };

  const renderSelectValue = (val: unknown) => {
    if (!Array.isArray(val) || val.length === 0) {
      return (
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs="auto">
            <span className={classes.selectLabelWrapper}>
              {placeholder}
            </span>
          </Grid>
          <Grid item xs="auto">
            {!isEmpty(anchorEl) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container alignItems="center" justifyContent="space-between">
        {logo && (
          <Grid item xs="auto" style={{ display: "flex", marginRight: 6 }}>
            {logo}
          </Grid>
        )}
        <Grid item xs={9}>
          <span className={classes.selectLabelWrapper}>{val.join("; ")}</span>
        </Grid>
        <Grid item xs={3}>
          {!isEmpty(anchorEl) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Grid>
      </Grid>
    );
  };

  return (
    <Box style={{ width: "100%" }}>
      {defaultLabel && (
        <CustomLabel style={{ ...labelStyle }} required={required}>{defaultLabel}</CustomLabel>
      )}
      <Select
        multiple
        value={selectedValue.map(each => each.label)}
        renderValue={renderSelectValue}
        open={!isEmpty(anchorEl)}
        onOpen={(e) => setAnchorEl({ main: e.target })}
        onClose={() => setAnchorEl({})}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          className: classes.menu,
          classes: { paper: classes.menuPaper, list: classes.menuList },
        }}
        input={
          <OutlinedInput
            fullWidth={fullWidth}
            id="nested-select-1"
            classes={!borderless ? outlinedInputClasses : borderlessOutlinedInputClasses}
            style={{ borderRadius: "8px", ...selectStyle }}
          />
        }
        classes={{ icon: classes.selectIcon, select: classes.select }}
      >
        {/* Display search filter if searchable is true */}
        {searchable && (
          <MenuItem
            className={classes.searchListItem}
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl({
                ...anchorEl,
                main: event.currentTarget
              });
            }}
            // onKeyDown={(e) => e.stopPropagation()}
          >
            <TextInput
              id="nested-select-filter"
              placeholder={searchPlaceholder || "Type ..."}
              iconLeft={<Search style={{ fill: "#8C8C8C" }} />}
              fullWidth
              value={searchValue}
              autoComplete="off"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              className={classes.searchField}
              style={{ borderRadius: "98px" }}
            />
          </MenuItem>
        )}

        {/* Display search filter if searchable is true */}
        {addable && (
          <MenuItem
            className={isCreatingNew ? classes.searchListItem : classes.addNewItem}
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl({
                ...anchorEl,
                main: event.currentTarget
              });
              if (!isCreatingNew) {
                setIsCreatingNew(true);
              }
            }}
          >
           {isCreatingNew ? (
              <Input
                id="nested-create-new-field"
                type="text"
                placeholder={addPlaceholder}
                fullWidth
                value={newCategory}
                onChange={e => setNewCategory(e.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        <Button
                            disabled={newCategory === ""}
                            style={{ 
                                height: "fit-content", 
                                padding: "5px 16px", 
                                backgroundColor: newCategory === "" ? "#94A3B8" : "#1A469C", 
                                color: "#F8FAFC",
                                fontSize: "10px",
                                lineHeight: "12px" 
                            }}
                            onClick={handleCreateNewClick}
                            // onMouseDown={handleMouseDownPassword}
                        >
                            Add
                        </Button>
                    </InputAdornment>
                }
                style={{ height: "46px" }}
                classes={{ underline: classes.underline }}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
           ) : (
              <Grid container alignItems="center" spacing={1}>
                    <Grid item xs="auto">
                        <Add style={{ fill: "#1A469C" }} />
                    </Grid>
                    <Grid item xs="auto" style={{ display: "flex", color: "#1A469C" }}>
                        {addButtonText}
                    </Grid>
              </Grid>
            )}
          </MenuItem>
        )}

        {filterOptionsByKeyword(crmFields).map(({ value, label }: OptionProps) => (
            <StyledMenuItem
              key={label + value}
              id={`menu-item-${label}${value}`}
              value={value}
              selected={selectedValue.some(each => each.value === value)}
              onClick={(event) => {
                event.stopPropagation();
                handleSelect({ label, value });
              }}
              style={{ 
                backgroundColor: (focusedObject === label + value) ? "#E8EDF5" : "#FFF", 
                color: (focusedObject === label + value) ? "#1A469C" : "#475569" 
              }}
            >
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs="auto">
                  {label}{" "}
                </Grid>
                <Grid item xs="auto" style={{ display: "flex" }}>
                  {selectedValue.some(each => each.value === value) && <Check fontSize="small" style={{ fill: "#1A469C" }} /> }
                </Grid>
              </Grid>
            </StyledMenuItem>
          )
        )}
      </Select>
    </Box>
  );
};

export default MultiSelect;

const useStyles = makeStyles(() => ({
  menu: {
    marginTop: 0,
  },
  menuPaper: {
    border: "1px solid #FFF !important",
    borderRadius: "8px !important",
    backgroundColor: "#fff !important",
    maxHeight: "340px !important",
        '&::-webkit-scrollbar': {
        width: '13px'
    },
    '&::-webkit-scrollbar-track': {
        border: '0.94px solid #CBD5E1'
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '28.34px 28.34px 85.01px',
        backgroundColor: '#EDF1F6',
        border: '0.94px solid #94A3B8'
    }
  },
  menuList: {
    padding: "10px 23px 10px 10px !important",
  },
  selectLabelWrapper: {
    marginRight: 16,
    display: "block",
    textOverflow: "ellipsis",
    whiteSpace: 'nowrap',
    overflow: "hidden"
  },
  selectLabel: {
    margin: "10px auto",
    color: "#0A0A0A"
  },
  selectIcon: {
    color: "#0A0A0A",
    marginRight: 8
  },
  searchField: {
    borderRadius: 98,
  },
  select: {
      "&:focus": {
          backgroundColor: "transparent !important",
      },  
  },
  searchListItem: {
    padding: "0 0 10px !important",
    background: "#fff !important"
  },
  addNewItem: {
    backgroundColor: "#E8EDF5 !important",
    borderRadius: "8px !important",
    padding: "12px 16px !important"
  },
  underline: {
    "&:before": {
        borderBottom: "1px solid #000 !important"
    },
    "&:after": {
        borderBottom: "1px solid #000 !important"
    }
  }
}));

const useOutlinedInputStyles = makeStyles(() => ({
  root: {
    width: "100%",
    background: "#fff",
    height: 44,
    borderRadius: "8px",
    "& $notchedOutline": {
        border: "1px solid #CBD5E1",
    },
    "&:hover $notchedOutline": {
        border: "1px solid #CBD5E1",
    },
    "&$focused $notchedOutline": {
        border: "1px solid #CBD5E1",
    },
    minWidth: 230
  },
  focused: {
    border: "1px solid #CBD5E1",
  },
  notchedOutline: {}
}));
const useBorderlessOutlinedInputStyles = makeStyles(() => ({
  root: {
    width: "100%",
    background: "#fff",
    height: 44,
    borderRadius: "8px",
    border: "none",
    "& $notchedOutline": {
        border: "none",
    },
    "&:hover $notchedOutline": {
        border: "none",
    },
    "&$focused $notchedOutline": {
        border: "none",
    },
    minWidth: 230
  },
  focused: {
    border: "1px solid #CBD5E1",
  },
  notchedOutline: {}
}));

const StyledMenuItem = withStyles(() => ({
  root: {
    minWidth: 170,
    width: "auto",
    height: 48,
    color: "#475569",
    fontSize: 16,
    lineHeight: "24px",
    margin: "10px 8px",
    padding: "12px 16px",
    borderRadius: 8,
    "&:hover": {
        backgroundColor: "#E8EDF5 !important",
        borderRadius: 8
    },
    "&.Mui-selected": {
        backgroundColor: "#FFF !important",
        "&:hover": {
          backgroundColor: "#E8EDF5 !important",
        }
    }
  }
}))(MenuItem);