import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
  styled, Button, InputBase,
  Paper,Modal,
  CardMedia,Card,Avatar
} from "@material-ui/core";
import {ChevronLeft} from "@material-ui/icons";
import { AvatarGroup } from "@material-ui/lab";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import { templateOne, moreIcon, order, DropCourse, CourseDownload,Edit,archive,dummy,CourseDelete,CourseIcon} from "./assets";
import Pagination from "@material-ui/lab/Pagination";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import SearchIcon from '@material-ui/icons/Search';
import { Select } from "antd";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Course } from "./PtTestsForCourseScreenController";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastContainer } from "react-toastify";
const theme = createTheme({
  palette: {
    primary: {
      main: "#afe9ed",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const StatusOptions = [
  { label: 'Assigned', value: 'assigned' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Completed', value: 'completed' }
]

const sortOptions = [
  { value: "asc", label: "Ascending" },
  { value: "desc", label: "Descending" },
];

// Customizable Area End
import PtTestsForCourseScreenController, {
  Props,
  configJSON,
} from './PtTestsForCourseScreenController';
export default class PtTestsForCourseScreen extends PtTestsForCourseScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  LeftFilterOptions = () => {
    return (
      <div style={{ display: "flex" }} data-test-id="left-filter-options">
        {this.state.roleId==="worker"&&
          <CourseTabSection>
          {(this.state.SelectedCourseTab === "is_assigned") ? (
            <SelectedFilter
              data-test-id="filter-selected-assigned"
              onClick={() => this.handleFilterClick("Assigned")}
            >
              <SelectedFilterTypo>Assigned</SelectedFilterTypo>
            </SelectedFilter>
          ) : (
            <DeselectedFilter
              data-test-id="filter-deselected-assigned"
              onClick={() => this.handleFilterClick("is_assigned")}
            >
              <DeselectedFilterTypo>Assigned</DeselectedFilterTypo>
            </DeselectedFilter>
          )}
  
          {(this.state.SelectedCourseTab === "downloaded") ? (
            <SelectedFilter
              data-test-id="filter-selected-downloaded"
              onClick={() => this.handleFilterClick("Downloaded")}
            >
              <SelectedFilterTypo>Downloaded</SelectedFilterTypo>
            </SelectedFilter>
          ) : (
            <DeselectedFilter
              data-test-id="filter-deselected-downloaded"
              onClick={() => this.handleFilterClick("downloaded")}
            >
              <DeselectedFilterTypo>Downloaded</DeselectedFilterTypo>
            </DeselectedFilter>
          )}
  
          {(this.state.SelectedCourseTab === "is_completed") ? (
            <SelectedFilter
              data-test-id="filter-selected-completed"
              onClick={() => this.handleFilterClick("Completed")}
            >
              <SelectedFilterTypo>Completed</SelectedFilterTypo>
            </SelectedFilter>
          ) : (
            <DeselectedFilter
              data-test-id="filter-deselected-completed"
              onClick={() => this.handleFilterClick("is_completed")}
            >
              <DeselectedFilterTypo>Completed</DeselectedFilterTypo>
            </DeselectedFilter>
          )}
        </CourseTabSection>
        }
       {(this.state.roleId==="instructor"|| this.state.roleId==="supervisor")&&
       <CourseTabSection>
       {(this.state.SelectedCourseTab === "all") ? (
         <SelectedFilter
           data-test-id="filter-selected-all"
           onClick={() => this.handleFilterR2Click("all")}
         >
           <SelectedFilterTypo>All</SelectedFilterTypo>
         </SelectedFilter>
       ) : (
         <DeselectedFilter
           data-test-id="filter-deselected-all"
           onClick={() => this.handleFilterR2Click("all")}
         >
           <DeselectedFilterTypo>All</DeselectedFilterTypo>
         </DeselectedFilter>
       )}

       {(this.state.SelectedCourseTab === "published") ? (
         <SelectedFilter
           data-test-id="filter-selected-published"
           onClick={() => this.handleFilterR2Click("published")}
         >
           <SelectedFilterTypo>Published</SelectedFilterTypo>
         </SelectedFilter>
       ) : (
         <DeselectedFilter
           data-test-id="filter-deselected-published"
           onClick={() => this.handleFilterR2Click("published")}
         >
           <DeselectedFilterTypo>Published</DeselectedFilterTypo>
         </DeselectedFilter>
       )}

       {(this.state.SelectedCourseTab === "archived") ? (
         <SelectedFilter
           data-test-id="filter-selected-archived"
           onClick={() => this.handleFilterR2Click("archived")}
         >
           <SelectedFilterTypo>Archived</SelectedFilterTypo>
         </SelectedFilter>
       ) : (
         <DeselectedFilter
           data-test-id="filter-deselected-archived"
           onClick={() => this.handleFilterR2Click("archived")}
         >
           <DeselectedFilterTypo>Archived</DeselectedFilterTypo>
         </DeselectedFilter>
       )}
       {(this.state.SelectedCourseTab === "drafted") ? (
         <SelectedFilter
           data-test-id="filter-selected-drafted"
           onClick={() => this.handleFilterR2Click("drafted")}
         >
           <SelectedFilterTypo>Drafted</SelectedFilterTypo>
         </SelectedFilter>
       ) : (
         <DeselectedFilter
           data-test-id="filter-deselected-drafted"
           onClick={() => this.handleFilterR2Click("drafted")}
         >
           <DeselectedFilterTypo>Drafted</DeselectedFilterTypo>
         </DeselectedFilter>
       )}
     </CourseTabSection>
       }
      </div>
    );
  };
  

  renderDownloadDropdown = (course: Course) => {
    const options1 = [
      course.attributes.course_downloaded 
        ? { label: "Delete From Download", value: "Delete From Download", img: CourseDelete } 
        : { label: "Download", value: "Download", img: CourseDownload },
      { label: "Request to Drop Course", value: "Request to Drop Course", img: DropCourse },
    ];
    
    let options2 = [
      { label: "Edit Course", value: "Edit Course", img: Edit },
    ];
    if(course.attributes.status==="archived"){
       let obj={
        label: "Unarchive",value:"draft",img:archive
       }
       options2.push(obj)
    }
    if(course.attributes.status==="published"){
      let obj={
        label: "Archive",value:"archived",img:archive
       }
       options2.push(obj)
    }
    const options=this.state.roleId==="instructor"?options2:options1
    return (
      <Dropdown style={{ maxHeight: "84px", zIndex: 100, top: -25, right: 5 }} data-test-id="sort-dropdown">
        {options.map((option) => (
          <SortText
            key={option.value}
            onClick={() => this.handleDropDown(option.value)}
            style={{
              borderRadius: "8px",
              backgroundColor: this.state.DownloadDropdownOption === option.value ? "#E8EDF5" : "",
              color: this.state.DownloadDropdownOption === option.value ? "#1A469C" : "#475569",
            }}
          >
            <div data-test-id="sort-dropdown2" onClick={()=>option.value==="Download" || option.value==="Delete From Download" ? this.DownloadCourse(course.attributes.course.data.id, course.attributes.course_downloaded) : undefined} style={{display:"flex", alignItems:"center"}}>
              <img src={option.img} alt={option.label} style={{ width: "18px", height: "16px", marginRight: "8px" }} />
              <div style={{ color: option.value === "Delete From Download" ? "#DC2626" : "" }}> {option.label} </div>
            </div>
          </SortText>
        ))}
        <Backdrop
          open={this.state.isLoading}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dropdown>
    );
  };

  renderPageSize = () => {
    const options = [10, 25, 50, 100];
    return (
      <Dropdown style={{ height: "160px", width: '55px', zIndex: 100, top: -225, right: 0 }} data-test-id="page-size-dropdown">
        {options.map((option) => (
          <SortText
            key={option}
            onClick={() => this.handlePageSizeChange(option)}
            style={{
              borderRadius: '8px',
              backgroundColor: this.state.selectedPageSize === option ? "#E8EDF5" : "",
              color: this.state.selectedPageSize === option ? "#1A469C" : "#475569",
            }}
          >
            {option}
          </SortText>
        ))}
      </Dropdown>
    );
  };
companyShow=()=>{
  return (
    <span style={{ lineHeight: 1, color: "#1A469C" }}>
    {this.state.selectedCompany
      ? this.state.selectedCompany.charAt(0).toUpperCase() + this.state.selectedCompany.slice(1)
      : <span style={{ color: '#475569' }}>Company</span>
    }
  </span>
  )
}
  RightFilterOptions = () => {
    const open = Boolean(this.state.anchorSort);
    return <div style={{ display: 'flex', alignItems: 'center',gap:"8px",justifyContent:"space-between" }}>
{(this.state.roleId==="instructor" ) &&
  <SubText
  data-test-id="dropdownOpen"
  onClick={() => {this.setState({ dropdownOpenCompany: !this.state.dropdownOpenCompany })
  this.handleCompanyChange()
}}
  style={{
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    position: 'relative',
    borderRadius: '30px',
    minHeight: 43,
    minWidth: 171,
    paddingRight: 30,
    backgroundColor: this.rightFilterBG(),
    transition: "background-color 0.3s ease",
    border: 'transparent'
  }}
>
{this.companyShow()}
  <span data-test-id="Dropdownopen" style={{ position: 'absolute', top: 10, right: 8 }}>
    <ChevronLeft
      style={{
        transform: this.transformShowCompany(),
        width: 24,
        height: 24,
        color: "rgba(71, 85, 105, 1)"
      }}
    />
  </span>
  {this.showCompanyFilter()&& (
    <DropdownBox className="role-dropbox" style={{maxHeight: '220px', overflowY: 'scroll'}}>
      {this.state.companyAllData.map((item:any) => (
        <DayList
          key={item.attributes.id}
          data-test-id="roleFilter"
          className={`roleList1 ${this.showFilterForGroupClassName(item)}`}
          onClick={() => {
            this.setState({ selectedCompany: item.attributes.company_name, dropdownOpenCompany: false, isCompanyReset: false});
            this.handleCompanySelect(item.attributes.id);
          }}
          style={{
            backgroundColor: this.showFilterForGroupbackgroundColor(item),
            color: this.showFilterForGroupColor(item) ,
            transition: "background-color 0.3s ease"
          }}
        >
          {item.attributes.company_name}
        </DayList>
      ))}
    </DropdownBox>
  )}
</SubText>
}


  <SubText
  data-test-id="dropdownOpen"
  onClick={() => {this.setState({ dropdownOpenCatogary: !this.state.dropdownOpenCatogary })
  this.handleCategoryChange()
}}
  style={{
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    position: 'relative',
    borderRadius: '30px',
    minHeight: 43,
    minWidth: 171,
    paddingRight: 30,
    backgroundColor: this.showGroupFilter() ? "rgba(232, 237, 245, 1)" : "white",
    transition: "background-color 0.3s ease",
    border: 'transparent'
  }}
>
  <span style={{ lineHeight: 1, color: "#1A469C" }}>
   {this.showSelectedGroup()}
  </span>

  <span data-test-id="Dropdownopen" style={{ position: 'absolute', top: 10, right: 8 }}>
    <ChevronLeft
      style={{
        transform: this.showTransForm(),
        width: 24,
        height: 24,
        color: "rgba(71, 85, 105, 1)"
      }}
    />
  </span>

  

  {this.state.dropdownOpenCatogary && (
    <DropdownBox className="role-dropbox" style={{height: '220px', overflowY: 'scroll'}}>
      {this.state.categoryAllData.data.map((item) => (
        <DayList
          key={item.attributes.id}
          data-test-id="roleFilter"
          className={`roleList1 ${this.rigthFilter(item)? "selected-option" : ""}`}
          onClick={() => {
            this.setState({ selectedGroup: item.attributes.name, dropdownOpenCatogary: false, isCategoryReset: false});
            this.handleCategorySelect(item.attributes.id);
          }}
          style={{
            backgroundColor: this.backGroundForCatogray(item),
            color:this.colorForCatogary(item),
            transition: "background-color 0.3s ease"
          }}
        >
          {item.attributes.name}
        </DayList>
      ))}
    </DropdownBox>
  )}
</SubText>

{this.showReset()}
      <Search data-test-id="search-container">
        <SearchIconWrapper data-test-id="search-icon-wrapper">
          <SearchIcon data-test-id="search-icon" />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          onChange={(event) => this.handleInputChange(event)}
          data-test-id="search-input"
        value={this.state.searchQuery}
        />
      </Search>
      {(this.checkSelectedStatusOrExpDate()) && <>
        <img
          src={order}
          alt="sort"
          style={{ cursor: "pointer" }}
          data-test-id="openAnchorSort"
          id="basic-button"
          onClick={(event: React.MouseEvent<HTMLImageElement>) => {
            this.setState({ anchorSort: event.currentTarget })
          }}
        />
        <Menu
          data-test-id="closeSort"
          id="basic-menu"
          anchorEl={this.state.anchorSort}
          open={open}
          onClose={() => this.setState({ anchorSort: null })}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          style={{ top: "50px" }}
        >
          <MenuItem data-test-id="removeImage" disabled>
            SORT BY
          </MenuItem>
          {sortOptions.map((item) => (
            <MenuItem data-test-id="removeImage">
              <span
                data-test-id="sortEmployee"
                style={{
                  color: "#1A469C",
                  fontFamily: "Rubik",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "22px",
                }}

              >
                {item.label}
              </span>
            </MenuItem>
          ))}
        </Menu> </>}
    </div>
  }   
  showSelectedGroup=()=>(
      this.state.selectedGroup
        ? this.state.selectedGroup.charAt(0).toUpperCase() + this.state.selectedGroup.slice(1)
        : <span style={{ color: '#475569' }}>Course Category</span>         
  )

  showReset=()=>{
return (this.checkSelectedGroupOrCompany())&&
<ResetTypo  onClick={this.handleCategoryReset}>
Reset
</ResetTypo>
  }
  showOfflineDownloadList = () => {
    return (
      <Modal
        open={this.state.openDownLoadModal}
        onClose={this.closeDownloadModal}
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)', alignContent: "center" } }} // This disables the background
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#fff",
            padding: "20px",
            borderRadius: "8px",
            width: "885px",
            height: "575px",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.2)", 
            paddingLeft: "4.5vw",
          }}
        >
          <Box style={{ width: "100%" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                justifyItems: "center",
                alignContent: "center",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  fontFamily: "Rubik",
                  fontWeight: 500,
                  fontSize: "24.96px",
                  lineHeight: "29.58px",
                  letterSpacing: "0%",
                  textAlign: "center"
                }}
              >
                Offline Access
              </Typography>
              <div
                style={{
                  background: "var(--Side-Navigation-Drawer-Tabs-Storkes-Tabs, #D7D7D780)",
                  width: "196.56px",
                  height: "1.56px"
                }}
              />
              <Typography
                style={{
                  fontFamily: "Rubik",
                  fontWeight: 400,
                  fontSize: "17.16px",
                  lineHeight: "20.34px",
                  letterSpacing: "0%"
                }}
              >
                Downloaded By
              </Typography>
              <div
                style={{
                  background: "var(--Side-Navigation-Drawer-Tabs-Storkes-Tabs, #D7D7D780)",
                  width: "196.56px",
                  height: "1.56px"
                }}
              />
              <Box
                style={{
                  width: "24px",
                  height: "24px",
                  top: "8.46px",
                  left: "8.46px",
                  fontSize: "12px",
                  color: "#DC2626",
                  fontWeight: "bold",
                  lineHeight: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
                onClick={this.closeDownloadModal}
              >
                X
              </Box>
            </Box>


            <Box className="scrollModal" style={{ marginTop: "20px", width: "93%", maxHeight: "417px", overflow: "scroll" }}>
              <RowDownloadWorker>
                {this.state.selectedCourseDownload?.attributes.course_download_worker_list?.data &&
                  this.state.selectedCourseDownload?.attributes.course_download_worker_list?.data.map((course) => {
                    return (
                      <BoxUpper1>
                        <Box>
                          <DownLoadTiltle>
                            ID
                          </DownLoadTiltle>
                          <DownloadListValue>
                            {course.id}
                          </DownloadListValue>
                        </Box>
                        <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                          <Card
                            style={{
                              width: "35px",
                              height: "35px",
                              borderRadius: "30px",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={dummy}  
                              style={{
                                width: "35px",
                                height: "35px",
                                borderRadius: "30px",
                                objectFit: "cover"
                              }}
                            />
                          </Card>
                          <Box>
                            <DownLoadTiltle >
                              Name
                            </DownLoadTiltle>

                            <DownloadListValue>
                              {course.first_name} {course.last_name}
                            </DownloadListValue>
                          </Box>
                        </Box>
                        <Box>
                          <DownLoadTiltle>
                            Role
                          </DownLoadTiltle>
                          <DownloadListValue>
                            {course.role_id}
                          </DownloadListValue>
                        </Box>

                        {/* Group Section */}
                        <Box>
                          <DownLoadTiltle >
                            Group
                          </DownLoadTiltle>
                          <DownloadListValue >
                            {course.group_names}
                          </DownloadListValue>
                        </Box>
                        <Box style={{ width: "40%" }}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%"
                            }}
                          >
                            <DownLoadTiltle>  
                              Training Requirements
                              </DownLoadTiltle>
                            <DownLoadTiltle>
                              100%
                            </DownLoadTiltle>
                          </Box>
                          <Box style={{ width: "100%", marginTop: "4px" }}>
                            <LinearProgressBar1
                              variant="determinate"
                              value={100}
                              style={{ width: "100%" }}
                            />
                          </Box>
                        </Box>
                      </BoxUpper1>
                    )
                  })
                }
              </RowDownloadWorker>
            </Box>
            <div
              style={{
                background: "var(--Side-Navigation-Drawer-Tabs-Storkes-Tabs, #D7D7D780)",
                width: "93%",
                height: "1.56px"
              }}
            />
          </Box>
        </div>
      </Modal>
    );
  }
  renderEachCourseBox = (allCourse: Course[]) => {
    return allCourse.map((course,index) => (
      <EachCourseBox data-test-id="createTestButton" key={index}>
           
           <div style={{ position: "relative", display: "inline-block",width:"50%" }}>
  
  <img 
    src={templateOne}
    style={{
      width: "100%",
      height: "92px",
      gap: "0px",
      borderRadius: "14px",
      opacity: "0px",
      backgroundColor: "#00000033"
    }}
    onClick={() => this.setCourseIdProjectPage(this.state.roleId==="worker"?course.attributes.course.data.id:course.id)}
  />
{(course.attributes.status==="draft"||course.attributes.status==="archived") &&<CardCourse
    style={{
      position: "absolute",
      top: "10px",
      left: "10px",
      width: "45.06px",
      height: "26.06px",
      color: "#DC2626",
      borderRadius: "30.11px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 100,
      backgroundColor: "white",
      
    }}
  >{this.showOfflineDownloadList()}
    <Typography
      style={{
        fontFamily: "Rubik, sans-serif",
        letterSpacing: "0%",
        fontWeight: 500,
        fontSize: "8.03px",
        lineHeight: "9.99px",
        marginLeft:"2px",
      marginRight:"2px"
      }}
    >
     {course.attributes.status==="draft"&&"DRAFT"}{course.attributes.status==="archived"&&"ARCHIVED"}
    </Typography>
  </CardCourse>}
  
</div>
        <CourseDetailBox>
          <SubDetailBox>
            <CourseNameBox>
              <CourseNameTypo>Course Category/Course Name</CourseNameTypo>
              <NameTypo data-test-id="categoryTestId">
              {this.renderCategoryName(course.attributes.category_name)} / {course.attributes.course_name}
              </NameTypo>
            </CourseNameBox>
            <Box key={course.id} style={{position: 'relative'}} >
            <img onClick={() => this.toggleDownloadDropdown(course.id)} src={moreIcon} style={{ width: "21.28px", height: "28.24px" }} />
            {this.state.openDropdownId === course.id && this.renderDownloadDropdown(course)}
            </Box>
          </SubDetailBox>
          <SubDetailBox>
            <CourseNameBox>
              <CourseNameTypo>Instructor Name/Lessons</CourseNameTypo>
              <NameTypo> {course.attributes.preferred_name} / {course.attributes.course_contents_count ?? 0}</NameTypo>
            </CourseNameBox>
          {this.state.roleId==="instructor"&&
           <Box style={{ display: "flex", alignItems: "center", gap: "4px" ,paddingRight:"55px",marginTop:"15px"}}>
           <Typography
             style={{
               fontFamily: "Rubik",
               fontWeight: 400,
               fontSize: "8.03px",
               color: "#1A469C"
             }}
           >
             Company:
           </Typography>
           <Typography
             style={{
               fontFamily: "Rubik",
               fontWeight: 500,
               fontSize: "10px",
               color: "#1A469C"
             }}
           >
         {this.state.companyMapState.get(`${course.attributes.company_id}`)}
         
           </Typography>
         </Box>
          }
           
            <ExpirationProgressBox>
              <ExpirationBox>
                <CourseNameTypo>Expiration Date</CourseNameTypo>
                <YellowBox><YellowTypo> {typeof course.attributes.due_date === "string" ? course.attributes.due_date : "No Expiration"}</YellowTypo></YellowBox>
              </ExpirationBox>
             
            </ExpirationProgressBox>
          </SubDetailBox>        
{this.showDownLoad(course)}  
        {this.state.roleId==="worker"&&<LinearProgressBar variant="determinate"></LinearProgressBar>}  
        </CourseDetailBox>
        
      </EachCourseBox>
    ))
  }
  showDownLoad=(course:Course)=>{
    if(course.attributes.course_download_worker_list?.data!==undefined &&course.attributes.course_download_worker_list?.data.length>0)
      {
      return (<>
        <div style={{
            width: "90%",
            height: "2px",
            backgroundColor: "#D7D7D780",
            margin: "0 auto"
          }} />
            <SubDetailBox>
       
       <NameTypo onClick={()=>{this.fetchDownloadCourseList(course) 
         }}>DownLoaded By </NameTypo> 
       
         <AvatarGroup max={4} >
          {course.attributes.course_download_worker_list?.data.map((e:any)=>{
            return(
         <Avatar alt="John Doe" src={e.image} style={{height: "18px",width:"18px"}}/>
            )
          })
            
          }
         
      </AvatarGroup>
      
      
     
   </SubDetailBox>
      </>
      
      )
    }
  }
  renderMainComponent = () => {
    return (
      <>
        <HamburgerMenu {...this.props} open={this.state.open} handleMenu={this.handleMenu} themeDark={this.state.darkTheme} handleTheme={this.handleTheme} />
        <NavigationMenu navigation={this.props.navigation} id={""} theme={this.state.darkTheme} open={this.state.open} />
        <MainContainer style={{ paddingLeft: this.state.open ? 227 : 95, transition: 'all 300ms linear' }}>
        <ToastContainer 
  position="top-center" 
  style={{ top: "70px",height:"42px" }} 
/>
        <Box style={{ display: "flex", width: "85%", alignItems: "center", justifyContent: "space-between" }}>
  
  <HeaderDetailsBox>
    <HeaderTypo>List of Courses</HeaderTypo>
    <HeaderLineTypo>Here you can find the courses that have been assigned to you</HeaderLineTypo>
  </HeaderDetailsBox>
{this.state.roleId==="instructor" &&
  <CreateCourseButton 
    onClick={this.goToPostCreationPage} 
    data-test-id="createPost"
  >
    <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <img src={CourseIcon} style={{ width: "24px", height: "24px" }} />
      <Typography style={{ fontFamily: "Rubik", fontSize: "16px", fontWeight: "500" }}>
        Create Courses
      </Typography>
    </Box>
  </CreateCourseButton>
}
  
</Box>


          <AllCoursesBox>
            <FilterContainer>
              {this.LeftFilterOptions()}
              {this.RightFilterOptions()}
            </FilterContainer>

            <CourseSectionBox>
              {this.renderEachCourseBox(this.state.currentPageData)}
            </CourseSectionBox>
            <PaginationBox>
              <PaginationBoxContent>
                {this.state.totalPage >= 2 && (
                  <Pagination
                    count={this.state.totalPage}
                    onChange={this.changePage}
                    data-test-id="pagination"
                  />
                )}
              </PaginationBoxContent>
              <Box>
                {this.state.currentPageData.length > 0 &&
                <PageSizeBox onClick={this.togglePageDropdown}>
                  <PageSizeTypography>Page Size</PageSizeTypography>
                  <KeyboardArrowDownIcon height="5.5px" width='9.5px' />
                  {this.state.isPageDropdownOpen && this.renderPageSize()}
                </PageSizeBox>
                }
              </Box>
            </PaginationBox>
          </AllCoursesBox>
        </MainContainer>
      </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
          <Box>

            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <Box>
              {this.renderMainComponent()}
            </Box>
            {/* Merge Engine UI Engine Code */}
            {/* Customizable Area End */}
          </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)(({ theme }) => ({
 justifyContent:"flex-start",minHeight: '100vh', alignItems:"center",display:"flex",flexDirection:"column",gap:"20px",backgroundColor:theme.palette.background.default
}))
const HeaderBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "113px",
    backgroundColor:theme.palette.background.paper,
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-start",
    justifyContent:"center",
    padding:"27px 37.78px",
    borderRadius:"18px"
}))
const HeaderDetailsBox = styled(Box)(({ theme }) => ({
  width: "632px",
  height: "59px",
  padding: "0px 301px 0px 0px",
  gap: "0px",
  opacity: "0px",
  display: "flex",
  flexDirection: "column",
  alignItems: "self-start",
  justifyContent: "center"
}))
const HeaderTypo = styled(Typography)(({ theme }) => ({
  width: "315px",
  height: "30px",
  top: "5px",
  left: "1px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "30px",
  fontWeight: 500,
  lineHeight: "30px",
  letterSpacing: "0.41111114621162415px",
  textAlign: "left",
  color: theme.palette.text.hint,
}))
const HeaderLineTypo = styled(Typography)(({ theme }) => ({
  width: "510px",
  height: "14px",
  top: "43px",
  left: "1px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "14px",
  letterSpacing: "0.41111114621162415px",
  textAlign: "left",
  color: theme.palette.text.hint
}))

export const FilterSelect = styled(Select)(({ theme }) => ({
  marginLeft: "6px",
  width: "176px",
  maxWidth: "176px",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.info.main,

  "& .ant-select-selection-placeholder" : {
color: 'rgba(71, 85, 105, 1) !important'
},

"& .ant-select:not(.ant-select-customize-input) .ant-select-selection-placeholder": {
  color: "red !important",
},

"& .ant-select:not(.ant-select-customize-input) .ant-select-selector":{
    borderRadius:"30px !important",
    borderColor: 'gery !important',
    color: 'rgba(26, 70, 156, 1) !important',
  },
  "& .ant-select-selector":{
    borderRadius:"30px !important",
    color: 'rgba(26, 70, 156, 1)',
  },

  "& .ant-select-dropdown": {
    height: "50px !important", 
    overflowY: "auto",
    borderRadius:"30px !important", 
  }
}));

const CourseOptionStyle = styled(Select.Option)(({ theme }) => ({
  height: "150px",
  overflowY: "auto",
  backgroundColor: "black",
}))


const AllCoursesBox = styled(Box)(({ theme }) => ({
  width: "90%",
  padding: "27px 34px 0px 34px",
  gap: "0px",
  borderRadius: "16.25px",
  opacity: "0px",
    backgroundColor:theme.palette.background.paper,
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center"
}))
const CourseSectionBox = styled(Box)(({ theme }) => ({
  width: "100%",
  gap: "8px",
  opacity: "0px",
  display: "flex",
  flexDirection: "column",
}))

const EachCourseBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100px",
  gap: "16px",
  borderRadius: "18px",
  opacity: "0px",
  backgroundColor: theme.palette.background.default,
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between",
    cursor:"pointer",
}))
const CourseDetailBox = styled(Box)(({ theme }) => ({
  height: "100px",
    width:"48%",
  padding: "10px 14px 10px 0px",
  gap: "4px",
  opacity: "0px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    alignItems:"center",
}))
const SubDetailBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "25px",
  gap: "0px",
  justifyContent: "space-between",
  opacity: "0px",
    display:"flex",
    flexDirection:"row",
}))
const CourseNameBox = styled(Box)(({ theme }) => ({
    width: "293px", height: "25px", gap: "0px", opacity: "0px",display:"flex",flexDirection:"column",alignItems:"flex-start",
    justifyContent:"center"
}))
const ExpirationProgressBox = styled(Box)(({ theme }) => ({
  width: "132.85px",
  height: "25.85px",
  padding: "0px 8px 0px 8px",
  gap: "12px",
  opacity: "0px",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
}))
const ExpirationBox = styled(Box)(({ theme }) => ({
  width: "74.85px",
  height: "25.85px",
  gap: "4px",
  opacity: "0px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "Center",
}))
const MessageBox = styled(Box)(({ theme }) => ({
  width: "169px",
  height: "26.06px",
  padding: "8.03px 8px 8.03px 8px",
  gap: "8.03px",
  borderRadius: "30.11px",
  opacity: "0px",
  backgroundColor: "#F9F9F9",
}))
const WarningTypo = styled(Typography)(({ theme }) => ({
  width: "153px",
  height: "10px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "8.03px",
  fontWeight: 500,
  lineHeight: "9.99px",
  textAlign: "left",
  color: "#DC2626"
}))
const YellowBox = styled(Box)(({ theme }) => ({
  height: "11.85px",
  padding: "1.48px 5.93px 1.48px 5.93px",
  gap: "1.48px",
  borderRadius: "29.63px",
  opacity: "0px",
  backgroundColor: "#FEF3C7"
}))
const YellowTypo = styled(Typography)(({ theme }) => ({
  height: "14px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "8.89px",
  fontWeight: 500,
  lineHeight: "13.33px",
  textAlign: "left",
  color: "#D97706",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))
const LinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  width: "100%",
  height: "4px",
  padding: "0px 251.39px 0px 0px",
  gap: "0px",
  borderRadius: "10px",
  opacity: "0px",
  [`& .MuiLinearProgress-colorPrimary`]: {
    backgroundColor: theme.palette.secondary.main,
  },
  [`& .MuiLinearProgress-bar`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.action.selected,
  },
}));
const LinearProgressBar1 = styled(LinearProgress)(({ theme }) => ({
  width: "100%",
  height: "4px",
  gap: "0px",
  borderRadius: "10px",
  opacity: "0px",
  [`& .MuiLinearProgress-colorPrimary`]: {
    backgroundColor: theme.palette.secondary.main,
  },
  [`& .MuiLinearProgress-bar`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.action.selected,
  },
}));

const RedBox = styled(Box)(({ theme }) => ({
  width: "74.85px",
  height: "11.85px",
  padding: "1.48px 5.93px 1.48px 5.93px",
  gap: "1.48px",
  borderRadius: "29.63px",
  opacity: "0px",
  backgroundColor: "#FEE2E2"
}))
const RedTypo = styled(Typography)(({ theme }) => ({
  width: "63px",
  height: "14px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "8.89px",
  fontWeight: 500,
  lineHeight: "13.33px",
  textAlign: "left",
  color: "#DC2626"
}))
const GreenBox = styled(Box)(({ theme }) => ({
  width: "74.85px",
  height: "11.85px",
  padding: "1.48px 5.93px 1.48px 5.93px",
  gap: "1.48px",
  borderRadius: "29.63px",
  opacity: "0px",
  backgroundColor: "#D1FAE5"
}))

const BoxUpper1 = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "20px",
  width: "100%"
}))

const DownLoadTiltle = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "18px",
  letterSpacing: "0%"
}))
const DownloadListValue=styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
fontWeight: 700,
fontSize: "14px",
lineHeight: "18px",
letterSpacing: "0%"
}))
const GreenTypo = styled(Typography)(({ theme }) => ({
  width: "63px",
  height: "14px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "8.89px",
  fontWeight: 500,
  lineHeight: "13.33px",
  textAlign: "left",
  color: "#059669"
}))
const RowDownloadWorker = styled(Typography)(({ theme }) => ({
  height: "47.64px",
                  paddingBottom: "20px",
                  borderBottom: "1px solid var(--Dashboard-Statics-Button-card-Card-Colourz, #F9F9F9)",
                  background: "var(--Dashboard-Statics-Button-card-Card-Colourz, #F9F9F9)",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
}))

const CourseNameTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  textAlign: "left",
  width: "293px",
  height: "12px",
  gap: "0px",
  opacity: "0px",
    color: theme.palette.text.secondary
}))

const NameTypo = styled(Typography)(({ theme }) => ({
  width: "293px",
  height: "18px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const ProgressTypo = styled(Typography)(({ theme }) => ({
  width: "30px",
  height: "14px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "14.22px",
  textAlign: "left",
  color: theme.palette.text.secondary
}))
const CourseTabSection = styled(Box)(({ theme }) => ({
  gap: '6px',
  height: "40px",
  padding: "4px",
  borderRadius: "50px",
  justifyContent: "space-between",
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    backgroundColor:theme.palette.background.default
}))
const SelectedFilter = styled(Box)(({ theme }) => ({
  height: "35px",
  padding: "6px 12px 6px 12px",
  gap: "6px",
  borderRadius: "50px",
  opacity: "0px",
  backgroundColor: "#EDF1F6",
  display: "flex",
  alignItems: "center",
  cursor: "pointer" 
}))
const SelectedFilterTypo = styled(Typography)(({ theme }) => ({
  width: "74px",
  height: "22px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  textAlign: "left",
  color: theme.palette.action.selected,
  display: 'flex',
  justifyContent: 'center'
}))
const DeselectedFilter = styled(Box)(({ theme }) => ({
  width: "95px",
  height: "35px",
  padding: "6px 12px 6px 12px",
  gap: "6px",
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer" 
}))
const DeselectedFilterTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  textAlign: "left",
  width: "71px",
  height: "22px",
  color:"#475569",
  display: 'flex',
  justifyContent: 'center'
}))

export const FilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "10px",
    width:"100%",
    alignItems:"center",
    fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  
    "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
    borderRadius: "30px",
    height: "43px",
    paddingTop: "5px",
    borderColor: "transparent",
     border: 'none !important',
     transition: ' !none'
  },
  "& .ant-select-focused .ant-select-selector": {
    borderColor: "transparent !important", 
    backgroundColor: 'rgba(232, 237, 245, 1)',
    border: 'none !important',
    boxShadow: 'none !important',
    color: 'rgba(26, 70, 156, 1)'
  },

"& .ant-select-selection-placeholder": {
  color: "rgba(71, 85, 105, 1) !important",  
},

"& .ant-select-focused .ant-select-selection-placeholder": {
  color: "rgba(26, 70, 156, 1) !important",
},

  "& .MuiInput-underline::before": {
    borderBottom: "transparent",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
    borderBottom: "transparent",
  },
  "& .MuiInput-underline::after": {
    borderBottom: "transparent",
  },
  "& .ant-select-arrow": {
    color: theme.palette.info.main
  },
  "& .selectedOption": {
    background: '#1A469C !important',
    color: '#F8FAFC !important'
  },
  "& .selectFilter .ant-select-selector": {
    background: `${theme.palette.background.paper} !important`,
    color: `${theme.palette.info.contrastText} !important`
  },
}));
const PaginationBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "29px",
  marginTop: "2rem",
  marginBottom: "1rem",
  display: "flex",
  justifyContent: "center",
  opacity: "0px",
}));
const PaginationBoxContent = styled(Box)(({ theme }) => ({
  width: '80%',
  display: 'flex',
  justifyContent: 'center',
  '& .MuiPagination-root': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '18px'
  },
  '& .MuiPagination-ul': {
    gap: '1rem'
  },
  '& .MuiPaginationItem-root': {
    borderRadius: '8px',
    backgroundColor: 'rgba(241, 245, 249, 1)',
    color: 'rgba(67, 83, 106, 1)',
    minWidth: '18px',
    height: '18px',
    fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  '& .MuiPaginationItem-page.Mui-selected': {
    borderRadius: '8px',
    backgroundColor: theme.palette.action.selected,
    color: 'rgba(248, 250, 252, 1)',
    minWidth: '18px',
    height: '18px',
    fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "18px",
  },

}));

const PageSizeBox = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: '95px',
  height: '29px',
  borderRadius: "30px",
  padding: '12px 8px',
  backgroundColor: theme.palette.primary.main,
  textAlign: 'left',
  color: 'rgba(71, 85, 105, 1)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const PageSizeTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  color: 'rgba(71, 85, 105, 1)'
}));

const Dropdown = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: 220,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  textAlign: 'left',
  marginTop: "50px"
}));



const SortText = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  height: "40px",
  paddingLeft: "16px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left",
  display: 'flex',
  alignItems: 'center',
  "&:hover": {
    borderRadius: '8px',
    backgroundColor: "#E8EDF5",
  },
  color: theme.palette.info.light
}))

export const EditDescription = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '14px',
  letterSpacing: '0.4px',
  textAlign: 'left',
  marginTop: '5px',
  color: theme.palette.secondary.main
}))
const ExpirationLabel = styled(Typography)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: theme.palette.info.contrastText,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
  borderRadius: '26px',
  padding: '10px 12px',
  marginLeft: '5px',
  cursor: 'pointer'
}))
const ResetText = styled(Button)(({ theme }) => ({
  fontSize: "16",
  fontWeight: 500,
  textTransform: 'capitalize',
  color: theme.palette.primary.contrastText
}))

const SearchBox = styled(TextField)({
  minWidth: "230px",
  height: "44px",
  padding: "8px",
  borderRadius: "18px",
  border: `1px solid ${theme.palette.info.dark} `,
  backgroundColor: "#FFF",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  paddingLeft: "50px",
  paddingTop: "4px",
  paddingBottom: "4px",
  '&::placeholder': {
    color: theme.palette.primary.main
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: "relative",
  borderRadius: "50px",
  backgroundColor: theme.palette.background.default,
  cursor: "pointer",
  "&:hover": {
    border: `2px solid ${theme.palette.action.selected}`,
  },
  marginRight: "16px",
  marginLeft: 0,
  width: "100%",
  "@media (min-width:600px)": {
    marginLeft: "24px",
    width: "auto",
  },
  color: "rgb(140, 140, 140)",
  border: "1px solid #CBD5E1",
}));

const SearchIconWrapper = styled("div")({
  padding: "0 16px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "gray"
});

const ResetTypo = styled(Typography)(({ theme }) => ({
  color: 'rgba(220, 38, 38, 1)',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 500,
  borderRadius: '26px',
  cursor: 'pointer'
}))


const RemindText = styled(Button)(({ theme }) => ({
  fontSize: "14",
  fontWeight: 500,
  textTransform: 'capitalize',
  color: theme.palette.info.contrastText
}))

const SubText = styled(Typography)(({ theme }) => ({
  borderRadius: 8,
  border: '1px solid #CBD5E1',
  padding: '5px 15px',
  color: theme.palette.info.main,
  lineHeight: 1,
  fontWeight: 400,
  fontFamily: "Rubik",
  fontSize: 16,
  backgroundColor: theme.palette.background.default,
  cursor: 'pointer',
  '& .day-dropbox p': {
    cursor: 'pointer'
  }
}))

const DayList = styled(Typography)(({ theme, themeDark }: any) => ({
  padding: '13px 16px',
  cursor: 'pointer',
  color: theme.palette.info.light,
  fontWeight: 400,
  fontSize: 14,
  fontFamily: "Rubik",
  '&:hover': {
    background: themeDark ? "#121B29" : "#E8EDF5",
    color: themeDark ? "#70A1FF" : "#1A469C",
    borderRadius: 8
  }
}))
const DropdownBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 9999,
  boxShadow: '0px 2px 4px 0px #00000026',
  top: 48,
  right: -8,
  borderRadius: 20,
  minWidth: 200,
  padding: 2,
  background: theme.palette.background.default,
  '&.dropdown-height': {
    height: 200,
    overflow: 'scroll'
  }
}))

const FontStyle = {
  fontFamily: 'Rubik',
  h1: { fontSize: '32px' },
  h2: { fontSize: '30px' },
  h3: { fontSize: '28px' },
  h4: { fontSize: '26px' },
  h5: { fontSize: '24px' },
  h6: { fontSize: '22px' },
  subtitle1: { fontSize: '20px' },
  subtitle2: { fontSize: '18px' },
  body1: { fontSize: '16px' },
  body2: { fontSize: '14px' }
}

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
            light:"010101"
    },
    secondary: {
      main: '#FFFFFF',
            light:"#D1FAE5",
            dark:"#0F172A"
    },
    background: {
      default: '#FFFFFF',
      paper: "#F9F9F9"
    },
    text: {
      primary: '#334155',
      secondary: '#475569',
      hint: '#0F172A',
    },
    action: {
      active: '#F1F5F9',
      selected: '#1A469C',
      focus: '#059669',
      disabled: '#475569'
    },
    info: {
      main: "#21272A",
      light: '#848484',
      dark: '#CBD5E1',
            contrastText:'#1A469C'
    },
  },
  typography: FontStyle
});
export const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#161616',
            light:"#BDCFF4"
    },
    secondary: {
      main: '#2F2F2F',
            light:"#074525",
            dark:"#BDCFF4"
    },
    background: {
      default: '#0D0D0D',
      paper: 'rgba(26,26,26,1)',
    },
    text: {
      primary: '#D5DDF0',
      secondary: '#93A1B5',
      hint: '#D5DDF0'
    },
    action: {
      active: '#70A1FF',
      selected: '#70A1FF',
      focus: '#42EBB5',
      disabled: '#3C3E49'
    },
    info: {
      main: "#D5DDF0",
      light: '#99A7BB',
      dark: '#273444',
            contrastText:'#12326F'
    },
  },
  typography: FontStyle
});

const webStyle = {
  mainWrapper: {
    fontFamily: "Roboto-Medium",
    alignItems: "center",
    paddingBottom: "30px",
    width: "100%",
    padding: 0,
    margin: 0,
    color: "black",
    backgroundColor: "white",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  selectStyle: {
    color: "#303233",
    text: "#303233",
    backgroundColor: "lightblue",
  },
  modal: {
    backgroundColor: "lightblue",
    alignSelf: "center",
    alignItems: "center",
    width: "75%",
    justifyContent: "space-between",
    margin: "2em",
  },
};
const CardCourse=styled(Box)(({theme})=>({
  background: theme.palette.background.default,
}))
export const CreateCourseButton = styled(Button)(({ theme }) => ({
  width: "200px",
  height: "44px",
  padding: "10px 16px",
  gap: "8px",
  borderRadius: "30px",
  border: "1px solid #1A469C", // Border color
  background: "#FFFFFF", // White background
  color: "#1A469C", // Text color
  textTransform: "capitalize",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "center",

  // "&:hover": {
  //   background: "#1A469C", // Change background on hover
  //   color: "#FFFFFF", // White text on hover
  // }
}));

// Customizable Area End
