import React from "react";
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Card,
  CardMedia,
  CardContent
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { viewAllIcon,back} from "./assets";
import CourseFeedbackController,{IProps} from "./CourseFeedbackController.web"
const ConfigJSON = require('./config.js')
const img1 = "https://s3-alpha-sig.figma.com/img/284a/e4d7/913a6126e69af2d70a988d275522e7db?Expires=1740960000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=a9hqWlHgYfy6-Us1mBtLq1cCTkmuYrJmsYjyGGaXZ05zcPjrxt-OryWq7eBUAO--rMqneAYGvJhPuagHXPzE3jcjRUWr7Ep787fIexHoe4IRiJvBfkKzhYI3XM940J~hzu4nBXy6ce9bimvksIfgFYQ3ZOaMTZVZAwkx47FYvqfCjhGCST7bNxhpSA7lDj35ZOin3MpUk1-0ReFWTc5aB8Gmr5UshLIxJT-elEBVnYNtRfDadNApoBLGiIoQlW-y8rhPn70cC3guHnpA6cPzumoASnyclh4iHZElnhr6ScFbhzOpkVplAI1BwTunQ1RgnE4EjTe-i2StlFf0Wjo~0w__";






const PageWrapper = styled(Box)({
  maxWidth: "100%",
  margin: "0 auto",
  padding: "24px",
  fontFamily: "Rubik, sans-serif"
});

const MainContainer = styled(Box)({
  background: "#F9F9F9",
  borderRadius: 16,
  padding: "24px",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)"
});

const Header = styled(Box)({
  marginBottom: 16,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});

const HeadingText = styled(Typography)({
  fontSize: 24,
  fontWeight: 600,
  color: "#0F172A"
});

const StyledTabs = styled(Tabs)({
  marginBottom: 24,
  borderBottom: "1px solid #E2E8F0",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1A469C",
    height: 3,
    borderRadius: 3
  }
});

const StyledTab = styled(Tab)({
  textTransform: "none",
  minWidth: 72,
  fontWeight: 500,
  fontSize: 16,
  "&:hover": {
    color: "#1A469C",
    opacity: 1
  },
  "&.Mui-selected": {
    color: "#1A469C"
  }
});

const CardsGrid = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "90px",
  maxHeight: 400,
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: 8
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#CBD5E1",
    borderRadius: 4
  }
});

const StyledCard = styled(Card)({
  width: 280,
  height: 380,
  borderRadius: 30,
  display: "flex",
  flexDirection: "column",
  background: "#FFFFFF"
});

const StyledCardMedia = styled(CardMedia)({
  height: 230,
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  borderBottomRightRadius: 30,
  borderBottomLeftRadius: 30
});

const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flexGrow: 1
});

const ReviewText = styled(Typography)({
  fontSize: 16,
  fontWeight: 700,
  marginBottom: 8,
  color: "#0F172A"
});

const CourseName = styled(Typography)({
  fontSize: 14,
  color: "#475569"
});

const StatusBadge = styled("span")({
    fontSize: 12,
    fontWeight: 700,
    color: "#059669",
    background: "#D1FAE5",
    borderRadius: 8,
    padding: "4px 8px",
    display: "inline-block", 
    marginTop: 8,
    marginLeft: "auto"
});

const CourseInfoSection = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  paddingLeft: "6px"
});
const RowStack = styled(Box)({ display: "flex" });
const CourseTitleSection = styled(RowStack)({
  alignItems: "center",
  gap: "8px"
});
const StepperContainer = styled(RowStack)({ width: "100%" })
const CourseInfoTitle = styled(Typography)(({ theme }) => ({
  fontSize: "32px",
  lineHeight: "38px",
  fontWeight: 500,
  color: "#0F172A",
  width:"35%"
}));

const ArrowBtn = styled(Button)({
  marginLeft: "auto",
  marginTop: "auto",
  padding: 0,
  minWidth: "unset",
  color: "#1A469C",
  "&:hover": {
    backgroundColor: "transparent"
  }
});
const StepperItem = styled(Box)({
  width: '97px',
  paddingBottom: '12px',
  fontSize: '12px',
  fontWeight: 'bolder',
  lineHeight: '18px',
  textAlign: 'center',
})

 
class CourseFeedback extends CourseFeedbackController {
  constructor(props: IProps) {
    super(props);
  }
   FeedbackReviewPage= () => {
    return (<>
      <PageWrapper>
      <MainContainer>
            <CourseInfoSection style={{ padding: 0 }}>
          <RowStack style={{ gap: "12px", alignItems: "center" ,width:"100%"}}>
            <img src={back}/>
            <CourseInfoTitle> Feedback</CourseInfoTitle>
            <hr style={{ color: "#D7D7D780", width: "100%", height: "1px" }} />
          </RowStack>
        </CourseInfoSection>
            
          <Box marginTop="24px" marginBottom="16px">
  
            <Box display="flex" justifyContent="space-between" alignItems="center">
  
              <Box display="flex" alignItems="center">
                <StyledCardMedia
                data-test-id="showFeedback1"
                  style={{
                    width: "32.89px",
                    height: "32.89px",
                    borderRadius: "24.66px",
                    marginRight: "8px",
                  }}
                  image={this.state.showFeedBack.attributes.feedback_by.attributes.image || img1}
                  onClick={() => this.setState({showFeedbackPage:false})}
                />
  
                <Typography variant="body2" style={{ color: "#1A469C" }}>
                  Reviewed Yesterday at 4:54 PM by {this.state.showFeedBack.attributes.feedback_by.attributes.first_name}
                </Typography>
              </Box>
  
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  flexGrow: 1,
                }}
              >
                The Review
              </Typography>
            </Box>
  
          </Box>
  
  
    <Box marginBottom="16px">
      <Typography variant="body2" style={{ color: "#0F172A", marginBottom: 8,textAlign:"center" }}>
       {this.state.showFeedBack.attributes.message.length === 0? <>"There is no feedback for this course."</>:<>{this.state.showFeedBack.attributes.message}</>}
      </Typography>
    </Box>
    
  
    <hr style={{ color: "#D7D7D780", width: "100%", height: "1px" }} />
    <Box display="flex" alignItems="center" justifyContent="space-between">
    <Box style={{ display: "flex", alignItems: "center", justifyItems:"center" }}>
    <Typography variant="body2" style={{ fontWeight: 500, fontSize: "14px" }}>
      Status 
    </Typography>
    <StatusBadge style={{ color: "#DC2626", background: "#FEE2E2",marginTop:"0px", marginLeft:"5px" }}>
       REJECTED
    </StatusBadge>
  </Box>
  
     
      <Button
        variant="contained"
        style={{
          backgroundColor: "#1A469C",
          color: "#FFFFFF",
          textTransform: "none",
          fontWeight: 800,
          width:'300px',
          height:"44px",
          borderRadius:'30px',
          padding:"10px 16px 10px 16px"
        }}
        onClick={this.goToCurriculum}
      >
        Go to Curriculum
      </Button>
    </Box>
  </MainContainer>
  </PageWrapper>
  </>
    );
  };
  render() {
    const { activeTab, showFeedbackPage } = this.state;
    



if (showFeedbackPage) {
  return (
    <PageWrapper>
      {this.FeedbackReviewPage()}
      </PageWrapper>
  );
}
return (
  <PageWrapper>
    <MainContainer>
      <CourseInfoSection style={{ padding: 0 }}>
        <RowStack style={{ gap: "12px", alignItems: "center" ,width:"100%"}}>
          <img src={back}/>
          <CourseInfoTitle>Course Feedback</CourseInfoTitle>
          <hr style={{ color: "#D7D7D780", width: "100%", height: "1px" }} />
        </RowStack>
      </CourseInfoSection>
      <StyledTabs value={activeTab} onChange={this.handleChangeTab}>
        <StyledTab label="Approved" />
        <StyledTab label=" Rejected" />
      </StyledTabs>
      <CardsGrid>
        {this.state.showCourseData.map((card) => (
          
          <StyledCard key={card.id}>
            <StyledCardMedia image={card.attributes.image ||img1} />
            <StyledCardContent>
              {card.attributes.status === "revision_rejected"&&<ArrowBtn onClick={()=>this.handleArrowClick(card.id)}>
                <img
                  src={viewAllIcon}
                  style={{ width: "28px", height: "28px" }}
                  alt="View"
                />
              </ArrowBtn>}
              <ReviewText>{card.reviewLabel}Review </ReviewText>
              <CourseName>Course Name:</CourseName>
              <CourseName>{card.attributes.course_name}</CourseName>

              {card.attributes.status === "revision_rejected" ? (
                <StatusBadge
                  style={{
                    color: "#DC2626",
                    background: "#FEE2E2"
                  }}
                >
                 REJECTED
                </StatusBadge>
              ) : (
                <StatusBadge>ASSIGNED</StatusBadge>
              )}
            </StyledCardContent>
          </StyledCard>
        ))}
      </CardsGrid>
    </MainContainer>
  </PageWrapper>
);
  }
}

export default CourseFeedback;