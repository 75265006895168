import React from 'react';

// Customizable Area Start
import {
  Container,
  Box,
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Typography,  
  styled,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,Button,
  Paper
} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Select } from "antd";
import SearchIcon from '@material-ui/icons/Search';
import { arrowRight, order, profileImage } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import OutsideAlerter from "../../utilities/src/OutsideAlerter";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Pagination } from "@material-ui/lab";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { IStudentDetail } from './types/types';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});
const sortOptions = [
  { value: "asc", label: "Ascending" },
  { value: "desc", label: "Descending" },
];
// Customizable Area End

import PtStudentInfoScreenController, {
  Props,
  TeamMember,
  configJSON,
} from './PtStudentInfoScreenController';

export default class PtStudentInfoScreen extends PtStudentInfoScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  LeftFilterOptions = () => {
    return <div style={{ display: 'flex' }}>
      <EditDescription style={{ fontSize: "16px", lineHeight: "24px", marginRight: "5px" }}>
        Sort by:
      </EditDescription>
      {this.state.selectedRadio.length === 0 && <ExpirationLabel
        data-test-id="enableExpiry1"
        className={this.state.orderBy === "courses_count" ? "selectedOption" : ""}
        onClick={() => this.handleOrderBy("courses_count")}
      >
        Num. of Courses {this.state.orderBy === "courses_count" && 'x'}
      </ExpirationLabel>}
      {this.state.selectedRadio.length === 0 && <ExpirationLabel
        data-test-id="enableExpiry2"
        className={this.state.orderBy === "no_of_certificates" ? "selectedOption" : ""}
        onClick={() => this.handleOrderBy("no_of_certificates")}
      >
        Num. of Certificates {this.state.orderBy === "no_of_certificates" && 'x'}
      </ExpirationLabel>}
      {this.state.selectedRadio.length === 0 && <ExpirationLabel
        data-test-id="enableExpiry3"
        className={this.state.orderBy === "overall_progress" ? "selectedOption" : ""}
        onClick={() => this.handleOrderBy("overall_progress")}
      >
        Overall Progress {this.state.orderBy === "overall_progress" && 'x'}
      </ExpirationLabel>}
      {
        (this.state.orderBy !== "" || this.state.searchValue.length > 0) && (
          <ResetButtonText
            data-test-id="enableExpiry4"
            onClick={() => this.resetFilters()}
          >
            Reset
          </ResetButtonText>
        )
      }
    </div>
  }

  RightFilterOptions = () => {
    const open = Boolean(this.state.anchorSort);
    return <div style={{ display: 'flex', alignItems: 'center',justifyContent:"space-between",gap:"10px" }}>
      {this.state.selectedRadio.length > 0 && <RemindText>Send Reminder</RemindText>}
      {(this.state.selectedCompany || this.state.selectedCourse || this.state.selectedGroup || this.state.selectedStatus || this.state.enableExpDate || this.state.enableRedLabel || (this.state.selectedRadio.length > 0)) && <ResetText data-test-id="resetValues" variant="text">Reset</ResetText>}
        <SearchBox
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ fill: "#1A469C", width: "24px", height: '24px' }} />
              </InputAdornment>
            )
          }}
          data-test-id="searchParam"
          placeholder="Search"
          style={{ width: '200px' }}
          value={this.state.searchValue}
          onChange={(e) => this.setState({ searchValue: e.target.value, currentPage: 1 }, () => this.getTeamMembers())}
        /> 
        <>
        <img
          src={order}
          alt="sort"
          style={{ cursor: "pointer" }}
          data-test-id="openAnchorSort"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event: React.MouseEvent<HTMLImageElement>) => {
            this.setState({ anchorSort: event?.currentTarget })
          }}
        />
        <Menu
          data-test-id="closeSort"
          id="basic-menu"
          anchorEl={this.state.anchorSort}
          open={Boolean(this.state.anchorSort)}
          onClose={() => this.setState({ anchorSort: null })}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          style={{ top: "90px" }}
        >
          <MenuItem disabled>
            SORT BY
          </MenuItem>
          {sortOptions.map((item) => (
            <MenuItem data-test-id={`btn-sort-type-${item.label}`} onClick={() => this.handleOrderByType(item.value)}>
              <span
                data-test-id="sortEmployee"
                style={{
                  color: "#1A469C",
                  fontFamily: "Rubik",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "22px",
                }}
              >
                {item.label}
              </span>
            </MenuItem>
          ))}
        </Menu> </>
    </div>
  }
  returnEachTeamBox=(teamMember: TeamMember)=>{
    return (
      <EachMemberBox key={teamMember.id}>
          <MemberIdBox>
            <IdBox>
              <IdHead>ID</IdHead>
              <IdTypo>{teamMember.attributes.id}</IdTypo>
            </IdBox>
            <NameProfileBox>
              <img src={profileImage} style={{width:"34px",height:"34px"}}/>
              <OnlyNameBox>
                <IdHead>Dep/Worker</IdHead>
                <IdTypo>{teamMember.attributes.first_name} {teamMember.attributes.last_name}</IdTypo>
              </OnlyNameBox>
            </NameProfileBox>
          </MemberIdBox>
          <GroupBox>
              <IdHead>Group Name</IdHead>
              <IdTypo>{teamMember.attributes.group_names}</IdTypo>
          </GroupBox>
          <CertificateBox>
              <IdHead>Num. Of Courses</IdHead>
              <IdTypo>{teamMember.attributes.courses_count}</IdTypo>
          </CertificateBox>
         <CertificateBox>
              <IdHead>No. of Certificates</IdHead>
              <IdTypo>{teamMember.attributes.number_of_certificates}</IdTypo>
          </CertificateBox>
          <ProgressBox>
            <OverallBox>
              <IdHead>Overall Progress</IdHead>
              <IdHead>{teamMember.attributes.overall_progress.toFixed(2)}%</IdHead>
            </OverallBox>
            <LinearProgressBar variant="determinate" value={teamMember.attributes.overall_progress}></LinearProgressBar>
          </ProgressBox>
          <img data-test-id={`btnGoToPerformaceTracker-${teamMember.attributes.id}`} onClick={() => this.goToPerformaceTracker(teamMember.attributes.id)} src={arrowRight} style={{width:"34px",height:"34px"}}/>
        </EachMemberBox>
    )
  }
  renderTeamMembersTable=()=>{
    return (
      <TeamMemberTableBox>
        {
          this.state.teamMembers.map(teamMember => {
            return (
              this.returnEachTeamBox(teamMember)
            )
          })
        }
      </TeamMemberTableBox>
    )
  }

  renderPageSize = () => {
    const options = [10, 25, 50, 100];

    return (
      <OutsideAlerter onOutsideClick={this.togglePageDropdown}>
        <Dropdown style={{ height: "160px", width: '55px', zIndex: 100, top: -225, right: 0 }} >
          {options.map((option) => (
            <SortText
              key={option}
              data-test-id={`page-size-dropdown-item-${option}`}
              onClick={() => this.handlePageSizeChange(option)}
              style={{
                borderRadius: '8px',
                backgroundColor: this.state.selectedPageSize === option ? "#E8EDF5" : "",
                color: this.state.selectedPageSize === option ? "#1A469C" : "#475569",
              }}
            >
              {option}
            </SortText>
          ))}
        </Dropdown>
      </OutsideAlerter>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
      <HamburgerMenu {...this.props} open={this.state.open} handleMenu={this.handleMenu}  themeDark={this.state.darkTheme} handleTheme={this.handleTheme}/>
      <NavigationMenu navigation={this.props.navigation} id={""} theme={this.state.darkTheme} open={this.state.open} />
     <TeamsBoxContainer style={{ paddingLeft: this.state.open ? 273 : 130, paddingRight: 48, transition: 'all 300ms linear' }}>
      <HeadContainer>
      <SubSection>
        <div>
          <EditHeading>Team Members</EditHeading>
          <EditDescription>
            Here where you can see all members and track all your workers progress.
          </EditDescription>
        </div>
        <div>
        <FilterSelect
            data-test-id="Group"
            className={this.state.selectedGroup ? "selectFilter" : ""}
            value={this.state.selectedGroup || undefined}
            
            placeholder="Group Name"
            dropdownMatchSelectWidth={false}
          >
            {this.state.allGroupName?.map((item) => (
              <Select.Option value={item.group_name}>
                {item.group_name}
              </Select.Option>
            ))}
        </FilterSelect>
        </div>
      </SubSection>
      </HeadContainer>
      <TeamContainer>        
        <MainContainer style={{ flexDirection: 'column',display:"flex",justifyContent:"space-between",alignItems:"center", maxWidth: '100%',  padding: 0 }}>
          <FilterContainer>
            {this.LeftFilterOptions()}
            {this.RightFilterOptions()}
          </FilterContainer>
        </MainContainer>
        {this.renderTeamMembersTable()}
        <PaginationBox>
          <div></div>
          <Pagination
            style={{ display: 'flex', alignSelf: "center" }}
            data-test-id="changePage"
            count={this.state.totalPage}
            page={this.state.currentPage}
            onChange={(_, page) => this.setState({
              currentPage: page
            }, () => this.getTeamMembers())}
          />
          <Box>
            <PageSizeBox data-test-id="btn-open-pagination-dropdown" onClick={this.togglePageDropdown}>
              <PageSizeTypography>Page Size</PageSizeTypography>
              <KeyboardArrowDownIcon height="5.5px" width='9.5px' />
              {this.state.isPageDropdownOpen && this.renderPageSize()}
            </PageSizeBox>
          </Box>
        </PaginationBox>
        </TeamContainer>

        </TeamsBoxContainer>
    </ThemeProvider>

    );
  }
  // Customizable Area End
}

// Customizable Area Start
const MemberIdBox = styled(Box)(({ theme }) => ({
  width: "196.09px",
  height: "47.64px",
  gap: "0px",
  justifyContent: "space-between",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  opacity: "0px"

}))
const LinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: "5.86px",
  borderRadius: "10px",
  padding: "0px 97.93px 0px 0px",
  backgroundColor: theme.palette.primary.contrastText,
  [`& .MuiLinearProgress-colorPrimary`]: {
    backgroundColor: theme.palette.primary.contrastText,
  },
  [`& .MuiLinearProgress-bar`]: {
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.dark,
  },
}));
const IdBox = styled(Box)(({ theme }) => ({
 width: "36px", height: "36px", gap: "0px", opacity: "0px",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start"
}))
const IdHead = styled(Typography)(({ theme }) => ({
   fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.success.main
}))

const IdTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.text.hint
}))

const NameProfileBox = styled(Box)(({ theme }) => ({
   width: "144px",
  height: "47.64px",
  gap: "14px",
  opacity: "0px",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"
}))

const OnlyNameBox = styled(Box)(({ theme }) => ({
 width: "96px", height: "36px", gap: "0px", opacity: "0px",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start"
}))
const GroupBox = styled(Box)(({ theme }) => ({
width: "94px", height: "36px", gap: "0px", opacity: "0px",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start"
}))
const CertificateBox = styled(Box)(({ theme }) => ({
 width: "112px", height: "36px", gap: "0px", opacity: "0px",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start"

}))
const ProgressBox = styled(Box)(({ theme }) => ({
  width: "233px",
  height: "29.86px",
  gap: "6px",
  opacity: "0px",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start"
}))
const OverallBox = styled(Box)(({ theme }) => ({
 width: "233px", height: "18px", gap: "0px", opacity: "0px",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"
}))

const EachMemberBox = styled(Box)(({ theme }) => ({
width:"100%",
 height: "63.64px",
 padding: "8px 32px 8px 32px",
 gap: "0px",
 borderRadius: "18px",
 justifyContent: "space-between",
 display:"flex",
 flexDirection:"row",
 opacity: "0px",
 backgroundColor: theme.palette.background.default
}))
const TeamTableBox = styled(Box)(({ theme }) => ({
  width: "1146px",
 height: "493.5px",
 gap: "8px",
 opacity: "0px",
 display:"flex",
 flexDirection:"column",
 justifyContent:"center",
 alignItems:"center",
}))

const TeamMemberTableBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
 maxHeight: "493.5px",
 gap: "8px",
 opacity: "0px",
 display:"flex",
 flexDirection:"column",
//  justifyContent:"space-between",
 alignItems:"center",
 padding:" 0px 14px 41.16px 41.16px"
}))
const HeadContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width:"100%",
  justifyContent:"center",
}))

const SubSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  flexDirection:"row",
  justifyContent:"space-between",
  alignItems:"center",
  padding:"35px 38px",
  width: '100%',
  margin: '20px 0px',
  "& .MuiPaginationItem-page.Mui-selected": {
    background: theme.palette.action.selected,
    color: '#FFF',
    minWidth: '18px',
    height: '18px'
  },
  "& .MuiPaginationItem-root": {
    fontFamily: "Rubik",
    background: theme.palette.action.active,
    fontWeight: 500,
    lineHeight: "18px",
    fontSize: "10px",
    minWidth: '18px',
    height: '18px'
  },
  "& .MuiPagination-ul": {
    background: theme.palette.background.default,
    padding: '5px',
    borderRadius: '40px'
  },
  "& .MuiSvgIcon-root": {
    fill: theme.palette.secondary.main,
    width: '18px'
  },
  "& .MuiCircularProgress-root": {
    transform: 'rotate(120deg) !important'
  }
}))

export const EditHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '30px',
  fontWeight: 500,
  lineHeight: '30px',
  letterSpacing: '0.41111114621162415px',
  textAlign: 'left',
  color: theme.palette.secondary.main
}))

export const EditDescription = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '14px',
  letterSpacing: '0.4px',
  textAlign: 'left',
  marginTop: '5px',
  color: theme.palette.secondary.main
}))

const TeamsBoxContainer = styled(Box)(({ theme }) => ({
  gap: "0px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center",
  backgroundColor:theme.palette.action.disabledBackground
}))

export const MainContainer = styled(Container)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  "& .ant-select-dropdown": {
    width: 'auto !important',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '12px',
    minWidth: "200px",
    background: theme.palette.action.focus
  }
}))
export const TeamContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius:"16.25px",
  backgroundColor: theme.palette.error.contrastText,
  gap:"10px",

  "& .MuiPaginationItem-page.Mui-selected": {
    background: theme.palette.action.selected,
    color: '#FFF',
    minWidth: '18px',
    height: '18px'
  },
  "& .MuiPaginationItem-root": {
    fontFamily: "Rubik",
    background: theme.palette.action.active,
    fontWeight: 500,
    lineHeight: "18px",
    fontSize: "10px",
    minWidth: '18px',
    height: '18px'
  },
  "& .MuiPagination-ul": {
    background: theme.palette.background.default,
    padding: '5px',
    borderRadius: '40px'
  },
}))
const PaginationBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "29px",
  marginTop: "2rem",
  marginBottom: "1rem",
  display: "flex",
  justifyContent: "space-around",
  opacity: "0px",
}));
const PageSizeBox = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '95px',
  height: '29px',
  borderRadius: "30px",
  padding: '12px 8px',
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  textAlign: 'left',
  color: 'rgba(71, 85, 105, 1)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const PageSizeTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  color: 'rgba(71, 85, 105, 1)'
}));
const Dropdown = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: 220,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  textAlign: 'left',
  marginTop: "50px"
}));
const SortText = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  height: "40px",
  paddingLeft: "16px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left",
  display: 'flex',
  alignItems: 'center',
  "&:hover": {
    borderRadius: '8px',
    backgroundColor: "#E8EDF5",
  },
  color: theme.palette.info.light
}))
export const FilterSelect = styled(Select)(({ theme }) => ({
  marginLeft: "6px",
  width: "auto",
  maxWidth: "140px",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.info.main,
  "& .ant-select:not(.ant-select-customize-input) .ant-select-selector":{
    borderRadius:"30px !important"
  },
  "& .ant-select-selector":{
    borderRadius:"30px !important"
  },
}));

export const FilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "10px",
  width:"100%",
  alignItems:"center",
  borderRadius:"30px !important",
  padding:"25px 0px 40px 40px",
  "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
    borderRadius: "30px !important",
    height: "43px",
    paddingTop: "5px",
    background: theme.palette.background.default
  },
  "& .ant-select-selector":{
    borderRadius:"30px !important"
  },
  "& .MuiInput-underline::before": {
    borderBottom: "transparent",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
    borderBottom: "transparent",
  },
  "& .MuiInput-underline::after": {
    borderBottom: "transparent",
  },
  "& .ant-select-arrow": {
    color: theme.palette.info.main
  },
  "& .selectedOption": {
    background: '#1A469C !important',
    color: '#F8FAFC !important'
  },
  "& .selectFilter .ant-select-selector": {
    background: `${theme.palette.background.paper} !important`,
    color: `${theme.palette.info.contrastText} !important`
  }
}));

const RemindText = styled(Button)(({ theme }) => ({
  fontSize: "14",
  fontWeight: 500,
  textTransform: 'capitalize',
  color: theme.palette.info.contrastText
}))

const ExpirationLabel = styled(Typography)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: theme.palette.info.contrastText,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
  borderRadius: '26px',
  padding: '10px 12px',
  marginLeft: '5px',
  cursor: 'pointer'
}))

const ResetButtonText = styled(Typography)(({ theme }) => ({
  color: "#DC2626",
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 500,
  borderRadius: '26px',
  padding: '10px 12px',
  marginLeft: '5px',
  cursor: 'pointer'
}))
const ResetText = styled(Button)(({ theme }) => ({
  fontSize: "16",
  fontWeight: 500,
  textTransform: 'capitalize',
  color: theme.palette.primary.contrastText
}))

const SearchBox = styled(TextField)({
  minWidth: "230px",
  height: "44px",
  padding: "8px",
  borderRadius: "18px",
  border: "1px solid  #CBD5E1",
  backgroundColor: "#FFF",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
});

const FontStyle = {
  fontFamily: 'Rubik',
  h1: { fontSize: '32px' },
  h2: { fontSize: '30px' },
  h3: { fontSize: '28px' },
  h4: { fontSize: '26px' },
  h5: { fontSize: '24px' },
  h6: { fontSize: '22px' },
  subtitle1: { fontSize: '20px' },
  subtitle2: { fontSize: '18px' },
  body1: { fontSize: '16px' },
  body2: { fontSize: '14px' }
}

export const lightTheme = createTheme({
  palette: {
      primary: {
          main: '#FFFFFF',
          light: '#F8FAFC',
          dark: '#1A469C',
          contrastText: '#E2E8F0'
      },
      secondary: {
          dark: '#808080',
          light: '#848484',
          main: '#000000',
          contrastText: "#94A3B8"
      },
      background: {
          paper: '#EDF1F6',
          default: '#FFFFFF'
      },
      text: {
          hint: '#0F172A',
          secondary: '#43536A',
          primary: '#334155',
          disabled: "#F4F4F5"
      },
      action: {
          focus: '#F9F9F9',
          selected: '#1A469C',
          active: '#F1F5F9',
          disabled: '#D7D7D7',
          hover: "#3F3F46",
          disabledBackground: "#FFFFFF"
      },
      info: {
          light: '#475569',
          main: "#0F172A",
          contrastText: '#1A469C',
          dark: '#CBD5E1',
      },
      warning: {
          main: '#E8EDF5',
          light: "#059669",
          dark: '#D97706',
          contrastText: "#DDE1E6"
      },
      error: {
          main: "#FEE2E2",
          light: "#FEF3C7",
          dark: "#D1FAE5",
          contrastText: '#F9F9F9'
      },
      success: {
          main: "#475569"
      }
  },
  typography: FontStyle
});

export const darkTheme = createTheme({
  palette: {
      primary: {
          main: '#161616',
          light: '#0F172A',
          dark: '#12326F',
          contrastText: '#18212E'
      },
      secondary: {
          main: '#FFFFFF',
          dark: '#18212E',
          light: '#99A7BB',
          contrastText: "#94A3B8"
      },
      background: {
          default: '#000000',
          paper: '#121B29'
      },
      text: {
          primary: '#909EB2',
          secondary: '#C4CFE1',
          hint: '#D5DDF0',
          disabled: '#F7FBF7',
      },
      action: {
          active: '#424242',
          selected: '#12326F',
          focus: '#161616',
          disabled: '#D7D7D733',
          hover: "#393D42",
          disabledBackground: "#0D0D0D"
      },
      info: {
          main: "#D5DDF0",
          light: '#99A7BB',
          dark: '#273444',
          contrastText: '#70A1FF'
      },
      warning: {
          main: '#121B29',
          light: '#42EBB5',
          dark: "#F28407",
          contrastText: "#242A31"
      },
      error: {
          main: "#1C1C1C",
          light: "#514101",
          dark: "#074525",
          contrastText: "#161616"
      },
      success: {
          main: "#99A7BB"
      }
  },
  typography: FontStyle
});
const webStyle = {
  mainWrapper: {
    display: 'flex',
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonStyle: {
    width: '100%',
    height: '45px',
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)',
  },
};
// Customizable Area End
