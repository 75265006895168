import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities";
import { userProfile } from "./assets";
import { Linking } from "react-native";
import { CSSProperties } from "react";
export const webConfigJSON = require("./config.js");
interface ProfileData  {
  id: string;
  type: string;
  attributes: ProfileAttributes;
};
interface ProfileAttributes {
  id: number;
  first_name: string | null;
  last_name: string | null;
  email: string;
  date_of_birth: string;
  preferred_name: string;
  role_id: string;
  full_phone_number: string;
  employee_number: string | null;
  location: string | null;
  company_reference: string | null;
  completed_course: string | null;
  account_id: number;
  image: string | null;
  group_id: number;
  group_name: string;
  company_id: number;
  company_name: string;
  phone_number_verified: boolean;
  selected_theme: string;
  font_size: string;
}
interface DropdownState {
  anchorEl: null | HTMLElement;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  theme?:boolean;
  open?:boolean;
  filter?:string;
  navBarStyle?: CSSProperties;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  courseTab:boolean;
  profileData:ProfileData;
  errorMsg: string;
  open:boolean;
  darkTheme:boolean;
  isDashboardActive:boolean;
  isCourseActive:boolean;
  isBulletinActive:boolean;
  anchorEl: null | HTMLElement;
  openMenu:boolean;
  isModalOpen:boolean;
  openTermsModal:boolean;
  isNotificationActive:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  apiProfileGETCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      courseTab:false,
      profileData: {
        id: "",
        type: "profile",
        attributes: {
          id: 0,
          first_name: null,
          last_name: null,
          email: "",
          date_of_birth: "",
          preferred_name: "",
          role_id: "",
          full_phone_number: "",
          employee_number: null,
          location: null,
          company_reference: null,
          completed_course: null,
          account_id: 0,
          image: null,
          group_id: 0,
          group_name: "",
          company_id: 0,
          company_name: "",
          phone_number_verified: false,
          selected_theme: "",
          font_size: "",
        },
      },
      errorMsg: "",
      open:false,
      darkTheme:false,
      isDashboardActive:false,
      isCourseActive:false,
      isBulletinActive:false,
      anchorEl: null,
      openMenu:false,
      isModalOpen:false,
      openTermsModal:false,
      isNotificationActive:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token }, () => {
      });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.apiProfileGETCallId !== "" &&
    this.apiProfileGETCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        setStorageData("saveUserInfo", JSON.stringify(responseJson.data));
        this.setState({
        profileData: responseJson.data,
        errorMsg: "",
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ openMenu:true,anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ openMenu:false,anchorEl: null });
  };
  closeTermsModal=()=>{
    this.setState({
      openTermsModal:false,
    })
  }
  handleOpenTermsModal=()=>{
    this.setState({
      openTermsModal:true
    })
  }
  toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(path);
      } else {
        Linking.openURL(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getUserProfileData();
    this.checkWindowPathName();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMenuItems = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  checkWindowPathName=()=>{
    const url = window.location.pathname;
    if(url==="/Dashboard"){
      this.setState({
        isDashboardActive:true,
        isCourseActive:false,
        isBulletinActive:false,
        isNotificationActive:false
      })
    } else if(url ==="/PtTestsForCourseScreen" || url === "/PostCreation"){
      this.setState({
        isDashboardActive:false,
        isCourseActive:true,
        isBulletinActive:false,
        isNotificationActive:false
      })
    } else if(url==="/BulletinBoard"){
      this.setState({
        isDashboardActive:false,
        isCourseActive:false,
        isBulletinActive:true,
        isNotificationActive:false
      })
    } else if(url==="/Notifications"){
      this.setState({
        isDashboardActive:false,
        isCourseActive:false,
        isBulletinActive:false,
        isNotificationActive:true
      })
    } else {
      this.setState({
        isDashboardActive:false,
        isCourseActive:false,
        isBulletinActive:false,
        isNotificationActive:false
      })
    }
  }
  goToDashboardPage=()=> {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Dashboard');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  goToCoursesPage=()=> {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'PtTestsForCourseScreen');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  goToNotificationPage = ()=> {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Notifications');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  goToBulletinPage=()=> {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'BulletinBoard');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  gotoProfilePage=()=> {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'CustomisableUserProfiles');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    sessionStorage.setItem('currentRoute','CustomisableUserProfiles')
  }
  handleLogoutButton=()=>{
    this.setState({isModalOpen: true})
  }
  handleLogout=()=> {
    localStorage.removeItem('authToken');
    this.goToLogin()
  }
  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  getProfileImage=()=>{
    if(this.state.profileData.attributes.image !== null)
      return this.state.profileData.attributes.image
    else 
      return userProfile;
  }
  getUserProfileData=()=>{
    const webHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProfileGETCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileGetURL
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }
  handleCloseLogoutModal=() => {
    this.setState({isModalOpen: false})
  }

  ternaryFunction = (condition:boolean,truePart:string | Element,falsePart:string | Element) => {
    return condition ? truePart : falsePart
  }
  // Customizable Area End
}
